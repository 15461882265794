// mockErrorStates.ts

import { EntityType, ResponseCode } from '../campaign-builder-error-review';

export interface EntityError {
  entityType: EntityType;
  details: string;
  code: ResponseCode;
  entityId?: number;
}

export interface MockCampaignBuilderResponse {
  campaign: any;
  sbaProfile?: any;
  sbaProfileImage?: any;
  adGroups: any[];
  adItems: any[];
  keywords: any[];
  errors: EntityError[];
  hasErrors: boolean;
}

export const generateMockErrors = (scenario: string): MockCampaignBuilderResponse => {
  const baseResponse: MockCampaignBuilderResponse = {
    campaign: {},
    adGroups: [],
    adItems: [],
    keywords: [],
    errors: [],
    hasErrors: true
  };

  switch (scenario) {
    case 'existingItems':
      return {
        ...baseResponse,
        errors: [
          {
            entityType: EntityType.AdItem,
            details: '2 items already exist in the campaign: ITEM123, ITEM456',
            code: ResponseCode.Failure
          }
        ]
      };

    case 'failedAdGroups':
      return {
        ...baseResponse,
        errors: [
          {
            entityType: EntityType.AdGroup,
            details: 'Invalid bid amount: must be between $0.10 and $100.00',
            code: ResponseCode.Failure,
            entityId: 12345
          }
        ]
      };

    case 'multipleErrors':
      return {
        ...baseResponse,
        errors: [
          {
            entityType: EntityType.AdGroup,
            details: 'Daily budget cannot be less than minimum bid',
            code: ResponseCode.Failure,
            entityId: 12345
          },
          {
            entityType: EntityType.AdItem,
            details: 'Item ITEM789 is not eligible for advertising',
            code: ResponseCode.Failure,
            entityId: 789
          },
          {
            entityType: EntityType.Keyword,
            details: "Keyword 'test keyword' contains prohibited terms",
            code: ResponseCode.Failure,
            entityId: 45678
          }
        ]
      };

    case 'keywordErrors':
      return {
        ...baseResponse,
        errors: Array.from({ length: 3 }, (_, i) => ({
          entityType: EntityType.Keyword,
          details: `Keyword match type invalid for keyword_${i + 1}`,
          code: ResponseCode.Failure,
          entityId: 1000 + i
        }))
      };

    case 'mixedSuccessFailure':
      return {
        ...baseResponse,
        adGroups: [{ id: 1, status: 'Active' }],
        adItems: [{ id: 101, status: 'Active' }],
        errors: [
          {
            entityType: EntityType.Keyword,
            details: "Bid too high for keyword 'expensive term'",
            code: ResponseCode.Failure,
            entityId: 5555
          }
        ]
      };

    default:
      return baseResponse;
  }
};
