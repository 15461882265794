import { CampaignOptionsSelections } from '../../shared/types/walmart-sams-club/campaign-options-selections';
import CampaignOptionSelect from './campaign-option-select';

interface CampaignOptionsProps {
  brandTermTargeting: CampaignOptionsSelections;
  complementaryTargeting: CampaignOptionsSelections;
  onValueChange: (field: string, value: CampaignOptionsSelections) => void;
}

const CampaignOptions: React.FC<CampaignOptionsProps> = ({ brandTermTargeting, complementaryTargeting, onValueChange }) => {
  return (
    <>
      <CampaignOptionSelect
        id="brand-term-targeting-select"
        label="Brand Term Targeting"
        tooltipTitle="Brand Term Targeting allows you to include (Opt In) or exclude (Opt Out) competitor terms in your automatic campaign."
        value={brandTermTargeting}
        onChange={(value: CampaignOptionsSelections) => onValueChange('brandTermTargeting', value)}
      />
      <CampaignOptionSelect
        id="complementary-targeting-select"
        label="Complementary Targeting"
        tooltipTitle="Complementary Targeting allows you to include (Opt In) or exclude (Opt Out) complementary items in your automatic campaign."
        value={complementaryTargeting}
        onChange={(value: CampaignOptionsSelections) => onValueChange('complementaryTargeting', value)}
        sx={{ mt: 2 }}
      />
    </>
  );
};

export default CampaignOptions;
