import { SvgIconTypeMap, Tooltip, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import * as React from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { DaypartingScheduleState } from '../../../../../walmart/components/day-parting/day-parting-types';
import { State } from '../../../../../amazon/types/campaign-builder-request';
const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: 'left',
  '& .icon': {
    color: 'inherit'
  },
  '&.Live': {
    color: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.main}`
  },
  '&.Enabled': {
    color: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.main}`
  },
  '&.Scheduled': {
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.main}`
  },
  '&.Proposal': {
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.main}`
  },
  '&.Paused': {
    color: theme.palette.action.disabled,
    border: `1px solid ${theme.palette.action.disabled}`
  }
}));

const StyledIcon = styled('div')(({ theme }) => ({
  '&.Live': {
    color: theme.palette.success.dark
  },
  '&.Enabled': {
    color: theme.palette.success.dark
  },
  '&.Scheduled': {
    color: theme.palette.warning.dark
  },
  '&.Proposal': {
    color: theme.palette.warning.dark
  },
  '&.Paused': {
    color: theme.palette.action.disabled
  }
}));

type CampaignStatusOption = {
  label: string;
  value: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
};

interface StatusProps {
  status: string;
  isActiveInVector?: boolean;
  currentMonthBudget: number;
  dailyRemainingBudget: number;
  campaignStatusOptions: CampaignStatusOption[];
  isOutOfBudget?: boolean;
  currentDaypartingScheduleState?: DaypartingScheduleState;
}

const Status = React.memo((props: StatusProps) => {
  const {
    status,
    isActiveInVector,
    currentMonthBudget,
    dailyRemainingBudget,
    campaignStatusOptions,
    isOutOfBudget,
    currentDaypartingScheduleState
  } = props;

  const statusObject = campaignStatusOptions.find((option) => option.value === status.toLowerCase());

  if (!statusObject) {
    return null;
  }

  const Icon = statusObject.icon;

  const currentMonth = new Date().toLocaleString('default', { month: 'long' });

  return (
    <div style={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>
      <StyledChip className={status} icon={<Icon className="icon" />} size="small" label={status} variant="outlined" />
      {currentDaypartingScheduleState === DaypartingScheduleState.Disabled && (
        <Tooltip title="Paused by Dayparting">
          <StyledIcon className={`icon ${status}`}>
            <ScheduleIcon />
          </StyledIcon>
        </Tooltip>
      )}
      {isActiveInVector &&
        status.toLowerCase() === State.PAUSED.toLowerCase() &&
        currentMonthBudget > 0 &&
        dailyRemainingBudget <= 50 && (
          <Tooltip title="Budget Depleted">
            <StyledIcon className={`icon ${status}`}>
              <AttachMoneyIcon />
            </StyledIcon>
          </Tooltip>
        )}
      {isActiveInVector && status.toLowerCase() === State.PAUSED.toLowerCase() && currentMonthBudget === 0 && (
        <Tooltip title={`No Budget Assigned for ${currentMonth}`}>
          <StyledIcon className={`icon ${status}`}>
            <MoneyOffIcon />
          </StyledIcon>
        </Tooltip>
      )}
    </div>
  );
});

export const renderCampaignStatus = (campaignStatusOptions: CampaignStatusOption[]) => (params: GridRenderCellParams) => {
  if (params.value == null) {
    return '';
  }

  return (
    <Status
      campaignStatusOptions={campaignStatusOptions}
      isActiveInVector={params.row.isActiveInVector}
      currentMonthBudget={params.row.currentMonthBudget}
      dailyRemainingBudget={params.row.dailyRemainingBudget}
      status={params.value}
      isOutOfBudget={params.row.isOutOfBudget}
      currentDaypartingScheduleState={params.row.currentDaypartingScheduleState}
    />
  );
};
