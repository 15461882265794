import {
  Add as AddIcon,
  Delete as DeleteIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { Box, Button, Card, CardContent, Collapse, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { FC, useMemo, useState } from 'react';
import { CampaignBuilderAdGroup } from '../../../types/campaign-builder-request';
import { AddKeywordsStep } from '../../ads/add-keywords-step';
import { ItemSelectionStep } from './item-selection-step';
import { CampaignBuilderStepLabelButton } from './step-label-button';

interface AdGroupSettingsStepProps {
  formik: FormikProps<any>;
  stepNumber: number;
  profileId: number | null;
  targetingType?: string;
}

export const AdGroupSettingsStep: FC<AdGroupSettingsStepProps> = ({
  formik,
  stepNumber,
  profileId,
  targetingType: targetingTypeFromProps
}) => {
  const [showStep, setShowStep] = useState(true);
  // Change from a single expandedGroup to a Set of expanded group indices
  const [expandedGroups, setExpandedGroups] = useState<Set<number>>(new Set([0]));

  // Campaign Builder has targetingType in the formik values
  // Ads Builder has targetingType as a prop from the parent component's campaign state
  const targetingType = useMemo(() => {
    return targetingTypeFromProps ?? formik.values.targetingType;
  }, [targetingTypeFromProps, formik.values.targetingType]);

  // Get all item IDs currently used across all ad groups
  const getAllUsedItemIds = useMemo(() => {
    return (formik.values.adGroups || []).reduce((acc: string[], group: any) => {
      const groupItemIds = (group.items || []).map((item: any) => item.itemId);
      return [...acc, ...groupItemIds];
    }, []);
  }, [formik.values.adGroups]);

  // Get item IDs used in other ad groups (excluding the current one)
  const getExistingItemsForGroup = (currentGroupIndex: number) => {
    return (formik.values.adGroups || []).reduce((acc: string[], group: any, index: number) => {
      if (index !== currentGroupIndex) {
        const groupItemIds = (group.items || []).map((item: any) => item.itemId);
        return [...acc, ...groupItemIds];
      }
      return acc;
    }, []);
  };

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  const handleAddAdGroup = () => {
    const currentAdGroups = formik.values.adGroups || [];
    const newIndex = currentAdGroups.length;

    formik.setFieldValue('adGroups', [
      ...currentAdGroups,
      {
        name: `Ad Group ${newIndex + 1}`,
        items: [],
        keywords: []
      }
    ]);

    // Automatically expand the newly added ad group
    const newExpandedGroups = new Set(expandedGroups);
    newExpandedGroups.add(newIndex);
    setExpandedGroups(newExpandedGroups);
  };

  const handleRemoveAdGroup = (index: number) => {
    const newAdGroups = [...(formik.values.adGroups || [])];
    newAdGroups.splice(index, 1);
    formik.setFieldValue('adGroups', newAdGroups);

    // Remove the deleted group from expanded groups
    const newExpandedGroups = new Set(expandedGroups);
    newExpandedGroups.delete(index);

    // Update indices of groups that come after the deleted one
    const updatedExpandedGroups = new Set<number>();
    newExpandedGroups.forEach((groupIndex) => {
      if (groupIndex < index) {
        updatedExpandedGroups.add(groupIndex);
      } else if (groupIndex > index) {
        updatedExpandedGroups.add(groupIndex - 1);
      }
    });

    setExpandedGroups(updatedExpandedGroups);
  };

  const handleAdGroupNameChange = (index: number, name: string) => {
    const newAdGroups = [...(formik.values.adGroups || [])];
    newAdGroups[index] = {
      ...newAdGroups[index],
      name
    };
    formik.setFieldValue('adGroups', newAdGroups);
  };

  const handleExpandGroup = (index: number) => {
    const newExpandedGroups = new Set(expandedGroups);
    if (newExpandedGroups.has(index)) {
      newExpandedGroups.delete(index);
    } else {
      newExpandedGroups.add(index);
    }
    setExpandedGroups(newExpandedGroups);
  };

  const handleItemsSelected = (index: number, items: any[]) => {
    const newAdGroups = [...(formik.values.adGroups || [])];
    newAdGroups[index] = {
      ...newAdGroups[index],
      items
    };
    formik.setFieldValue('adGroups', newAdGroups);
  };

  return (
    <Box sx={{ mb: showStep ? 10 : 1, mt: 3 }}>
      <CampaignBuilderStepLabelButton
        showStep={showStep}
        handleStepButtonClick={handleStepButtonClick}
        stepNumber={stepNumber}
        stepLabel="Ad Group Setup"
      />

      <Collapse in={showStep}>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                Multi-Item Ad Groups
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Create multiple ad groups, each containing related items and shared keywords
              </Typography>
            </Box>

            <Stack spacing={2}>
              {(formik.values.adGroups || []).map((adGroup: CampaignBuilderAdGroup, index: number) => (
                <Card key={index} variant="outlined">
                  <CardContent>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <TextField
                        fullWidth
                        label="Ad Group Name"
                        value={adGroup.name}
                        onChange={(e) => handleAdGroupNameChange(index, e.target.value)}
                        error={Boolean(
                          Array.isArray(formik.touched.adGroups) &&
                            formik.touched.adGroups[index]?.name &&
                            Array.isArray(formik.errors.adGroups)
                          // formik.errors.adGroups[index]?.name
                        )}
                        helperText={
                          Array.isArray(formik.touched.adGroups) &&
                          formik.touched.adGroups[index]?.name &&
                          Array.isArray(formik.errors.adGroups)
                          //   formik.errors.adGroups[index]?.name
                        }
                      />
                      <IconButton onClick={() => handleRemoveAdGroup(index)} color="error" size="small">
                        <DeleteIcon />
                      </IconButton>
                      <Button
                        variant="outlined"
                        onClick={() => handleExpandGroup(index)}
                        endIcon={expandedGroups.has(index) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      >
                        {expandedGroups.has(index) ? 'Collapse' : 'Add Items'}
                      </Button>
                    </Stack>
                    <Collapse in={expandedGroups.has(index)}>
                      <Box sx={{ mt: 2 }}>
                        <ItemSelectionStep
                          formik={{
                            ...formik,
                            values: { items: adGroup.items },
                            setFieldValue: (_: any, value: any) => {
                              handleItemsSelected(index, value);
                            }
                          }}
                          stepNumber={1}
                          profileId={profileId}
                          campaignId={formik.values.campaignId}
                          existingItems={getExistingItemsForGroup(index)}
                          onItemsSelected={(items: any[]) => handleItemsSelected(index, items)}
                        />
                        {targetingType?.toLowerCase() === 'manual' && (
                          <>
                            <Divider sx={{ my: 2 }} />
                            <AddKeywordsStep
                              keywords={adGroup.keywords}
                              setKeywords={(keywords: any) => {
                                const newAdGroups = [...(formik.values.adGroups || [])];
                                newAdGroups[index] = {
                                  ...newAdGroups[index],
                                  keywords
                                };
                                formik.setFieldValue('adGroups', newAdGroups);
                              }}
                              stepNumber={2}
                            />
                          </>
                        )}
                      </Box>
                    </Collapse>
                  </CardContent>
                </Card>
              ))}
            </Stack>

            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <Button startIcon={<AddIcon />} onClick={handleAddAdGroup} variant="outlined" size="large">
                Add New Ad Group
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Collapse>
    </Box>
  );
};
