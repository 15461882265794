import { Box, Card, CardContent, CardMedia, Chip, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MovieIcon from '@mui/icons-material/Movie';
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import { useAdsApi } from '../../../../hooks/use-walmart-sams-club-api';
import { AdGroupMedia } from '../../../../types/walmart-sams-club/review';

interface ViewVideoProfileProps {
  profileId: number;
  adGroupMedia: AdGroupMedia;
  isUnderReview?: boolean;
}

export const ViewVideoProfile: React.FC<ViewVideoProfileProps> = ({ profileId, adGroupMedia, isUnderReview }) => {
  const { getMedia } = useAdsApi();
  const [mediaData, setMediaData] = useState<{ videoUrl?: string; captionUrl?: string; videoName?: string }>({});
  const { reviewStatus, reviewReason } = adGroupMedia;

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await getMedia(profileId, adGroupMedia.mediaId);
        if (response.success) {
          setMediaData(response.body[0]);
        } else {
          console.error('Failed to fetch media:', response.errorMessage);
        }
      } catch (error) {
        console.error('Error fetching media:', error);
      }
    };

    fetchMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, adGroupMedia.mediaId]);

  const renderStatusIcon = () => {
    switch (reviewStatus) {
      case 'Approved':
        return <CheckCircleIcon fontSize="small" color="success" />;
      case 'Rejected':
        return <ErrorIcon fontSize="small" color="error" />;
      case 'Pending':
        return <PendingIcon fontSize="small" color="warning" />;
      default:
        return null;
    }
  };

  const getStatusColor = () => {
    switch (reviewStatus) {
      case 'Approved':
        return 'success';
      case 'Rejected':
        return 'error';
      case 'Pending':
        return 'warning';
      default:
        return 'default';
    }
  };

  const getStatusText = () => {
    return reviewStatus || 'Not Reviewed';
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h6">{mediaData.videoName || 'Video Profile'}</Typography>
            <Chip icon={renderStatusIcon() || undefined} label={getStatusText()} color={getStatusColor()} size="small" />
          </Box>
          {reviewReason && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              Rejection reason: {reviewReason}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Box display="flex" alignItems="center" gap={1} mb={1}>
                <MovieIcon color="primary" />
                <Typography variant="body1">Video Asset</Typography>
              </Box>

              {mediaData.videoUrl ? (
                <Box sx={{ position: 'relative', height: '160px', backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
                  <CardMedia
                    component="video"
                    image={mediaData.videoUrl}
                    controls
                    sx={{ height: '100%', objectFit: 'contain' }}
                  />
                </Box>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No video asset uploaded
                </Typography>
              )}

              {mediaData.videoUrl && (
                <Typography variant="caption" display="block" mt={1} color="text.secondary">
                  {mediaData.videoUrl.split('/').pop()}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {mediaData.captionUrl && (
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardContent>
                <Box display="flex" alignItems="center" gap={1} mb={1}>
                  <ClosedCaptionIcon color="primary" />
                  <Typography variant="body1">Closed Caption File</Typography>
                </Box>

                <Box sx={{ p: 2, backgroundColor: '#f0f0f0', borderRadius: '4px', height: '120px', overflow: 'auto' }}>
                  <Typography variant="body2">Caption file available</Typography>
                  <Typography variant="caption" display="block" mt={1} color="text.secondary">
                    {mediaData.captionUrl.split('/').pop()}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
