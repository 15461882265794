import { useState } from 'react';
import type { FC } from 'react';
import { Card, CardContent, Tooltip, Typography, Collapse, Grid, Box, Autocomplete, TextField } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { CampaignBuilderStepLabelButton } from './step-label-button';
import AddKeywords from '../../../../shared/components/add-keywords';
import { CustomTypeOptionRadioButton } from '../../../../shared/components/custom-type-option-radio-button';
import { WalmartTargetingType } from '../../../../shared/types/walmart-sams-club/campaign';
import { CampaignOptionsSelections } from '../../../../shared/types/walmart-sams-club/campaign-options-selections';
import { AdGroupMode } from './campaign-builder-form-config';
import CampaignOptionSelect from '../../../components/campaign-option-select';
import CampaignOptions from '../../../components/campaign-options';

interface TargetingSettingsStepProps {
  formik: any;
  stepNumber: number;
  adGroupMode?: AdGroupMode;
}

type stringNumberType = {
  [key: string]: number;
};

const minKeywordBidsByCampaignType: stringNumberType = {
  sba: 1,
  sponsoredProducts: 0.3,
  video: 0.8
};
export const TargetingSettingsStep: FC<TargetingSettingsStepProps> = (props) => {
  const { formik, stepNumber, adGroupMode = AdGroupMode.SIAG, ...other } = props;

  const [showStep, setShowStep] = useState<boolean>(true);

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  return (
    <Box {...other}>
      <CampaignBuilderStepLabelButton
        showStep={showStep}
        handleStepButtonClick={handleStepButtonClick}
        stepNumber={stepNumber}
        stepLabel="Targeting Settings"
      />
      <Collapse in={showStep}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={4}>
            {['sba', 'video'].some((type) => type === formik.values.campaignType) ? (
              <Tooltip
                title={'Automatic targeting not available for Sponsored Brand and Sponsored Video campaigns'}
                placement="bottom"
              >
                <CustomTypeOptionRadioButton
                  typeOptionValue={'auto'}
                  typeOptionLabel={'Automatic'}
                  checked={formik.values.targetingType === 'auto'}
                  handleClick={(): void => formik.setFieldValue('targetingType', 'auto')}
                  sx={{ mb: 2, mt: 2, maxWidth: '500px' }}
                  disabled={true}
                />
              </Tooltip>
            ) : (
              <CustomTypeOptionRadioButton
                typeOptionValue={'auto'}
                typeOptionLabel={'Automatic'}
                checked={formik.values.targetingType === 'auto'}
                handleClick={(): void => formik.setFieldValue('targetingType', 'auto')}
                sx={{ mb: 2, mt: 2, maxWidth: '500px' }}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <CustomTypeOptionRadioButton
              typeOptionValue={'manual'}
              typeOptionLabel={'Manual'}
              checked={formik.values.targetingType === 'manual'}
              handleClick={(): void => formik.setFieldValue('targetingType', 'manual')}
              sx={{ mb: 2, mt: 2, maxWidth: '500px' }}
            />
          </Grid>
        </Grid>
        {props.adGroupMode === AdGroupMode.SIAG && (
          <Collapse in={formik.values.targetingType === 'manual'}>
            <Card sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Add Keywords (Optional)
                </Typography>
                <Typography variant="caption" gutterBottom>
                  Enter a bid, select match types, and enter keywords. Once finished click create ads to create your new ads and
                  keywords.
                </Typography>
                <AddKeywords
                  keywords={formik.values.keywords}
                  setKeywords={(keywords) => formik.setFieldValue('keywords', keywords)}
                  minBid={minKeywordBidsByCampaignType[formik.values.campaignType]}
                />
              </CardContent>
            </Card>
          </Collapse>
        )}
        <Collapse in={formik.values.targetingType === WalmartTargetingType.Auto.toLowerCase()}>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <CampaignOptions
                brandTermTargeting={formik.values.brandTermTargeting}
                complementaryTargeting={formik.values.complementaryTargeting}
                onValueChange={formik.setFieldValue}
              />{' '}
            </CardContent>
          </Card>
        </Collapse>
      </Collapse>
    </Box>
  );
};
