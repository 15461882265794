import { Autocomplete, Backdrop, Box, Button, CircularProgress, Container, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ArrowRight from '@mui/icons-material/ArrowRight';
import InfoIcon from '@mui/icons-material/Info';
import { useAdsApi } from '../../../shared/hooks/use-walmart-sams-club-api';
import { useAdsApi as useUpdatedAdsApi } from '../../../shared/hooks/use-wmt-map-updated-api';
import { ResponseObject } from '../../../shared/utilities/fetch-utilities';
import { Pageable } from '../../../shared/types/pageable';
import { AdGroupModeSelection } from '../campaigns/ad-group-mode-selector';
import { AdGroupSettingsStep } from '../campaigns/campaign-builder-form/ad-group-settings-step';
import ErrorReview from '../campaigns/campaign-builder-form/campaign-builder-error-review';
import { ItemSelectionStep } from '../campaigns/campaign-builder-form/item-selection-step';
import { MIAGSuccessfulCampaignCreation } from '../campaigns/campaign-builder-form/miag-successful-campaign-creation';
import { SuccessfulCampaignCreation } from '../campaigns/campaign-builder-form/successful-campaign-creation';
import { TargetingSettingsStep } from '../campaigns/campaign-builder-form/targeting-settings-step';
import {
  AdGroupMode,
  ADS_BUILDER_INITIAL_VALUES,
  ADS_BUILDER_SCHEMA,
  AdsBuilderFormValues
} from './ads-builder-form/ads-builder-form-config';
import { AddKeywordsStep } from './add-keywords-step';
import { Campaign, CampaignType } from '../../../shared/types/walmart-sams-club/campaign';

enum ContentSteps {
  Create = 0,
  Error = 1,
  Complete = 2
}

interface AdsBuilderState {
  profiles: Array<{
    profileId: number;
    name: string;
  }>;
  campaigns: Array<{
    campaignId: number;
    name: string;
    targetingType: string;
  }>;
  isSubmitting: boolean;
  shouldShowReview: boolean;
  currentContentToShow: ContentSteps;
  adGroupMode: AdGroupMode;
  adsBuilderResponse: ResponseObject;
  campaign: Campaign | null;
}

const AdsBuilder: FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const { getProfiles, getCampaigns, walmartSamsClubBuildAds: buildSingleItemGroupAds } = useAdsApi();
  const { walmartBuildMultiItemAdGroupAds: buildMultiItemGroupAds } = useUpdatedAdsApi();

  const [uiState, setUiState] = useState<AdsBuilderState>({
    profiles: [],
    campaigns: [],
    isSubmitting: false,
    shouldShowReview: false,
    currentContentToShow: ContentSteps.Create,
    adGroupMode: AdGroupMode.SIAG,
    adsBuilderResponse: {
      body: null,
      success: false,
      response: undefined,
      errorMessage: undefined
    },
    campaign: null
  });

  const profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;
  const campaignId = searchParams.get('campaignId') ? Number(searchParams.get('campaignId')) : null;

  useEffect(() => {
    const initialize = async () => {
      const profilesResponse = await getProfiles();
      if (profilesResponse.success) {
        setUiState((prev) => ({ ...prev, profiles: profilesResponse.body.records }));

        if (profileId) {
          const campaignsResponse = await getCampaigns({
            limit: 250,
            offset: 0,
            filters: [{ column: 'profileId', comparator: 'Equals', value: profileId.toString() }],
            sorts: []
          } as Pageable);

          if (campaignsResponse.success) {
            setUiState((prev) => ({ ...prev, campaigns: campaignsResponse.body.records }));

            // If we have a campaignId, set the targeting type from the selected campaign
            if (campaignId) {
              const selectedCampaign = campaignsResponse.body.records.find(
                (campaign: { campaignId: number }) => campaign.campaignId === campaignId
              );
              if (selectedCampaign) {
                setUiState((prev) => ({ ...prev, campaign: selectedCampaign }));
                formik.setFieldValue('targetingType', selectedCampaign.targetingType);
              }
            }
          } else {
            enqueueSnackbar(campaignsResponse.errorMessage, { variant: 'error' });
          }
        }
      } else {
        enqueueSnackbar(profilesResponse.errorMessage, { variant: 'error' });
      }
    };

    initialize();
  }, [profileId, campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleModeSelection = (mode: AdGroupMode) => {
    setUiState((prev) => ({
      ...prev,
      adGroupMode: mode,
      currentContentToShow: ContentSteps.Create
    }));
    formik.setFieldValue('adGroupMode', mode);
  };

  const handleProfileChange = async (profileId: number | null) => {
    formik.setFieldValue('profileId', profileId);
    formik.setFieldValue('campaignId', null);
    formik.setFieldValue('targetingType', null);

    const newSearchParams = new URLSearchParams(searchParams);
    if (profileId) {
      newSearchParams.set('profileId', profileId.toString());
    } else {
      newSearchParams.delete('profileId');
    }
    newSearchParams.delete('campaignId');
    navigate({ search: newSearchParams.toString() }); // watch out for this

    if (profileId) {
      const campaignsResponse = await getCampaigns({
        limit: 250,
        offset: 0,
        filters: [{ column: 'profileId', comparator: 'Equals', value: profileId.toString() }],
        sorts: []
      } as Pageable);

      if (campaignsResponse.success) {
        setUiState((prev) => ({ ...prev, campaigns: campaignsResponse.body.records }));
      } else {
        enqueueSnackbar(campaignsResponse.errorMessage, { variant: 'error' });
      }
    } else {
      setUiState((prev) => ({ ...prev, campaigns: [] }));
    }
  };

  const handleCampaignChange = (campaignId: number | null) => {
    formik.setFieldValue('campaignId', campaignId);

    const newSearchParams = new URLSearchParams(searchParams);
    if (campaignId) {
      newSearchParams.set('campaignId', campaignId.toString());

      const selectedCampaign = uiState.campaigns.find((campaign) => campaign.campaignId === campaignId);
      setUiState((prev) => ({ ...prev, campaign: (selectedCampaign as unknown as Campaign) || null }));

      if (selectedCampaign) {
        formik.setFieldValue('targetingType', selectedCampaign.targetingType);
      }
    } else {
      newSearchParams.delete('campaignId');
      formik.setFieldValue('targetingType', null);
    }
    navigate({ search: newSearchParams.toString() });
  };

  const onSubmitComplete = (response: ResponseObject) => {
    if (!response?.response) {
      return;
    }

    if (!response?.success) {
      enqueueSnackbar(response?.errorMessage, { variant: 'error' });
      setUiState((prev) => ({
        ...prev,
        currentContentToShow: ContentSteps.Error,
        isSubmitting: false,
        adsBuilderResponse: response
      }));
      return;
    }

    if (response?.body?.hasErrors) {
      setUiState((prev) => ({
        ...prev,
        currentContentToShow: ContentSteps.Error,
        isSubmitting: false,
        adsBuilderResponse: response
      }));
      return;
    }

    setUiState((prev) => ({
      ...prev,
      currentContentToShow: ContentSteps.Complete,
      isSubmitting: false,
      adsBuilderResponse: response
    }));
  };

  const formik = useFormik<AdsBuilderFormValues>({
    initialValues: {
      ...ADS_BUILDER_INITIAL_VALUES,
      profileId,
      campaignId
    },
    validationSchema: ADS_BUILDER_SCHEMA,
    onSubmit: async (values) => {
      setUiState((prev) => ({ ...prev, isSubmitting: true }));

      const response =
        values.adGroupMode === AdGroupMode.SIAG
          ? await buildSingleItemGroupAds(values.profileId!, values.campaignId!, {
              items: values.items,
              keywords: values.keywords
            })
          : await buildMultiItemGroupAds(values.profileId!, values.campaignId!, {
              adGroups: values.adGroups.map((group) => ({
                name: group.name,
                items: group.items.map((item) => ({
                  itemId: item.itemId.toString(),
                  itemName: item.itemName
                })),
                keywords: group.keywords
              }))
            });

      onSubmitComplete(response);
    }
  });

  const handleBackButtonClick = () => {
    setUiState((prev) => ({
      ...prev,
      currentContentToShow: ContentSteps.Create
    }));
  };

  const renderPlaceholder = () => (
    <Box className="text-center p-8 bg-gray-50 rounded-lg">
      <Typography variant="h6" className="mb-4 text-gray-700">
        {!profileId ? 'Please select a profile to continue' : !campaignId ? 'Please select a campaign to continue' : null}
      </Typography>
      <Typography variant="body1" className="text-gray-600">
        {!profileId
          ? 'Choose a profile from the dropdown above to view available campaigns'
          : !campaignId
            ? 'Choose a campaign from the dropdown above to start building your ads'
            : null}
      </Typography>
    </Box>
  );

  return (
    <Box component="main" className="flex-grow p-8 bg-background" sx={{ mb: 8 }}>
      <Container maxWidth="md">
        <Typography variant="h4" sx={{ mb: 4 }}>
          Ads Builder
        </Typography>
        <AnimatePresence mode="wait">
          {uiState.currentContentToShow === ContentSteps.Create && (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Autocomplete
                    value={uiState.profiles.find((p) => p.profileId === formik.values.profileId) || null}
                    onChange={(_, newValue: { profileId: number; name: string } | null) =>
                      handleProfileChange(newValue?.profileId || null)
                    }
                    options={uiState.profiles}
                    getOptionLabel={(option) => option.name || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Profile"
                        required
                        error={formik.touched.profileId && !!formik.errors.profileId}
                        helperText={formik.touched.profileId && formik.errors.profileId}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    value={uiState.campaigns.find((c) => c.campaignId === formik.values.campaignId) || null}
                    onChange={(_, newValue: { campaignId: number; name: string } | null) =>
                      handleCampaignChange(newValue?.campaignId || null)
                    }
                    options={uiState.campaigns}
                    getOptionLabel={(option) => option.name || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Campaign"
                        required
                        error={formik.touched.campaignId && !!formik.errors.campaignId}
                        helperText={formik.touched.campaignId && formik.errors.campaignId}
                      />
                    )}
                  />
                </Grid>

                {!profileId || !campaignId ? (
                  <Grid item xs={12}>
                    {renderPlaceholder()}
                  </Grid>
                ) : (
                  <>
                    {uiState.campaign?.campaignType?.toLowerCase() === CampaignType.SponsoredProducts.toLowerCase() ? (
                      <Grid item xs={12}>
                        <AdGroupModeSelection onModeSelect={handleModeSelection} stepNumber={1} />
                      </Grid>
                    ) : (
                      <Grid item xs={12} container alignItems="center">
                        <InfoIcon fontSize="small" color="action" />
                        <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
                          This campaign type does not support ad group mode selection
                        </Typography>
                      </Grid>
                    )}

                    {uiState.adGroupMode === AdGroupMode.SIAG ? (
                      <>
                        <Grid item xs={12}>
                          <ItemSelectionStep
                            formik={formik}
                            stepNumber={2}
                            profileId={formik.values.profileId}
                            campaignId={formik.values.campaignId}
                          />
                        </Grid>
                        {formik.values.targetingType && (
                          <Grid item xs={12}>
                            <Typography variant="h6" color="textSecondary" component={'div'}>
                              {uiState.campaigns.find((c) => c.campaignId === formik.values.campaignId)?.name} Targeting Type:{' '}
                              {formik.values.targetingType}
                            </Typography>
                          </Grid>
                        )}
                        {formik.values.targetingType?.toLowerCase() === 'manual' ? (
                          <Grid item xs={12}>
                            <AddKeywordsStep
                              keywords={formik.values.keywords}
                              setKeywords={(keywords) => formik.setFieldValue('keywords', keywords)}
                              stepNumber={3}
                              selectedCampaignType={
                                uiState.campaigns.find((c) => c.campaignId === formik.values.campaignId)?.targetingType
                              }
                            />
                          </Grid>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <Grid item xs={12}>
                          {formik.values.targetingType && (
                            <Grid item xs={12}>
                              <Typography variant="h6" color="textSecondary">
                                Targeting Type: {formik.values.targetingType}
                              </Typography>
                            </Grid>
                          )}
                          <AdGroupSettingsStep
                            formik={formik}
                            stepNumber={1}
                            profileId={formik.values.profileId}
                            targetingType={formik.values.targetingType}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} container justifyContent="flex-end">
                      <Button
                        endIcon={<ArrowRight />}
                        type="submit"
                        variant="contained"
                        size="large"
                        disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          )}

          {uiState.currentContentToShow === ContentSteps.Error && (
            <ErrorReview
              genericErrorText="The following errors occurred while creating your ads. Some ads may have been created successfully. Please review for completeness."
              handleBackButtonClick={handleBackButtonClick}
              campaignBuilderResponse={uiState.adsBuilderResponse}
            />
          )}

          {uiState.currentContentToShow === ContentSteps.Complete &&
            (uiState.adGroupMode === AdGroupMode.SIAG ? (
              <SuccessfulCampaignCreation
                adItems={uiState.adsBuilderResponse.body?.adItems}
                keywords={uiState.adsBuilderResponse.body?.keywords}
                campaignName={uiState.adsBuilderResponse.body?.name}
                campaignBuilderResponse={uiState.adsBuilderResponse}
                handleSkipReview={() => setUiState((prev) => ({ ...prev, shouldShowReview: false }))}
                handleSuccessfulReviewSubmission={() => navigate(`/walmart/campaigns?profileId=${formik.values.profileId}`)}
                shouldShowReview={uiState.shouldShowReview}
              />
            ) : (
              <MIAGSuccessfulCampaignCreation
                adGroups={uiState.adsBuilderResponse.body?.adGroups}
                campaignName={uiState.adsBuilderResponse.body?.campaign?.name}
                campaignBuilderResponse={uiState.adsBuilderResponse}
                handleSkipReview={() => setUiState((prev) => ({ ...prev, shouldShowReview: false }))}
                handleSuccessfulReviewSubmission={() => navigate(`/walmart/campaigns?profileId=${formik.values.profileId}`)}
                shouldShowReview={uiState.shouldShowReview}
              />
            ))}
        </AnimatePresence>
      </Container>

      <Backdrop className="text-white z-drawer" open={uiState.isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default AdsBuilder;
