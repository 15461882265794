import { Dayjs } from 'dayjs';
import { FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  campaignBuilderTargetingTypes,
  keywordMatchTypes,
  WalmartBiddingStrategyStrategy,
  WalmartTargetingType
} from '../../../../shared/types/walmart-sams-club/campaign';
import { CampaignOptionsSelections } from '../../../../shared/types/walmart-sams-club/campaign-options-selections';
import { ResponseObject } from '../../../../shared/utilities/fetch-utilities';
import { CampaignBuilderAdGroup, CampaignBuilderItem, CampaignBuilderKeyword } from '../../../types/campaign-builder-request';

const today = new Date();
today.setHours(0, 0, 0, 0);

export enum AdGroupMode {
  SIAG = 'siag',
  MIAG = 'miag'
}

const entitySchemas: { [key: string]: Yup.ObjectSchema<any> } = {
  item: Yup.object({
    itemId: Yup.string().required('Item ID is required'),
    itemName: Yup.string().required('Item name is required')
  }),
  keyword: Yup.object({
    keywordText: Yup.string().required('Keyword text is required'),
    matchType: Yup.string()
      .oneOf(keywordMatchTypes.map((type) => type.value))
      .required('Match type is required'),
    bid: Yup.number().required('Bid is required').min(0.01, 'Bid must be at least 0.01')
  }),
  adGroup: Yup.object({
    name: Yup.string().required('Ad group name is required'),
    items: Yup.array()
      .of(Yup.lazy(() => entitySchemas.item))
      .min(1, 'At least one item is required per ad group')
      .required('Items are required for each ad group')
  })
};

export const CONDITIONAL_CAMPAIGN_SCHEMA = Yup.object({
  name: Yup.string().max(255).required('Campaign name is required'),
  startDate: Yup.date().nullable().min(today, 'Start date cannot have passed').required('Start date is required'),
  endDate: Yup.date()
    .nullable()
    .min(today, 'End date cannot be before start date')
    .when('startDate', (startDate, schema) =>
      startDate ? schema.min(startDate, 'End date must come after start date') : schema
    ),
  dailyBudget: Yup.number().min(1, 'Daily budget must be at least 1').required('Daily budget is required'),
  totalBudget: Yup.number().min(1, 'Total budget must be at least 1').required('Total budget is required'),
  budgetType: Yup.string().oneOf(['daily', 'lifetime']).required('Budget type is required'),
  biddingStrategy: Yup.object({
    strategy: Yup.string().required('Bidding strategy is required')
  }),
  targetingType: Yup.string()
    .oneOf(campaignBuilderTargetingTypes.map((type) => type.value))
    .required('Targeting type is required'),
  adGroupMode: Yup.string().oneOf(Object.values(AdGroupMode)).required('Campaign mode is required'),

  // Items are required only for SIAG
  items: Yup.array().when('adGroupMode', {
    is: AdGroupMode.SIAG,
    then: (schema) => schema.of(entitySchemas.item).required().min(1, 'At least one item is required'),
    otherwise: (schema) => schema.strip()
  }),

  // Ad Groups are required only for MIAG
  adGroups: Yup.array().when('adGroupMode', {
    is: AdGroupMode.MIAG,
    then: (schema) => schema.of(entitySchemas.adGroup).required().min(1, 'At least one ad group is required'),
    otherwise: (schema) => schema.strip()
  })

  // Keywords can be empty
});

const CAMPAIGN_ENTITY_INITIAL_VALUES = {
  name: '',
  campaignType: 'sponsoredProducts',
  budgetType: 'daily',
  startDate: new Date(),
  endDate: null,
  dailyBudget: 50,
  totalBudget: 100,
  targetingType: WalmartTargetingType.Manual.toLowerCase(),
  biddingStrategy: { strategy: WalmartBiddingStrategyStrategy.Fixed },
  brandTermTargeting: CampaignOptionsSelections.OptOut,
  complementaryTargeting: CampaignOptionsSelections.OptOut
};

const SPONSORED_BRANDS_PROFILE_INITIAL_VALUES = {
  searchAmpName: '',
  headlineText: '',
  clickUrl: '',
  logoDataUri: ''
};

// Start with items, keywords, and ad groups
// will filter out & construct appropriate dto during submission
const CHILD_ENTITIES_INITIAL_VALUES = {
  // SIAG
  items: [],
  keywords: [],

  // MIAG
  adGroups: [
    {
      name: '',
      items: [],
      keywords: []
    }
  ]
};

// Base initial values
export const CAMPAIGN_BUILDER_INITIAL_VALUES: CampaignFormValues = {
  ...CAMPAIGN_ENTITY_INITIAL_VALUES,
  ...SPONSORED_BRANDS_PROFILE_INITIAL_VALUES,
  ...CHILD_ENTITIES_INITIAL_VALUES,
  adGroupMode: AdGroupMode.SIAG // not passed to requestDto
};

export interface CampaignFormValues {
  name: string;
  targetingType: string;
  startDate: Date | null;
  endDate: Date | null;
  items: CampaignBuilderItem[];
  keywords: CampaignBuilderKeyword[];
  adGroups: CampaignBuilderAdGroup[];
  adGroupMode: AdGroupMode;
  budgetType: string;
  dailyBudget: number;
  totalBudget: number;
  biddingStrategy: {
    strategy: string;
  };
  [key: string]: any;
}
