import { Tooltip, Typography } from '@mui/material';
import React from 'react';

interface ChartDeltaProps {
  previousValue: number | null;
  currentValue: number | null;
  dataType?: 'Number' | 'Percentage' | 'Currency';
}

export const ChartDelta: React.FC<ChartDeltaProps> = ({ previousValue, currentValue, dataType = 'Number' }) => {
  if (previousValue == null || currentValue == null) {
    return <Typography variant="body2"></Typography>;
  }

  let absoluteDelta = currentValue - previousValue;
  const relativeDelta = previousValue !== 0 ? absoluteDelta / previousValue : 0;

  const sign = absoluteDelta > 0 ? '+' : '';

  return (
    <Tooltip
      title={
        <React.Fragment>
          <Typography variant="body2">Previous Value: {formatToolTipValue(previousValue, dataType)}</Typography>
          <Typography variant="body2">
            Absolute Change: {sign}
            {formatToolTipValue(absoluteDelta, dataType)}
          </Typography>
          {previousValue !== 0 && (
            <Typography variant="body2">
              Relative Change: {sign}
              {formatToolTipValue(relativeDelta, 'Percentage')}
            </Typography>
          )}
        </React.Fragment>
      }
    >
      <Typography sx={{ color: absoluteDelta >= 0 ? 'success.main' : 'error.main', paddingLeft: 0.5 }}>
        {sign}
        {formatDisplayValue(absoluteDelta, dataType)}
        {previousValue !== 0 && ` (${sign}${formatDisplayValue(relativeDelta, 'Percentage')})`}
      </Typography>
    </Tooltip>
  );
};

function formatDisplayValue(value: number, dataType: 'Number' | 'Percentage' | 'Currency' = 'Number') {
  const sign = value < 0 ? '-' : '';
  const absValue = Math.abs(value);

  switch (dataType) {
    case 'Percentage':
      return `${sign}${formatNumberShortened(absValue * 100, 1)}%`;
    case 'Currency':
      return `${sign}$${formatNumberShortened(absValue, 1)}`;
    default:
      if (absValue < 1000) {
        return `${sign}${formatNumberShortened(absValue, 0)}`;
      }
      return `${sign}${formatNumberShortened(absValue, 1)}`;
  }
}

function formatToolTipValue(value: number, dataType: 'Number' | 'Percentage' | 'Currency' = 'Number'): string {
  const absValue = Math.abs(value);
  switch (dataType) {
    case 'Percentage':
      return `${formatNumberWithCommas(absValue * 100, 2)}%`;
    case 'Currency':
      return `$${formatNumberWithCommas(absValue, 2)}`;
    default:
      return formatNumberWithCommas(absValue);
  }
}

function formatNumberWithCommas(value: number, decimals = 0) {
  return value.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function formatNumberShortened(value: number, decimal: number) {
  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let suffixNum = 0;

  while (value >= 1000 && suffixNum < suffixes.length - 1) {
    value /= 1000;
    suffixNum++;
  }

  return value.toFixed(decimal) + suffixes[suffixNum];
}
