import AddIcon from '@mui/icons-material/Add';
import WarningIcon from '@mui/icons-material/Warning';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Typography } from '@mui/material';
import {
  GridCallbackDetails,
  GridEventListener,
  GridRowSelectionModel,
  MuiEvent,
  useGridApiContext
} from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import useAdsApi from '../../../../shared/hooks/use-wmt-map-updated-api';
import KeywordActionsMenu from '../../../../shared/components/grid-components/bulk-actions/keywords/keyword-actions-menu';
import AdGroupItemDialog from './ad-group-item-dialog';

interface AdGroupBulkActionsProps {
  setIsLoading: (loading: boolean) => void;
}

const AdGroupBulkActions = ({ setIsLoading }: AdGroupBulkActionsProps) => {
  const apiRef = useGridApiContext();

  const [selectionModelHasRows, setSelectionModelHasRows] = useState(apiRef.current.getSelectedRows()?.size > 0);

  const [isAddAdItemsOpen, setIsAddAdItemsOpen] = useState(false);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);

  const [profileId, setProfileId] = useState<number | null>(null);

  useEffect(() => {
    function initialize() {
      apiRef.current.subscribeEvent('rowSelectionChange', handleSelectionChange);
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [hasSameProfileId, setHasSameProfileId] = useState(true);

  const handleSelectionChange: GridEventListener<'rowSelectionChange'> = async (
    params: GridRowSelectionModel,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const profileIds = new Set<string>();

    selectedRows.forEach((row) => {
      profileIds.add(row.profileId);
    });

    setSelectionModelHasRows(params?.length > 0);

    if (profileIds.size > 1) {
      setHasSameProfileId(false);
      return;
    }

    // Set profileId if only one profile is selected
    if (profileIds.size === 1) {
      setProfileId(Number(profileIds.values().next().value));
    }
  };

  return (
    <>
      <Tooltip
        title={
          selectionModelHasRows
            ? ''
            : `No rows selected for bulk action. Please select one or more Ad Groups to enable adding Ad Items`
        }
      >
        <span>
          <Button
            sx={{ fontSize: '8pt' }}
            onClick={() => {
              if (hasSameProfileId) {
                setIsAddAdItemsOpen(true);
              } else {
                setIsWarningDialogOpen(true);
              }
            }}
            disabled={!selectionModelHasRows}
            startIcon={<AddIcon />}
          >
            Add Ad Items
          </Button>
        </span>
      </Tooltip>
      <KeywordActionsMenu selectionModelHasRows={selectionModelHasRows} />

      {isAddAdItemsOpen && hasSameProfileId && !isNaN(profileId as number) && (
        <AdGroupItemDialog
          open={isAddAdItemsOpen}
          onClose={() => setIsAddAdItemsOpen(false)}
          apiRef={apiRef}
          profileId={Number(profileId)}
        />
      )}

      {isWarningDialogOpen && !hasSameProfileId && (
        <Dialog open={isWarningDialogOpen} onClose={() => setIsWarningDialogOpen(false)}>
          <DialogTitle>Multiple Profiles Detected</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                <WarningIcon color="error" sx={{ marginRight: 1 }} /> To add Items, Ad Groups must be from the same Profile.
              </Typography>
              <br />
              <Typography>Try again by limiting the selected rows.</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsWarningDialogOpen(false)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AdGroupBulkActions;
