import { Divider } from '@mui/material';
import {
  GridCallbackDetails,
  GridEventListener,
  GridRowSelectionModel,
  MuiEvent,
  useGridApiContext
} from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import KeywordActionsMenu from '../../../../shared/components/grid-components/bulk-actions/keywords/keyword-actions-menu';
import UpdateAdBids from '../../../../shared/components/grid-components/bulk-actions/update-ad-bids';
import useAdsApi from '../../../../shared/hooks/use-walmart-sams-club-api';
import { buildUpdateAdRequest } from '../../../types/ad';
import IconButtonWithTooltip from '../../../../shared/components/icon-button-with-tooltip';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

interface AdBulkActionsProps {
  setIsLoading: (loading: boolean) => void;
}

const AdBulkActions = ({ setIsLoading }: AdBulkActionsProps) => {
  const apiRef = useGridApiContext();
  const { updateAds } = useAdsApi();

  const updateAdState = async (state: 'Disabled' | 'Enabled') => {
    setIsLoading(true);

    const selectedAds = Array.from(apiRef.current.getSelectedRows().values());

    const updatedAds = selectedAds.map((ad: any) => {
      return {
        ...ad,
        status: state
      };
    });

    const adRequestObjects = updatedAds.map((ad: any) => {
      return buildUpdateAdRequest(ad);
    });

    const response = await updateAds(updatedAds[0]?.profileId, adRequestObjects);

    if (response.success) {
      apiRef.current.updateRows(updatedAds);
    }

    apiRef.current.setRowSelectionModel([]);

    setIsLoading(false);
  };

  const [selectionModelHasRows, setSelectionModelHasRows] = useState(apiRef.current.getSelectedRows()?.size > 0);

  useEffect(() => {
    function initialize() {
      apiRef.current.subscribeEvent('rowSelectionChange', handleSelectionChange);
    }

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectionChange: GridEventListener<'rowSelectionChange'> = (
    params: GridRowSelectionModel,
    event: MuiEvent<{}>,
    details: GridCallbackDetails
  ) => {
    setSelectionModelHasRows(params?.length > 0);
  };

  return (
    <>
      <IconButtonWithTooltip
        tooltipText={selectionModelHasRows ? 'Enable Ads' : 'Select one or more Ads to enable actions'}
        handleClick={() => updateAdState('Enabled')}
        isDisabled={!selectionModelHasRows}
        icon={<PlayArrowIcon />}
      />
      <IconButtonWithTooltip
        tooltipText={selectionModelHasRows ? 'Pause Ads' : 'Select one or more Ads to enable actions'}
        handleClick={() => updateAdState('Disabled')}
        isDisabled={!selectionModelHasRows}
        icon={<PauseIcon />}
      />
      <Divider orientation="vertical" flexItem />
      <UpdateAdBids setIsLoading={setIsLoading} selectionModelHasRows={selectionModelHasRows} />
      <KeywordActionsMenu selectionModelHasRows={selectionModelHasRows} />
    </>
  );
};

export default AdBulkActions;
