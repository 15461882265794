import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import VectorPerformanceGrid from '../../../../shared/components/performance-grid/vector-performance-grid';
import useDialog from '../../../../shared/hooks/use-dialog';
import useVectorPerformanceGrid from '../../../../shared/hooks/use-vector-performance-grid';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../../shared/types/pageable';
import { GridConfigSettings } from '../../../../shared/utilities/grid-config-settings';
import { AD_COLUMNS, createAdColumns } from '../../../components/grid-components/column-configurations/ad-columns';
import { BreadCrumbParams, useAmazonApi } from '../../../hooks/use-amazon-api-v2';
import { AmazonCampaignTypeValue } from '../../../types/campaign';
import { CreateAdsDialog } from './create-dialogs/create-ads-dialog';

interface OverviewAdsProps {
  campaignType: AmazonCampaignTypeValue | undefined;
}

export const OverviewAds = (props: OverviewAdsProps) => {
  const { campaignType } = props;

  const { getAds } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [ads, setAds] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  let [searchParams] = useSearchParams();
  let campaignId = useMemo(() => searchParams.get('campaignId'), [searchParams]);
  let adGroupId = useMemo(() => searchParams.get('adGroupId'), [searchParams]);

  const { isShowing, toggle: toggleAddAdsDialog } = useDialog();

  let userSettings = adGroupId
    ? GridConfigSettings.AMAZON_AD_GROUP_OVERVIEW_ADS
    : GridConfigSettings.AMAZON_CAMPAIGN_OVERVIEW_ADS;

  const breadcrumbParams: BreadCrumbParams[] = useMemo(() => [{ campaignId }, { adGroupId }], [campaignId, adGroupId]);

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange,
    handleRefreshEntities
  } = useVectorPerformanceGrid(userSettings);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchAdsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAdsData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    if (!breadcrumbParams) {
      return;
    }

    setIsLoading(true);

    const response = await getAds([...breadcrumbParams], pageable, beginDate, endDate);

    if (response.success) {
      setAds(response.body.records);
      setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const processRowUpdate = useCallback((newRow: any, oldRow: any) => {
    //Execute Put Request here
    return newRow;
  }, []);

  const adColumns = useMemo(() => {
    if (!campaignType) {
      return [];
    }

    return createAdColumns(campaignType);
  }, [campaignType]);

  return (
    <Box style={{ height: '93vh', marginTop: '10px' }}>
      {/* Since this is a nested component whose parent has `Container`, use `Box` so we don't double up on margins. */}
      <Stack spacing={1} style={{ height: '100%' }}>
        <Box display="flex" alignItems={'center'}>
          <Typography variant="h6">Ads</Typography>
          <Box
            sx={{
              mr: 'auto'
            }}
          ></Box>
          <Button startIcon={<AddIcon fontSize="small" />} onClick={() => toggleAddAdsDialog()} sx={{ mb: '0.5rem' }}>
            Create Ads
          </Button>
        </Box>
        {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
          <DataGridPremium
            rows={[]}
            columns={AD_COLUMNS}
            disableRowGrouping
            loading={true}
            processRowUpdate={processRowUpdate}
            initialState={{}}
            key={uuidv4()}
          />
        ) : (
          <VectorPerformanceGrid
            apiRef={apiRef}
            processRowUpdate={processRowUpdate}
            loading={isLoading}
            rows={ads}
            columns={adColumns}
            initialState={initialSettings?.config}
            saveGridConfig={saveGridConfig}
            dateConfig={{
              dateSettings: dateSettings,
              dateSettingsLoading: dateSettingsLoading,
              handleDateRangeChange: (dateRange: DateRange<Dayjs>) =>
                handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchAdsData, setDateSettings)
            }}
            getRowId={(row: any) => row.adId}
            handleFilterModelChange={(newModel: GridFilterModel) => {
              handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchAdsData);
            }}
            handleSortModelChange={(newModel: GridSortModel) => {
              handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchAdsData);
            }}
            handlePageChange={(page: number) => {
              handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchAdsData);
            }}
            handlePageSizeChange={(pageSize: number) => {
              handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchAdsData);
            }}
            rowCount={rowCount}
            otherDataGridProps={{ autoHeight: ads?.length < 10 }}
          />
        )}
        {isShowing && (
          <CreateAdsDialog
            isShowing={isShowing}
            toggle={toggleAddAdsDialog}
            refreshAds={() => handleRefreshEntities(settingsLoaded, dateSettings, apiRef, fetchAdsData)}
          />
        )}
      </Stack>
    </Box>
  );
};
