import { GridFilterModel } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { isEqual } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import {
  DEFAULT_ROW_COUNT,
  Filter,
  Pageable,
  Sort,
  convertGridFilterModelToCustomFilters,
  processSortsFromGrid
} from '../types/pageable';
import { DATE_SETTINGS_KEY, DEFAULT_DATE_CONFIG, DateConfig } from '../utilities/date-utilities';
import { IGridConfigSettingItem } from '../utilities/grid-config-settings';
import useSharedApi from './use-shared-api';

interface initializePerformanceGridProps {
  setInitialSettings: React.Dispatch<
    React.SetStateAction<
      | {
          pageable?: Pageable;
          config: GridInitialStatePremium;
          shouldShowChart?: boolean;
        }
      | undefined
    >
  >;
  setSettingsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setDateSettings: React.Dispatch<
    React.SetStateAction<
      | {
          beginDate: string;
          endDate: string;
        }
      | undefined
    >
  >;
  setDateSettingsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchEntity: (
    pageable: Pageable,
    beginDate: string,
    endDate: string,
    compareBeginDate?: string,
    compareEndDate?: string
  ) => Promise<void>;
}

const useVectorPerformanceGrid = (gridSetting: IGridConfigSettingItem) => {
  const { getUserSetting, updateUserSetting } = useSharedApi();

  const initializePerformanceGrid = async ({
    setInitialSettings,
    setSettingsLoaded,
    setDateSettings,
    setDateSettingsLoading,
    fetchEntity
  }: initializePerformanceGridProps) => {
    Promise.all([fetchGridConfig(gridSetting), fetchDateConfig()])
      .then(([loadedGridConfig, loadedDateConfig]) => {
        let updatedFilters: Filter[] = [];
        let updatedSorts: Sort[] = [];

        if (loadedGridConfig?.filter?.filterModel) {
          const filtersFromConfig: Filter[] = convertGridFilterModelToCustomFilters(loadedGridConfig.filter.filterModel);
          updatedFilters = updatedFilters.concat(filtersFromConfig);
        }

        if (loadedGridConfig?.sorting?.sortModel) {
          updatedSorts = processSortsFromGrid(loadedGridConfig.sorting.sortModel);
        }

        const loadedPageable: Pageable = {
          filters: updatedFilters,
          sorts: updatedSorts,
          limit: loadedGridConfig?.pagination?.paginationModel?.pageSize || DEFAULT_ROW_COUNT,
          offset: loadedGridConfig?.pagination?.paginationModel?.page || 0
        };

        setInitialSettings({
          pageable: loadedPageable,
          config: loadedGridConfig
        });
        setSettingsLoaded(true);

        setDateSettings(loadedDateConfig);
        setDateSettingsLoading(false);

        return { loadedPageable, loadedDateConfig };
      })
      .then(({ loadedPageable, loadedDateConfig }: { loadedPageable: Pageable; loadedDateConfig: DateConfig }) => {
        fetchEntity(
          loadedPageable,
          loadedDateConfig.beginDate,
          loadedDateConfig.endDate,
          loadedDateConfig.compareBeginDate && loadedDateConfig.compareEndDate ? loadedDateConfig.compareBeginDate : undefined,
          loadedDateConfig.compareBeginDate && loadedDateConfig.compareEndDate ? loadedDateConfig.compareEndDate : undefined
        );
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' });
      });
  };

  const fetchGridConfig = async (currentGridSetting: IGridConfigSettingItem) => {
    let loadedGridConfig: GridInitialStatePremium;

    const response = await getUserSetting(currentGridSetting.SettingsKey);

    if (response.success && response.body && typeof response.body === 'string') {
      const gridConfig = JSON.parse(response.body);
      loadedGridConfig = gridConfig;
    } else {
      loadedGridConfig = currentGridSetting.DefaultSetting;
    }

    return loadedGridConfig;
  };

  const saveGridConfig = async (gridConfig: GridInitialStatePremium) => {
    const response = await updateUserSetting(gridSetting.SettingsKey, JSON.stringify(gridConfig));

    return response;
  };

  const fetchDateConfig = async () => {
    let loadedDateConfig: DateConfig;

    const response = await getUserSetting(DATE_SETTINGS_KEY);

    if (response.success && response.body && typeof response.body === 'string') {
      const dateConfig = JSON.parse(response.body);
      loadedDateConfig = dateConfig;
    } else {
      loadedDateConfig = DEFAULT_DATE_CONFIG;
    }

    return loadedDateConfig;
  };

  interface DateSettings {
    beginDate: string;
    endDate: string;
    compareBeginDate?: string;
    compareEndDate?: string;
  }

  const handleDateChange = async (
    newDateSettings: any,
    dateSettings: any,
    dateSettingsLoading: any,
    apiRef: any,
    fetchEntity: any,
    setDateSettings: any
  ) => {
    if (dateSettingsLoading || !dateSettings) {
      return;
    }

    // Check if we need to update based on dates
    const shouldUpdate = Boolean(
      !isEqual(dateSettings.beginDate, newDateSettings[0]) ||
        !isEqual(dateSettings.endDate, newDateSettings[1]) ||
        !isEqual(dateSettings.compareBeginDate, newDateSettings[2]) ||
        !isEqual(dateSettings.compareEndDate, newDateSettings[3])
    );

    if (shouldUpdate) {
      const currentState = apiRef.current.exportState();

      const updatedPageable: Pageable = {
        filters: currentState?.filter?.filterModel
          ? convertGridFilterModelToCustomFilters(currentState?.filter?.filterModel)
          : [],
        sorts: currentState?.sorting?.sortModel ? processSortsFromGrid(currentState.sorting.sortModel) : [],
        limit: currentState?.pagination?.paginationModel?.pageSize || DEFAULT_ROW_COUNT,
        offset: currentState?.pagination?.paginationModel?.page || 0
      };

      fetchEntity(
        updatedPageable,
        newDateSettings.beginDate,
        newDateSettings.endDate,
        newDateSettings.compareBeginDate,
        newDateSettings.compareEndDate
      );

      updateUserSetting(DATE_SETTINGS_KEY, JSON.stringify(newDateSettings));

      setDateSettings(newDateSettings);
    }
  };

  const handleFilterModelChange = async (
    newModel: GridFilterModel,
    settingsLoaded: boolean,
    dateSettings: any,
    apiRef: any,
    fetchEntity: any,
    searchableColumns?: string[]
  ) => {
    if (!settingsLoaded || !dateSettings) {
      return;
    }

    if (newModel?.items?.some((filter: any) => !filter?.value)) {
      return; // Stop execution if any filter has no value
    }

    const currentState = apiRef.current.exportState();

    const updatedPageable: Pageable = {
      filters: currentState?.filter?.filterModel
        ? convertGridFilterModelToCustomFilters(currentState?.filter?.filterModel, searchableColumns)
        : [],
      sorts: currentState?.sorting?.sortModel ? processSortsFromGrid(currentState.sorting.sortModel) : [],
      limit: currentState?.pagination?.paginationModel?.pageSize || DEFAULT_ROW_COUNT,
      offset: currentState?.pagination?.paginationModel?.page || 0
    };

    fetchEntity(
      updatedPageable,
      dateSettings.beginDate,
      dateSettings.endDate,
      dateSettings.compareBeginDate,
      dateSettings.compareEndDate
    );
  };

  const handleSortModelChange = async (
    newModel: any,
    settingsLoaded: boolean,
    dateSettings: any,
    apiRef: any,
    fetchEntity: any
  ) => {
    if (!settingsLoaded || !dateSettings) {
      return;
    }

    const currentState = apiRef.current.exportState();

    const updatedPageable: Pageable = {
      filters: currentState?.filter?.filterModel ? convertGridFilterModelToCustomFilters(currentState?.filter?.filterModel) : [],
      sorts: currentState?.sorting?.sortModel ? processSortsFromGrid(currentState.sorting.sortModel) : [],
      limit: currentState?.pagination?.paginationModel?.pageSize || DEFAULT_ROW_COUNT,
      offset: currentState?.pagination?.paginationModel?.page || 0
    };

    fetchEntity(updatedPageable, dateSettings.beginDate, dateSettings.endDate);
  };

  const handlePageSizeChange = async (
    newPageSize: number,
    settingsLoaded: boolean,
    dateSettings: any,
    apiRef: any,
    fetchEntity: any
  ) => {
    if (!settingsLoaded || !dateSettings) {
      return;
    }

    const currentState = apiRef.current.exportState();

    const updatedPageable: Pageable = {
      filters: currentState?.filter?.filterModel ? convertGridFilterModelToCustomFilters(currentState?.filter?.filterModel) : [],
      sorts: currentState?.sorting?.sortModel ? processSortsFromGrid(currentState.sorting.sortModel) : [],
      limit: newPageSize,
      offset: currentState?.pagination?.paginationModel?.page || 0
    };

    fetchEntity(updatedPageable, dateSettings.beginDate, dateSettings.endDate);
  };

  const handlePageChange = async (newPage: number, settingsLoaded: boolean, dateSettings: any, apiRef: any, fetchEntity: any) => {
    if (!settingsLoaded || !dateSettings) {
      return;
    }

    const currentState = apiRef.current.exportState();

    const updatedPageable: Pageable = {
      filters: currentState?.filter?.filterModel ? convertGridFilterModelToCustomFilters(currentState?.filter?.filterModel) : [],
      sorts: currentState?.sorting?.sortModel ? processSortsFromGrid(currentState.sorting.sortModel) : [],
      limit: currentState?.pagination?.paginationModel?.pageSize || DEFAULT_ROW_COUNT,
      offset: newPage
    };

    fetchEntity(updatedPageable, dateSettings.beginDate, dateSettings.endDate);
  };

  const handleRefreshEntities = async (settingsLoaded: boolean, dateSettings: any, apiRef: any, fetchEntity: any) => {
    if (!settingsLoaded || !dateSettings) {
      return;
    }

    const currentState = apiRef.current.exportState();

    const updatedPageable: Pageable = {
      filters: currentState?.filter?.filterModel ? convertGridFilterModelToCustomFilters(currentState?.filter?.filterModel) : [],
      sorts: currentState?.sorting?.sortModel ? processSortsFromGrid(currentState.sorting.sortModel) : [],
      limit: currentState?.pagination?.paginationModel?.pageSize || DEFAULT_ROW_COUNT,
      offset: currentState?.pagination?.paginationModel?.page || 0
    };

    fetchEntity(updatedPageable, dateSettings.beginDate, dateSettings.endDate);
  };

  return {
    initializePerformanceGrid,
    fetchGridConfig,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange,
    handleRefreshEntities
  };
};

export default useVectorPerformanceGrid;
