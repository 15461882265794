import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo } from 'react';
import { campaignStatusOptions } from '../../../types/walmart-sams-club/campaign';
import NearMeDisabledIcon from '@mui/icons-material/NearMeDisabled';

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: 'left',
  '& .icon': {
    color: 'inherit'
  },
  '&.Live': {
    color: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.main}`
  },
  '&.Scheduled': {
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.main}`
  },
  '&.Proposal': {
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.main}`
  },
  '&.Paused': {
    color: theme.palette.action.disabled,
    border: `1px solid ${theme.palette.action.disabled}`
  },
  '&.Unsubmitted': {
    color: theme.palette.action.disabled,
    border: `1px solid ${theme.palette.action.disabled}`
  },
  '&.Cancelled': {
    color: theme.palette.action.disabled,
    border: `1px solid ${theme.palette.action.disabled}`
  }
}));

export const CampaignStatusChip = memo((props: any) => {
  const { status, sx } = props;

  const statusObject = campaignStatusOptions.find((option) => option.value === status.toLowerCase());

  if (!statusObject) {
    return null;
  }

  const Icon = statusObject.icon;

  return (
    <StyledChip className={status} icon={<Icon className="icon" />} size="small" label={status} sx={sx} variant="outlined" />
  );
});
