import {
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useAdsApi } from '../../../../../hooks/use-walmart-sams-club-api';
import {
  ReviewStepProps,
  CancelSbReviewRequest as WalmartCancelSbaReviewReqeust
} from '../../../../../types/walmart-sams-club/review';
import ReviewDetails from '../../review/review-details';
import { ReviewStatusChip } from '../../../../../components/sponsored-brands-sponsored-video/shared/review-status-chip';

export default function ProposalStep(props: ReviewStepProps) {
  const { review, ads, keywords, profileId, campaignId, adGroupId, refreshParent } = props;
  const { cancelSbaReviews: walmartCancelSbaReviews } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const confirmReviewCancel = async () => {
    setIsSubmitting(true);
    const requestObject: WalmartCancelSbaReviewReqeust = {
      campaignId: campaignId?.toString() || '',
      adGroupId: adGroupId?.toString() || '',
      reviewId: review?.reviewId?.toString() || ''
    };

    const response = await walmartCancelSbaReviews(profileId, requestObject);

    if (response.success) {
      if (refreshParent) {
        refreshParent();
      }

      enqueueSnackbar('Campaign Review successfully canceled.');
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsSubmitting(false);
    setCancelDialogIsOpen(false);
  };

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title={
            <Box sx={{ display: 'inline-flex' }}>
              <Typography variant="h6">REVIEW</Typography> <ReviewStatusChip status={review?.reviewStatus} sx={{ ml: 2 }} />
            </Box>
          }
          disableTypography={true}
        />
        <CardContent>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography>Review {review?.reviewId}</Typography>
              <Typography variant="caption">
                SB Profile | {ads?.length} items | {keywords?.length} keywords
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="error" onClick={() => setCancelDialogIsOpen(true)}>
                Cancel Review
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br />
      <ReviewDetails {...props} />

      <Dialog open={cancelDialogIsOpen} maxWidth="md" onClose={() => setCancelDialogIsOpen(false)}>
        <DialogContent>
          <Typography>Are you sure you want to cancel this review?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelDialogIsOpen(false)}>Nevermind, close this window</Button>
          <Button onClick={confirmReviewCancel} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
