import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Chip,
  Backdrop,
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Collapse
} from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import useAdsApi from '../../../../shared/hooks/use-wmt-map-updated-api';
import { ItemSelectionStep } from '../../campaigns/campaign-builder-form/item-selection-step';
import { AdGroupFormValues, AdItemCreateDto } from '../../../types/ad';

interface BulkAdItemCreationRecordDto {
  campaignId: number;
  adGroupId: number;
  campaignName: string;
  adGroupName: string;
  code: string;
  details: string;
}

interface BulkAdItemCreationResultDto {
  successfullyAdded: BulkAdItemCreationRecordDto[];
  skipped: BulkAdItemCreationRecordDto[];
  failed: BulkAdItemCreationRecordDto[];
}

interface Profile {
  profileId: number;
  name: string;
}

interface AdGroupItemDialogProps {
  open: boolean;
  onClose: () => void;
  apiRef: any;
  profileId: number;
}

const DEFAULT_BID = 0.5;

const validationSchema = Yup.object().shape({
  profileId: Yup.number().required('Profile is required'),
  items: Yup.array().min(1, 'At least one item must be selected')
});

const AdGroupItemDialog: React.FC<AdGroupItemDialogProps> = ({ open, onClose, apiRef, profileId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [bulkResult, setBulkResult] = useState<BulkAdItemCreationResultDto | null>(null);

  const [showDetails, setShowDetails] = useState(false);

  const { createBulkAdItems } = useAdsApi();

  const formik = useFormik<AdGroupFormValues>({
    initialValues: {
      profileId,
      items: []
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setBackDropOpen(true);
        setIsSubmitting(true);

        const selectedRows = Array.from(apiRef.current.getSelectedRows().values());

        const adItems: AdItemCreateDto[] = [];
        selectedRows.forEach((row: any) => {
          values.items.forEach((item) => {
            adItems.push({
              adGroupId: row.adGroupId,
              campaignId: row.campaignId,
              itemId: item.itemId,
              status: 'Enabled',
              bid: DEFAULT_BID
            });
          });
        });

        const response = await createBulkAdItems(profileId, adItems);

        if (!response.success) {
          enqueueSnackbar(response.errorMessage || 'Failed to create ad items', { variant: 'error' });
          handleClose();
          return;
        }

        const result: BulkAdItemCreationResultDto = response.body;
        setBulkResult(result);
      } catch (error) {
        enqueueSnackbar(error instanceof Error ? error.message : 'An error occurred', {
          variant: 'error'
        });
        handleClose();
      } finally {
        setIsSubmitting(false);
        setBackDropOpen(false);
      }
    }
  });

  const handleClose = () => {
    formik.resetForm();
    setBulkResult(null);
    setShowDetails(false);
    onClose();
  };

  const groupRecordsByCampaignAndAdGroup = (records: BulkAdItemCreationRecordDto[]) => {
    const campaignMap: {
      [campaignId: number]: {
        campaignId: number;
        campaignName: string;
        adGroups: {
          [adGroupId: number]: {
            adGroupId: number;
            adGroupName: string;
            items: BulkAdItemCreationRecordDto[];
          };
        };
      };
    } = {};

    for (const record of records) {
      if (!campaignMap[record.campaignId]) {
        campaignMap[record.campaignId] = {
          campaignId: record.campaignId,
          campaignName: record.campaignName,
          adGroups: {}
        };
      }
      const campaignEntry = campaignMap[record.campaignId];

      if (!campaignEntry.adGroups[record.adGroupId]) {
        campaignEntry.adGroups[record.adGroupId] = {
          adGroupId: record.adGroupId,
          adGroupName: record.adGroupName,
          items: []
        };
      }

      campaignEntry.adGroups[record.adGroupId].items.push(record);
    }

    return Object.values(campaignMap).map((campaign) => ({
      campaignId: campaign.campaignId,
      campaignName: campaign.campaignName,
      adGroups: Object.values(campaign.adGroups)
    }));
  };

  const renderBulkResultSection = (
    title: string,
    records: BulkAdItemCreationRecordDto[],
    icon: React.ReactNode,
    color: string
  ) => {
    if (!records || records.length === 0) return null;

    const grouped = groupRecordsByCampaignAndAdGroup(records);

    return (
      <Box mb={2}>
        <Typography variant="h6" sx={{ color, mb: 1 }}>
          {title}
        </Typography>
        {grouped.map((campaign) => (
          <List
            key={campaign.campaignId}
            dense
            subheader={
              <ListSubheader sx={{ lineHeight: '32px', fontWeight: 'bold', pl: 0 }}>
                Campaign: {campaign.campaignName || `Campaign ID ${campaign.campaignId}`}
              </ListSubheader>
            }
          >
            {campaign.adGroups.map((ag) => (
              <React.Fragment key={ag.adGroupId}>
                <Typography variant="subtitle1" sx={{ ml: 2 }}>
                  Ad Group: {ag.adGroupName || `AdGroup ID ${ag.adGroupId}`}
                </Typography>
                {ag.items.map((item, idx) => (
                  <ListItem key={idx} sx={{ ml: 4, color }}>
                    <ListItemIcon sx={{ color }}>{icon}</ListItemIcon>
                    <ListItemText primary={item.details ? `${item.details}` : `Code: ${item.code}`} />
                  </ListItem>
                ))}
              </React.Fragment>
            ))}
          </List>
        ))}
      </Box>
    );
  };

  const renderBulkResult = () => {
    if (!bulkResult) return null;

    const totalCount = bulkResult.successfullyAdded.length + bulkResult.skipped.length + bulkResult.failed.length;
    const successCount = bulkResult.successfullyAdded.length;

    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h5" sx={{ mb: 1 }}>
          {successCount} / {totalCount} items successfully added
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Button variant="outlined" onClick={() => setShowDetails((prev) => !prev)} size="small">
            {showDetails ? 'Hide Details' : 'Show Details'}
          </Button>
        </Box>

        <Collapse in={showDetails} unmountOnExit>
          {renderBulkResultSection(
            `Successfully Added (${bulkResult.successfullyAdded.length})`,
            bulkResult.successfullyAdded,
            <CheckCircleIcon />,
            green[600]
          )}
          {renderBulkResultSection(`Skipped (${bulkResult.skipped.length})`, bulkResult.skipped, <WarningIcon />, orange[600])}
          {renderBulkResultSection(`Failed (${bulkResult.failed.length})`, bulkResult.failed, <ErrorIcon />, red[600])}
        </Collapse>
      </Box>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }
        }}
      >
        {!bulkResult ? (
          <>
            <DialogTitle>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>Adding Items to:</Grid>
                <Grid item>
                  <Chip
                    label={`${apiRef.current.getSelectedRows().size} Ad Group${apiRef.current.getSelectedRows().size > 1 ? 's' : ''}`}
                  />
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent sx={{ flex: 1, overflowY: 'auto' }}>
              <form onSubmit={formik.handleSubmit} id="adGroupItemForm">
                <ItemSelectionStep formik={formik} stepNumber={1} profileId={profileId} />
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!formik.isValid || !formik.dirty || isSubmitting}
                form="adGroupItemForm"
              >
                {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Save'}
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>{renderBulkResult()}</DialogContent>
            <DialogActions sx={{ mt: 2 }}>
              <Button onClick={handleClose} variant="contained" color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AdGroupItemDialog;
