import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export interface UpdateAdRequest {
  profileId: number;
  adItem: { campaignId: number; adGroupId: number; itemId: number; status: string; bid: number };
}

export const AD_STATUS_OPTIONS = [
  {
    label: 'Enabled',
    value: 'enabled',
    icon: PlayArrowIcon
  },
  {
    label: 'Disabled',
    value: 'disabled',
    icon: PauseIcon
  }
];

export const buildUpdateAdRequest = (ad: any): UpdateAdRequest => {
  return {
    profileId: ad.profileId,
    adItem: { campaignId: ad.campaignId, adGroupId: ad.adGroupId, itemId: ad.itemId, status: ad.status, bid: ad.bid }
  };
};

export interface WalmartAdItem {
  adGroupId: Number;
  adId: Number;
  attributedRevenue: Number | null;
  bid: Number;
  campaignId: Number;
  clickThroughRate: null;
  clicks: null;
  conversionRate: null;
  cost: null;
  costPerClick: null;
  costPerConversion: null;
  impressions: null;
  itemId: Number;
  itemImageUrl: string;
  itemPageUrl: string;
  name: string;
  newToBrandOrders: null;
  newToBrandRevenue: Number | null;
  newToBrandUnits: null;
  orders: null;
  profileId: Number;
  returnOnAdSpend: null;
  reviewReason: null;
  reviewStatus: string;
  status: string;
  units: null;
}

export interface AdItemCreateDto {
  campaignId: number;
  adGroupId: number;
  itemId: string;
  status: 'Enabled' | 'Paused';
  bid: number;
}

interface ItemRequestDto {
  itemId: string;
  itemName: string;
}

export interface AdGroupFormValues {
  profileId: number;
  items: ItemRequestDto[];
}
