import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './_app';

import { PostHogProvider } from 'posthog-js/react';

const apiKey = process.env.REACT_APP_PUBLIC_POSTHOG_KEY || '';
const apiHost = process.env.REACT_APP_PUBLIC_POSTHOG_HOST || '';

const options = {
  api_host: apiHost,
  capture_pageview: false
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <PostHogProvider apiKey={apiKey} options={options}>
      <App />
    </PostHogProvider>
  </React.StrictMode>
);
