import React, { useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { ErrorReview } from '../campaign-builder-error-review';
import { generateMockErrors } from './mock-error-states';

const ErrorReviewTester = (props: any) => {
  const { handleBackButtonClick } = props;
  const [selectedScenario, setSelectedScenario] = useState('existingItems');

  const scenarios = ['existingItems', 'failedAdGroups', 'multipleErrors', 'keywordErrors', 'mixedSuccessFailure'];

  return (
    <Box sx={{ p: 4 }}>
      <Stack spacing={4}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Error Scenario</InputLabel>
          <Select value={selectedScenario} label="Error Scenario" onChange={(e) => setSelectedScenario(e.target.value)}>
            {scenarios.map((scenario) => (
              <MenuItem key={scenario} value={scenario}>
                {scenario}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <ErrorReview
          campaignBuilderResponse={{
            success: false,
            body: generateMockErrors(selectedScenario)
          }}
          handleBackButtonClick={handleBackButtonClick}
          genericErrorText="There were some issues creating your campaign. Please review the errors below."
        />
      </Stack>
    </Box>
  );
};

export default ErrorReviewTester;
