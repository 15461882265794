import { Autocomplete, Box, TextField, Tooltip } from '@mui/material';
import { CampaignOptionsSelections } from '../../shared/types/walmart-sams-club/campaign-options-selections';
import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface CampaignOptionSelectProps {
  id: string;
  label: string;
  value: CampaignOptionsSelections;
  onChange: (value: CampaignOptionsSelections) => void;
  tooltipTitle?: string;
  sx?: any;
}

const CampaignOptionSelect: React.FC<CampaignOptionSelectProps> = ({ id, label, value, onChange, tooltipTitle, sx }) => {
  return (
    <Box sx={sx}>
      <Box mr={2} display="flex" flexDirection="row" alignItems="center" gap={2}>
        <Autocomplete
          id={id}
          renderInput={(params) => <TextField {...params} size="medium" label={label} />}
          value={value}
          options={Object.values(CampaignOptionsSelections)}
          onChange={(_event, value) => onChange(value as CampaignOptionsSelections)}
          autoHighlight
          includeInputInList
          disableClearable
          disablePortal
          sx={{ width: '250px' }}
        />
        {tooltipTitle && (
          <Tooltip title={tooltipTitle}>
            <HelpOutlineIcon sx={{ cursor: 'pointer' }} />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default CampaignOptionSelect;
