import { FC, useEffect, useState } from 'react';
import { useLocation, Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Link, Stack, Breadcrumbs as MUIBreadcrumbs, Typography, Skeleton } from '@mui/material';
import { useAdsApi } from '../../../shared/hooks/use-walmart-sams-club-api';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSnackbar } from 'notistack';
import { useCurrentRetailerPlatform } from '../../../shared/hooks/use-current-retailer-platform';

const WalmartAndSamsClubBreadCrumbs: FC = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const retailerPlatform = useCurrentRetailerPlatform();
  const { enqueueSnackbar } = useSnackbar();
  const { getProfile, getCampaign, getAdGroup } = useAdsApi(retailerPlatform);

  // Direct access from searchParams instead of maintaining duplicate state
  const profileId = searchParams.get('profileId') || '';
  const campaignId = searchParams.get('campaignId') || '';
  const adGroupId = searchParams.get('adGroupId') || '';
  const adName = searchParams.get('adName') || '';

  const [profileName, setProfileName] = useState<string>('');
  const [campaignName, setCampaignName] = useState<string>('');
  const [adGroupName, setAdGroupName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      setError(false);

      try {
        await Promise.all(
          [profileId && fetchProfile(), campaignId && fetchCampaign(), adGroupId && fetchAdGroup()].filter(Boolean)
        );
      } catch (err) {
        setError(true);
        enqueueSnackbar('Error loading breadcrumb data', { variant: 'error' });
      }

      setIsLoading(false);
    };

    if (profileId || campaignId || adGroupId) {
      initialize();
    } else {
      setIsLoading(false);
    }
  }, [profileId, campaignId, adGroupId, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProfile = async () => {
    const response = await getProfile(parseInt(profileId, 10));
    if (response.success) {
      setProfileName(response.body?.name || '');
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  const fetchCampaign = async () => {
    const response = await getCampaign(parseInt(profileId, 10), parseInt(campaignId, 10));
    if (response.success) {
      setCampaignName(response.body?.name || '');
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  const fetchAdGroup = async () => {
    if (!adGroupId) {
      return;
    }

    const response = await getAdGroup(parseInt(profileId, 10), parseInt(campaignId, 10), parseInt(adGroupId, 10));
    if (response.success) {
      setAdGroupName(response.body?.name || '');
    } else {
      throw new Error(response.errorMessage);
    }
  };

  const crumbModel = [
    {
      key: `/${retailerPlatform}/profiles`,
      name: 'All Profiles',
      href: `/${retailerPlatform}/profiles`,
      show: true
    },
    {
      key: `/${retailerPlatform}/campaigns`,
      name: profileName,
      href: `/${retailerPlatform}/campaigns?profileId=${profileId}`,
      show: !!profileId && !!profileName
    },
    {
      key: `/${retailerPlatform}/ad-groups`,
      name: campaignName,
      href: `/${retailerPlatform}/ad-groups?campaignId=${campaignId}&profileId=${profileId}`,
      show: !!campaignId && !!campaignName
    },
    {
      key: `/${retailerPlatform}/ads`,
      name: adGroupName,
      href: `/${retailerPlatform}/ads?campaignId=${campaignId}&profileId=${profileId}&adGroupId=${adGroupId}`,
      show: !!adGroupId && !!adGroupName
    },
    {
      key: `/${retailerPlatform}/keywords`,
      name: adName,
      href: `/${retailerPlatform}/keywords?campaignId=${campaignId}&profileId=${profileId}&adGroupId=${adGroupId}&adName=${adName}`,
      show: !!adName
    }
  ];

  const getCrumbs = () => {
    const currentLocation = location.pathname;
    const crumbs: React.ReactElement[] = [];

    crumbModel
      .filter((crumb) => crumb.show)
      .forEach((crumb) => {
        if (crumb.key === currentLocation) {
          crumbs.push(<Typography key={crumb.key}>{crumb.name}</Typography>);
        } else {
          crumbs.push(
            <Link to={crumb.href} component={RouterLink} key={crumb.key}>
              {crumb.name}
            </Link>
          );
        }
      });

    return crumbs;
  };

  if (error) {
    return null;
  }

  return (
    <Stack>
      {isLoading ? (
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Skeleton sx={{ minWidth: 350 }} />
        </Stack>
      ) : (
        <MUIBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {getCrumbs()}
        </MUIBreadcrumbs>
      )}
    </Stack>
  );
};

export default WalmartAndSamsClubBreadCrumbs;
