import { Box, Chip, Grid, Typography } from '@mui/material';
import {
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarProps,
  GridToolbarQuickFilter,
  gridFilterModelSelector
} from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useCurrentRetailerPlatform } from '../../hooks/use-current-retailer-platform';
import { Platform } from '../../types/platforms';
import GridDateRangePicker from '../grid-components/date-picker/grid-date-picker';

const defaultDateConfig = {
  dateSettings: {},
  dateSettingsLoading: false,
  handleDateRangeChange: () => {},
  handleCompareRangeChange: () => {},
  handleOnSelectShortcut: () => {}
};

export const PerformanceGridToolbar = (props: GridToolbarProps) => {
  const {
    apiRef,
    setFilterButtonEl,
    bulkActions,
    disableGridDateRangePicker,
    dateConfig = defaultDateConfig,
    allowQuickFilterSearch = false
  } = props;

  const {
    dateSettings,
    dateSettingsLoading,
    handleDateRangeChange,
    handleCompareRangeChange,
    handleOnSelectShortcut,
    enableCompare,
    maxDate
  } = dateConfig;

  const [isMounted, setIsMounted] = useState(false); // Track mount state
  const currentRetailerPlatform = useCurrentRetailerPlatform();

  useEffect(() => {
    setIsMounted(true); // Component has mounted
    return () => {
      setIsMounted(false); // Component is unmounting
    };
  }, []);

  const filterModel = gridFilterModelSelector(apiRef);

  const shouldShowDateRangePicker = !disableGridDateRangePicker && !dateSettingsLoading;

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        flexWrap={'nowrap'}
        style={{ borderBottom: '1px solid #ccc', paddingBottom: '4px', paddingTop: '4px' }}
        ref={shouldShowDateRangePicker ? null : setFilterButtonEl}
      >
        {allowQuickFilterSearch && (
          <Box sx={{ ml: 1.5 }}>
            <GridToolbarQuickFilter debounceMs={500} sx={{ ml: 2 }} />
          </Box>
        )}
        {bulkActions && (
          <>
            {currentRetailerPlatform !== Platform.AMAZON.value && (
              <Typography variant="h6" sx={{ ml: 2, mr: 2 }}>
                Actions:
              </Typography>
            )}
            {bulkActions}
          </>
        )}
        <Box
          sx={{
            mr: 'auto'
          }}
        ></Box>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        <GridToolbarFilterButton ref={shouldShowDateRangePicker ? setFilterButtonEl : null} />
        {shouldShowDateRangePicker ? (
          <Box marginLeft="10px" marginRight="10px">
            <GridDateRangePicker
              handleDateRangeChange={handleDateRangeChange}
              handleCompareChange={handleCompareRangeChange}
              handleOnSelectShortcut={handleOnSelectShortcut}
              maxDate={maxDate}
              initialDateRange={[dayjs(dateSettings.beginDate), dayjs(dateSettings.endDate)]}
              enableCompare={enableCompare}
              initialCompareDateRange={
                dateSettings.compareBeginDate && dateSettings.compareEndDate
                  ? [dayjs(dateSettings.compareBeginDate), dayjs(dateSettings.compareEndDate)]
                  : undefined
              }
            />
          </Box>
        ) : null}
      </Grid>
      <Grid container alignItems={'center'}>
        {filterModel?.items.length > 0 ? (
          <Box>
            {filterModel?.items.map((item, index) => {
              if (item.value === undefined || item.value === null || item.value === '') {
                return;
              }

              return (
                <Chip
                  key={index}
                  sx={{ margin: '10px' }}
                  label={`${item.field} ${item.operator} ${item.value}`}
                  onDelete={() => apiRef.current.deleteFilterItem(item)}
                  onClick={() => apiRef.current.showFilterPanel()}
                />
              );
            })}
          </Box>
        ) : null}
      </Grid>
    </>
  );
};
