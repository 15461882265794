import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, Link, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import MetricsChart from '../../../shared/components/metrics-chart';
import Page from '../../../shared/components/page';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useVectorPerformanceGrid from '../../../shared/hooks/use-vector-performance-grid';
import { BreadCrumbParams, useAdsApi } from '../../../shared/hooks/use-wmt-map-updated-api';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { getUpdateCampaignRequest } from '../../../shared/types/walmart-sams-club/campaign';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { CAMPAIGN_COLUMNS } from '../../components/grid-components/column-configurations/campaign-columns';
import CampaignBulkActions from './bulk-actions/campaign-bulk-actions';
import { DATE_SETTINGS_KEY } from '../../../shared/utilities/date-utilities';
import useSharedApi from '../../../shared/hooks/use-shared-api';

const Campaigns: FC = () => {
  const { updateUserSetting } = useSharedApi();
  const { getCampaigns, updateCampaign, getCampaignTotals } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [campaignTotals, setCampaignTotals] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);
  const [comparingTimeSeriesData, setComparingTimeSeriesData] = useState<any[]>([]);

  let [searchParams] = useSearchParams();
  let profileId = useMemo(() => (searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null), [searchParams]);

  const breadcrumbParams: BreadCrumbParams[] = useMemo(() => [{ profileId }], [profileId]);

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange,
    handleRefreshEntities
  } = useVectorPerformanceGrid(GridConfigSettings.WALMART_CAMPAIGN);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{
    beginDate: string;
    endDate: string;
    compareBeginDate?: string;
    compareEndDate?: string;
  }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchCampaignsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCampaignsData = async (
    pageable: Pageable,
    beginDate: string,
    endDate: string,
    compareBeginDate?: string,
    compareEndDate?: string
  ) => {
    if (!breadcrumbParams) {
      return;
    }

    setIsLoading(true);

    // Get regular campaigns and totals data
    const campaignsPromise = getCampaigns([...breadcrumbParams], pageable, beginDate, endDate, true);
    const totalsPromise = getCampaignTotals([...breadcrumbParams], pageable, beginDate, endDate);

    // Await the promises separately
    const campaignsResponse = await campaignsPromise;
    const totalsResponse = await totalsPromise;

    // Process regular campaign data
    if (campaignsResponse.success) {
      setCampaigns(campaignsResponse.body.dataGrid.records);
      setRowCount(campaignsResponse.body?.dataGrid.totalFilteredRecords || DEFAULT_ROW_COUNT);
      setTimeSeriesData(campaignsResponse.body.timeSeriesData || []);
    } else {
      enqueueSnackbar(campaignsResponse.errorMessage, { variant: 'error' });
    }

    // Process totals data
    if (totalsResponse.success) {
      setCampaignTotals(totalsResponse.body);
    } else {
      enqueueSnackbar(totalsResponse.errorMessage, { variant: 'error' });
    }

    // If comparing dates are provided, get and process comparing data
    if (compareBeginDate && compareEndDate) {
      const comparingCampaignsResponse = await getCampaigns(
        [...breadcrumbParams],
        pageable,
        compareBeginDate,
        compareEndDate,
        true
      );

      if (comparingCampaignsResponse.success) {
        setComparingTimeSeriesData(comparingCampaignsResponse.body.timeSeriesData || []);
      } else {
        enqueueSnackbar(comparingCampaignsResponse.errorMessage, { variant: 'error' });
      }
    }

    setIsLoading(false);
  };

  const handleResetCompareDate = () => {
    if (!dateSettings) return;

    let newDateSettings = {
      ...dateSettings,
      compareBeginDate: '',
      compareEndDate: ''
    };
    setDateSettings(newDateSettings);
    updateUserSetting(DATE_SETTINGS_KEY, JSON.stringify(newDateSettings));
    setComparingTimeSeriesData([]);
  };

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    const updateCampaignRequest = getUpdateCampaignRequest(newRow, oldRow);

    if (!updateCampaignRequest) {
      return oldRow;
    }

    setIsLoading(true);

    const response = await updateCampaign(updateCampaignRequest);

    setIsLoading(false);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    return newRow;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Page title="Campaigns">
        <Container maxWidth="xl" style={{ height: '93vh', marginTop: '10px' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Box display="flex" alignItems={'center'}>
              <Typography variant="h4">Campaigns</Typography>
              <Box
                sx={{
                  mr: 'auto'
                }}
              ></Box>
              <Link
                to={`/walmart/campaigns/create${profileId ? `?profileId=${profileId}` : ''}`}
                component={RouterLink}
                style={{ textDecoration: 'none' }}
              >
                <Button startIcon={<AddIcon fontSize="small" />} variant="contained">
                  Create Campaign
                </Button>
              </Link>
            </Box>
            {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
              <DataGridPremium
                rows={[]}
                disableRowGrouping
                columns={CAMPAIGN_COLUMNS}
                loading={true}
                processRowUpdate={processRowUpdate}
                initialState={{}}
              />
            ) : (
              <>
                {!!timeSeriesData && (
                  <Box display="flex" alignItems={'center'} my={'1%'}>
                    <MetricsChart
                      timeSeriesData={timeSeriesData}
                      comparingTimeSeriesData={comparingTimeSeriesData}
                      isMetricsChartLoading={isLoading}
                      chartSettingsKey="WalmartCampaignsChartSetting"
                    />
                  </Box>
                )}
                <VectorPerformanceGrid
                  apiRef={apiRef}
                  loading={isLoading}
                  processRowUpdate={processRowUpdate}
                  rows={campaigns}
                  columns={CAMPAIGN_COLUMNS}
                  initialState={initialSettings?.config}
                  saveGridConfig={saveGridConfig}
                  dateConfig={{
                    dateSettings: dateSettings,
                    dateSettingsLoading: dateSettingsLoading,
                    enableCompare: true,
                    maxDate: dayjs().subtract(2, 'day'),
                    handleDateRangeChange: (newDateRange: DateRange<Dayjs>) => {
                      handleDateChange(
                        {
                          beginDate: newDateRange[0]?.format('YYYY-MM-DD'),
                          endDate: newDateRange[1]?.format('YYYY-MM-DD'),
                          compareBeginDate: dateSettings?.compareBeginDate, // Keep the compare dates the same, from state
                          compareEndDate: dateSettings?.compareEndDate // Keep the compare dates the same, from state
                        },
                        dateSettings,
                        dateSettingsLoading,
                        apiRef,
                        fetchCampaignsData,
                        setDateSettings
                      );
                    },
                    handleCompareRangeChange: (newCompareDateRange: DateRange<Dayjs>) => {
                      if (newCompareDateRange[0] !== null && newCompareDateRange[1] !== null) {
                        handleDateChange(
                          {
                            beginDate: dateSettings?.beginDate, // Keep the regular dates the same, from state
                            endDate: dateSettings?.endDate, // Keep the regular dates the same, from state
                            compareBeginDate: newCompareDateRange[0]?.format('YYYY-MM-DD'),
                            compareEndDate: newCompareDateRange[1]?.format('YYYY-MM-DD')
                          },
                          dateSettings,
                          dateSettingsLoading,
                          apiRef,
                          fetchCampaignsData,
                          setDateSettings
                        );
                      } else {
                        handleResetCompareDate();
                      }
                    },
                    handleOnSelectShortcut: (newDateRange: DateRange<Dayjs>, newCompareDateRange: DateRange<Dayjs>) => {
                      handleDateChange(
                        {
                          // Update all dates.
                          // When a shortcut is selected for the main date range,
                          // update the comparison range based on the corresponding previous period
                          beginDate: newDateRange[0]?.format('YYYY-MM-DD'),
                          endDate: newDateRange[1]?.format('YYYY-MM-DD'),
                          compareBeginDate: newCompareDateRange[0]?.format('YYYY-MM-DD'),
                          compareEndDate: newCompareDateRange[1]?.format('YYYY-MM-DD')
                        },
                        dateSettings,
                        dateSettingsLoading,
                        apiRef,
                        fetchCampaignsData,
                        setDateSettings
                      );
                    }
                  }}
                  getRowId={(row: any) => row.campaignId}
                  getRowClassName={(params) => `row-status--${params.row.status}`}
                  bulkActions={
                    <CampaignBulkActions
                      setIsLoading={setIsLoading}
                      refreshCampaigns={() => handleRefreshEntities(settingsLoaded, dateSettings, apiRef, fetchCampaignsData)}
                    />
                  }
                  handleFilterModelChange={(newModel: GridFilterModel) => {
                    handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                  }}
                  handleSortModelChange={(newModel: GridSortModel) => {
                    handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                  }}
                  handlePageChange={(page: number) => {
                    handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                  }}
                  handlePageSizeChange={(pageSize: number) => {
                    handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                  }}
                  rowCount={rowCount}
                  aggregatedTotals={campaignTotals}
                />
              </>
            )}
          </Stack>
        </Container>
      </Page>
    </>
  );
};

export default Campaigns;
