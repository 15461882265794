import { ArrowDropDownSharp, InfoOutlined } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Collapse,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
  TooltipProps,
  Typography,
  colors,
  styled,
  tooltipClasses
} from '@mui/material';
import { green, purple } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import type { ApexOptions } from 'apexcharts';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useUserSeries } from '../hooks/use-chart-config';
import { metricsChartDateFormat } from '../utilities/date-utilities';
import { Chart } from './chart';
import { abbreviateMetricDisplayName, formatMetricValue } from '../utilities/chart-utilities';
import ChartSkeletonLoader from './chart-loader-skeleton';
import { ChartDelta } from './chart-delta';

const containerStyle = {
  width: '100%',
  height: '100%',
  bgcolor: 'background.paper',
  borderRadius: '0.5rem',
  border: '1px solid',
  borderColor: 'divider',
  padding: '0.5rem'
};

const headerContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

const buttonStyle = {
  width: '0.2rem',
  height: '50%'
};

const chipContainerStyle = {
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'auto',
  gap: '1rem',
  mr: '1rem',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& > *': {
    m: 0.5
  }
};

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#36454F'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#36454F'
  }
}));

const useChartOptions = (timeSeries: any, series: any[], colors: any[]): ApexOptions => {
  const { palette } = useTheme();

  return {
    chart: {
      animations: {
        enabled: false
      },
      background: palette.background.paper,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        }
      }
    },
    legend: {
      show: false,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      formatter: (value) => {
        return abbreviateMetricDisplayName(
          timeSeries.metrics.find((metric: { name: string }) => metric.name === value).displayName
        );
      },
      labels: {
        colors: palette.text.secondary
      },
      onItemClick: {
        toggleDataSeries: true
      }
    },
    colors: colors,
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1,
      type: 'gradient',
      gradient: {
        shade: palette.mode === 'dark' ? 'dark' : 'light',
        type: 'vertical',
        shadeIntensity: 0.6,
        opacityFrom: 0.3,
        opacityTo: 0.1,
        stops: [0, 100]
      }
    },
    grid: {
      borderColor: palette.divider,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    markers: {
      radius: 4,
      shape: 'circle',
      size: 2,
      strokeWidth: 0,
      hover: {
        sizeOffset: 2
      }
    },
    stroke: {
      curve: 'straight',
      lineCap: 'square',
      width: 3
    },
    theme: {
      mode: palette.mode
    },
    xaxis: {
      categories: timeSeries.categories,
      type: 'datetime',
      labels: {
        style: {
          colors: palette.text.secondary
        },
        format: metricsChartDateFormat(timeSeries.categories)
      },
      crosshairs: {
        show: true,
        position: 'back',
        stroke: {
          color: palette.divider,
          width: 2
        }
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: palette.divider,
        height: 6,
        offsetX: 0,
        offsetY: 0
      }
    },
    yaxis: series,
    noData: {
      text: 'No Data Available'
    },
    tooltip: {
      x: {
        format: metricsChartDateFormat(timeSeries.categories)
      },
      y: {
        formatter: (val: any, opt) => {
          return formatMetricValue(
            val,
            false,
            timeSeries.metrics.find(
              (metric: { displayName: string }) =>
                abbreviateMetricDisplayName(metric.displayName) === opt.w.globals.lastYAxis[opt.seriesIndex].title.text
            ).dataType
          );
        },
        title: {
          formatter: (seriesName: string) => {
            return `${abbreviateMetricDisplayName(
              timeSeries.metrics.find((metric: { name: string }) => metric.name === seriesName).displayName
            )}: `;
          }
        }
      },
      marker: {
        show: true
      },
      theme: palette.mode
    }
  };
};

interface TimeSeriesChartProps {
  timeSeriesData: any;
  comparingTimeSeriesData?: any;
  isMetricsChartLoading?: boolean;
  chartSettingsKey: string;
}

const MetricsChart = ({
  timeSeriesData,
  comparingTimeSeriesData,
  isMetricsChartLoading = false,
  chartSettingsKey
}: TimeSeriesChartProps) => {
  const { palette } = useTheme();
  const isFirstRender = useRef(true);

  const [selectedSeries, setSelectedSeries] = useState<string[]>([]);
  const [seriesColors, setSeriesColors] = useState<{ [key: string]: string }>({});
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { userSeriesData, saveUserSeriesData } = useUserSeries(chartSettingsKey);
  const [boxClickedValue, setBoxClickedValue] = useState<string>('');
  const [anchorEls, setAnchorEls] = useState<null[] | HTMLElement[]>([null, null, null, null, null]);
  const [series, setSeries] = useState<any[]>([]);
  const [isMenuItemHovered, setIsMenuItemHovered] = useState<string | null>(null);
  const [hiddenSeries, setHiddenSeries] = useState<string[]>([]);

  useEffect(() => {
    const currentlyDisplayedSeries = selectedSeries.filter((seriesName) => !hiddenSeries.includes(seriesName));
    const orderedSeries = currentlyDisplayedSeries.map((seriesName, index) => {
      const metric = timeSeriesData?.metrics.find((metric: { name: string }) => metric.name === seriesName);
      const opposite = index % 2 === 0;

      return {
        seriesName: metric.name,
        title: { text: abbreviateMetricDisplayName(metric.displayName) },
        opposite,
        labels: {
          formatter: (value: number) => {
            return formatMetricValue(value, true, metric.dataType);
          }
        },
        color: seriesColors[metric.name],
        data: metric.data,
        min: 0
      };
    });

    setSeries(orderedSeries);
  }, [selectedSeries, hiddenSeries, timeSeriesData.metrics, seriesColors]);

  useEffect(() => {
    const colors = [palette.info.dark, palette.warning.dark, purple[600], green[600], palette.error.light];
    const initialColors: { [key: string]: string } = {};

    if (userSeriesData && userSeriesData.selectedSeries && userSeriesData.selectedSeries.length > 0) {
      // Filter out any series in initialSelectedSeries (coming from userSettings) that do not exist in timeSeriesData.metrics
      const validMetrics = timeSeriesData.metrics.map((metric: { name: any }) => metric.name);
      let initialSelectedSeries = userSeriesData.selectedSeries.filter((series) => validMetrics.includes(series));

      // Add new metrics if the initialSelectedSeries has less than 5 valid metrics
      let newSelectedSeries = [];
      if (initialSelectedSeries.length < 5) {
        newSelectedSeries = timeSeriesData.metrics
          .filter((metric: { name: string }) => !initialSelectedSeries.includes(metric.name))
          .map((metric: { name: any }) => metric.name)
          .slice(0, 5 - initialSelectedSeries.length);
      }

      const finalSelectedSeries = [...initialSelectedSeries, ...newSelectedSeries];
      setSelectedSeries(finalSelectedSeries);

      finalSelectedSeries.forEach((seriesName, index) => {
        initialColors[seriesName] = colors[index];
      });
      setSeriesColors(initialColors);
    } else if (timeSeriesData.metrics && timeSeriesData.metrics.length >= 5) {
      const initialSelectedSeries = timeSeriesData.metrics.slice(0, 5).map((metric: any) => metric.name);
      setSelectedSeries(initialSelectedSeries);

      initialSelectedSeries.forEach((seriesName: string, index: any) => {
        initialColors[seriesName] = colors[index];
      });
      setSeriesColors(initialColors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSeriesData]);

  useEffect(() => {
    if (isFirstRender.current && selectedSeries.length === 5) {
      setHiddenSeries(selectedSeries.slice(2, 5));
      isFirstRender.current = false;
    }
  }, [selectedSeries]);

  const visibleSeriesColors = selectedSeries
    .filter((series) => !hiddenSeries.includes(series))
    .map((series) => seriesColors[series]);

  const chartOptions = useChartOptions(timeSeriesData, series, visibleSeriesColors);

  const handleSeriesChange = (oldSeriesName: string, newSeriesName: string) => {
    const newSelectedSeries = selectedSeries.map((seriesName) => (seriesName === boxClickedValue ? newSeriesName : seriesName));

    if (hiddenSeries.includes(oldSeriesName)) {
      setHiddenSeries(
        hiddenSeries
          .filter((seriesName) => seriesName !== oldSeriesName)
          .concat([newSeriesName])
          .sort()
      );
    }

    setSelectedSeries(newSelectedSeries);
    saveUserSeriesData(newSelectedSeries);

    const newSeriesColors = { ...seriesColors };
    newSeriesColors[newSeriesName] = newSeriesColors[oldSeriesName];
    delete newSeriesColors[oldSeriesName];

    setSeriesColors(newSeriesColors);

    setAnchorEls((prevAnchorEls: any) =>
      prevAnchorEls.map((_: any, i: any) => (i === selectedSeries.indexOf(boxClickedValue) ? null : _))
    );
  };

  const handleSeriesToggle = (seriesToToggleVisibility: string) => {
    if (hiddenSeries.includes(seriesToToggleVisibility)) {
      setHiddenSeries(hiddenSeries.filter((seriesName) => seriesName !== seriesToToggleVisibility));
    } else {
      setHiddenSeries([...hiddenSeries, seriesToToggleVisibility]);
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleBoxClick = (event: React.MouseEvent<HTMLElement>, clickedBoxValue: string) => {
    const anchorEl = event.currentTarget;
    const index = selectedSeries.indexOf(clickedBoxValue);
    setAnchorEls((prevAnchorEls: any) => prevAnchorEls.map((_: any, i: any) => (i === index ? anchorEl : _)));
    setBoxClickedValue(clickedBoxValue);
  };

  return !timeSeriesData.metrics ? (
    <ChartSkeletonLoader />
  ) : (
    <Box sx={containerStyle}>
      <Box sx={headerContainerStyle}>
        <Box sx={chipContainerStyle}>
          {Array.isArray(selectedSeries) && selectedSeries.length > 0 ? (
            selectedSeries.map((seriesName, index) => {
              return (
                <Box
                  sx={{
                    borderTop: hiddenSeries.includes(seriesName) ? 'none' : '3px solid',
                    borderColor: hiddenSeries.includes(seriesName) ? palette.grey[500] : seriesColors[seriesName],
                    borderRadius: '2px',
                    paddingX: '0.5rem',
                    paddingTop: hiddenSeries.includes(seriesName) ? '3px' : '',
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.6)',
                    width: '20%',
                    cursor: isCollapsed ? 'auto' : 'pointer'
                  }}
                  key={`${seriesName} - ${index}`}
                  onClick={isCollapsed ? () => {} : () => handleSeriesToggle(seriesName)}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start'
                    }}
                  >
                    <Box
                      onClick={
                        isCollapsed
                          ? () => {}
                          : (event) => {
                              event.stopPropagation();
                              if (!isCollapsed) {
                                handleBoxClick(event, seriesName);
                              }
                            }
                      }
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        ':hover': {
                          backgroundColor: isCollapsed ? 'unset' : palette.mode === 'dark' ? colors.grey[800] : colors.grey[100]
                        },
                        borderRadius: '10px'
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          alignItems: 'center',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {abbreviateMetricDisplayName(
                          timeSeriesData?.metrics?.find((metric: { name: string }) => metric.name === seriesName)?.displayName
                        ) || null}
                      </Typography>
                      {!isCollapsed ? <ArrowDropDownSharp /> : <></>}
                    </Box>
                    <BootstrapTooltip
                      title={timeSeriesData.metrics.find((metric: { name: string }) => metric.name === seriesName).description}
                      arrow
                      placement="top"
                    >
                      <InfoOutlined sx={{ scale: '0.8', ml: 'auto', color: colors.grey[500] }} />
                    </BootstrapTooltip>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'end'
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      {formatMetricValue(
                        timeSeriesData.metrics.find((metric: { name: string }) => metric.name === seriesName)?.tileValue,
                        false,
                        timeSeriesData.metrics.find((metric: { name: string }) => metric.name === seriesName)?.dataType
                      )}
                    </Typography>
                    <ChartDelta
                      previousValue={
                        comparingTimeSeriesData?.metrics?.find((metric: { name: string }) => metric.name === seriesName)
                          ?.tileValue
                      }
                      currentValue={
                        timeSeriesData.metrics.find((metric: { name: string }) => metric.name === seriesName)?.tileValue
                      }
                      dataType={timeSeriesData.metrics.find((metric: { name: string }) => metric.name === seriesName)?.dataType}
                    />
                  </Box>
                  <Menu
                    anchorEl={anchorEls[index] || null}
                    key={seriesName}
                    open={Boolean(anchorEls[index])}
                    onClose={() =>
                      setAnchorEls((prevAnchorEls: any) => prevAnchorEls.map((_: any, i: any) => (i === index ? null : _)))
                    }
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    PaperProps={{
                      style: {
                        maxHeight: 400
                      }
                    }}
                  >
                    {timeSeriesData.metrics.map((metric: any) => (
                      <MenuItem
                        key={`${metric.name} - ${seriesName} - ${index}`}
                        value={metric.name}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleSeriesChange(seriesName, metric.name);
                        }}
                        disabled={selectedSeries.includes(metric.name)}
                        onMouseEnter={() => setIsMenuItemHovered(metric.name)}
                        onMouseLeave={() => setIsMenuItemHovered(null)}
                      >
                        <ListItemText primary={abbreviateMetricDisplayName(metric.displayName)} />
                        <BootstrapTooltip
                          title={
                            timeSeriesData.metrics.find((metric: { name: string }) => metric.name === seriesName).description
                          }
                          arrow
                          placement="right"
                        >
                          <InfoOutlined
                            sx={{
                              scale: '0.6',
                              ml: '0.6rem',
                              visibility: isMenuItemHovered === metric.name ? 'visible' : 'hidden',
                              color: colors.grey[500]
                            }}
                          />
                        </BootstrapTooltip>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              );
            })
          ) : (
            <Typography variant="body1">No metrics selected</Typography>
          )}
        </Box>
        <Button onClick={toggleCollapse} variant="outlined" sx={buttonStyle}>
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </Button>
      </Box>
      <Collapse in={!isCollapsed}>
        <span
          style={{
            backgroundColor: isMetricsChartLoading ? palette.divider : 'transparent',
            opacity: isMetricsChartLoading ? 0.5 : 1
          }}
        >
          <Chart
            height={300}
            width="100%"
            options={chartOptions}
            series={series.map((metric: { seriesName: any; data: any }) => {
              return { name: metric.seriesName, data: metric.data };
            })}
            type="area"
            sx={{ alignSelf: 'center' }}
          />
        </span>
      </Collapse>
    </Box>
  );
};

export default MetricsChart;
