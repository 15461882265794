import { ArrowRight } from '@mui/icons-material';
import { Backdrop, Box, Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorReview } from './campaign-builder-form/campaign-builder-error-review';
import { useAdsApi } from '../../../shared/hooks/use-walmart-sams-club-api';
import { useAdsApi as useUpdatedAdsApi } from '../../../shared/hooks/use-wmt-map-updated-api';
import { isSbResponseEligibleForReview } from '../../../shared/types/walmart-sams-club/review';
import { ResponseObject } from '../../../shared/utilities/fetch-utilities';
import {
  createMultiItemAdGroupCampaignBuilderRequest,
  createSingleItemAdGroupCampaignBuilderRequest
} from '../../types/campaign-builder-request';
import { AdGroupModeSelection } from './ad-group-mode-selector';
import { AdGroupSettingsStep } from './campaign-builder-form/ad-group-settings-step';
import {
  CAMPAIGN_BUILDER_INITIAL_VALUES,
  CampaignFormValues,
  AdGroupMode,
  CONDITIONAL_CAMPAIGN_SCHEMA
} from './campaign-builder-form/campaign-builder-form-config';
import { CampaignSettingsStep } from './campaign-builder-form/campaign-settings-step';
import { ItemSelectionStep } from './campaign-builder-form/item-selection-step';
import { MIAGSuccessfulCampaignCreation } from './campaign-builder-form/miag-successful-campaign-creation';
import { SuccessfulCampaignCreation } from './campaign-builder-form/successful-campaign-creation';
import { TargetingSettingsStep } from './campaign-builder-form/targeting-settings-step';
import ErrorReviewTester from './campaign-builder-form/error-testing/error-review-tester';
import { WalmartSamsClubCampaignType } from '../../../shared/types/walmart-sams-club/campaign';

export enum ContentSteps {
  Create = 0,
  Error = 1,
  Complete = 2
}

export interface CreateCampaignState {
  profiles: any[];
  isSubmitting: boolean;
  shouldShowReview: boolean;
  currentContentToShow: ContentSteps;
  adGroupMode: AdGroupMode;
  campaignBuilderResponse: ResponseObject;
  allowAdGroupModeSelection: boolean;
  siagItemStepNumber: number;
  siagTargetStepNumber: number;
}

export const CampaignBuilder: FC = (props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getProfiles, walmartBuildCampaign: buildSingleItemAdGroupCampaign } = useAdsApi();
  const { walmartBuildMultiItemAdGroupCampaign: buildMultiItemAdGroupCampaign } = useUpdatedAdsApi();

  const isDevelopment = process.env.NODE_ENV === 'development';
  const [showErrorTesting, setShowErrorTesting] = useState(false);

  const [campaignBuilderUIState, setCampaignBuilderUIState] = useState<CreateCampaignState>({
    profiles: [],
    isSubmitting: false,
    shouldShowReview: false,
    currentContentToShow: ContentSteps.Create,
    adGroupMode: AdGroupMode.SIAG,
    campaignBuilderResponse: {
      body: null,
      success: false,
      response: undefined,
      errorMessage: undefined
    },
    allowAdGroupModeSelection: false,
    siagItemStepNumber: 0,
    siagTargetStepNumber: 0
  });

  const [searchParams] = useSearchParams();
  const profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;

  useEffect(() => {
    const initialize = async () => {
      const response = await getProfiles();

      if (response.success) {
        setCampaignBuilderUIState((prev) => ({ ...prev, profiles: response.body.records }));
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }
    };

    initialize();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmitComplete = (response: ResponseObject) => {
    if (!response?.response) {
      return;
    }

    if (!response?.success) {
      enqueueSnackbar(response?.errorMessage, { variant: 'error' });
      setCampaignBuilderUIState((prev) => ({
        ...prev,
        currentContentToShow: ContentSteps.Error,
        isSubmitting: false,
        campaignBuilderResponse: response
      }));
      return;
    }

    if (response?.body?.hasErrors) {
      setCampaignBuilderUIState((prev) => ({
        ...prev,
        currentContentToShow: ContentSteps.Error,
        isSubmitting: false,
        campaignBuilderResponse: response
      }));
      return;
    }

    if (isSbResponseEligibleForReview(response)) {
      setCampaignBuilderUIState((prev) => ({ ...prev, shouldShowReview: true }));
    }

    setCampaignBuilderUIState((prev) => ({
      ...prev,
      currentContentToShow: ContentSteps.Complete,
      isSubmitting: false,
      campaignBuilderResponse: response
    }));
  };

  const handleModeSelection = (mode: AdGroupMode) => {
    setCampaignBuilderUIState((prev) => ({
      ...prev,
      adGroupMode: mode,
      currentContentToShow: ContentSteps.Create
    }));

    //set formik value
    formik.setFieldValue('adGroupMode', mode);
  };

  const formik = useFormik<CampaignFormValues>({
    initialValues: CAMPAIGN_BUILDER_INITIAL_VALUES,
    validationSchema: CONDITIONAL_CAMPAIGN_SCHEMA,
    onSubmit: async (values: CampaignFormValues) => {
      setCampaignBuilderUIState((prev) => ({ ...prev, isSubmitting: true }));

      let response;

      if (campaignBuilderUIState.adGroupMode === AdGroupMode.SIAG) {
        const singleItemAdGroupRequest = createSingleItemAdGroupCampaignBuilderRequest(values);
        response = await buildSingleItemAdGroupCampaign(profileId, singleItemAdGroupRequest);
      } else {
        const multiItemAdGroupRequest = createMultiItemAdGroupCampaignBuilderRequest(values);
        response = await buildMultiItemAdGroupCampaign(profileId, multiItemAdGroupRequest);
      }

      onSubmitComplete(response);
    }
  });

  const handleBackButtonClick = () => {
    setCampaignBuilderUIState((prev) => ({
      ...prev,
      currentContentToShow: ContentSteps.Create
    }));

    if (isDevelopment) {
      setShowErrorTesting(false);
    }
  };

  useEffect(() => {
    const allowAdGroupModeSelection =
      formik.values.campaignType?.toLowerCase() === WalmartSamsClubCampaignType.SponsoredProducts.toLowerCase();
    const siagItemStepNumber = allowAdGroupModeSelection ? 3 : 2;
    const siagTargetStepNumber = allowAdGroupModeSelection ? 4 : 3;

    setCampaignBuilderUIState((prev) => ({
      ...prev,
      allowAdGroupModeSelection,
      siagItemStepNumber,
      siagTargetStepNumber
    }));
  }, [formik.values.campaignType]);

  const createCampaignContentSteps = [
    {
      key: ContentSteps.Create,
      content: (
        <form onSubmit={formik.handleSubmit}>
          {/* Step 1 */}
          <CampaignSettingsStep
            formik={formik}
            profiles={campaignBuilderUIState.profiles}
            adGroupMode={campaignBuilderUIState.adGroupMode}
          />

          {/* Step 2 if Campaign Type is Sponsored Products */}
          {campaignBuilderUIState.allowAdGroupModeSelection && <AdGroupModeSelection onModeSelect={handleModeSelection} />}

          {/* Steps 2-3 or 3-4, Depending on Ad Group Mode */}
          {campaignBuilderUIState.adGroupMode === AdGroupMode.SIAG && (
            <>
              <ItemSelectionStep formik={formik} stepNumber={campaignBuilderUIState.siagItemStepNumber} profileId={profileId} />
              <TargetingSettingsStep
                formik={formik}
                stepNumber={campaignBuilderUIState.siagTargetStepNumber}
                adGroupMode={AdGroupMode.SIAG}
              />
            </>
          )}
          {campaignBuilderUIState.adGroupMode === AdGroupMode.MIAG && (
            <>
              <TargetingSettingsStep formik={formik} stepNumber={3} adGroupMode={AdGroupMode.MIAG} />
              <AdGroupSettingsStep formik={formik} stepNumber={4} profileId={profileId} />
            </>
          )}

          <Grid container justifyContent="flex-end">
            <Grid item sx={{ my: 5 }}>
              <Button
                endIcon={<ArrowRight fontSize="small" />}
                type="submit"
                variant="contained"
                size="large"
                disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
              >
                Create Campaign
              </Button>
            </Grid>
          </Grid>
        </form>
      )
    },
    {
      key: ContentSteps.Error,
      content: (
        <ErrorReview
          genericErrorText="The following errors occurred while creating your campaign. Parts of your campaign may have succeeded. Please review your
          campaign for completeness."
          handleBackButtonClick={handleBackButtonClick}
          campaignBuilderResponse={campaignBuilderUIState.campaignBuilderResponse}
        />
      )
    },
    {
      key: ContentSteps.Complete,
      content: (
        <>
          {campaignBuilderUIState.adGroupMode === AdGroupMode.SIAG && (
            <SuccessfulCampaignCreation
              adItems={campaignBuilderUIState.campaignBuilderResponse.body?.adItems}
              keywords={campaignBuilderUIState.campaignBuilderResponse.body?.keywords}
              campaignName={campaignBuilderUIState.campaignBuilderResponse.body?.name}
              campaignBuilderResponse={campaignBuilderUIState.campaignBuilderResponse}
              handleSkipReview={() => setCampaignBuilderUIState((prev) => ({ ...prev, shouldShowReview: false }))}
              handleSuccessfulReviewSubmission={() => navigate(`/walmart/campaigns?profileId=${profileId}`)}
              shouldShowReview={campaignBuilderUIState.shouldShowReview}
            />
          )}
          {campaignBuilderUIState.adGroupMode === AdGroupMode.MIAG && (
            <MIAGSuccessfulCampaignCreation
              adGroups={campaignBuilderUIState.campaignBuilderResponse.body?.adGroups}
              campaignName={campaignBuilderUIState.campaignBuilderResponse.body?.campaign?.name}
              campaignBuilderResponse={campaignBuilderUIState.campaignBuilderResponse}
              handleSkipReview={() => setCampaignBuilderUIState((prev) => ({ ...prev, shouldShowReview: false }))}
              handleSuccessfulReviewSubmission={() => navigate(`/walmart/campaigns?profileId=${profileId}`)}
              shouldShowReview={campaignBuilderUIState.shouldShowReview}
            />
          )}
        </>
      )
    }
  ];

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
          bgcolor: 'background.default'
        }}
      >
        <Container maxWidth="md">
          <AnimatePresence mode="wait">
            {showErrorTesting && campaignBuilderUIState.currentContentToShow === ContentSteps.Error ? (
              <ErrorReviewTester handleBackButtonClick={handleBackButtonClick} />
            ) : (
              createCampaignContentSteps.find((content) => content.key === campaignBuilderUIState.currentContentToShow)?.content
            )}
            {isDevelopment && (
              <>
                <Button
                  onClick={() => {
                    setShowErrorTesting(!showErrorTesting);
                    setCampaignBuilderUIState((prev) => ({
                      ...prev,
                      currentContentToShow: !showErrorTesting ? ContentSteps.Error : ContentSteps.Create
                    }));
                  }}
                  variant="outlined"
                  sx={{ mb: 2 }}
                >
                  Toggle Error Testing (Dev Only)
                </Button>
              </>
            )}
          </AnimatePresence>
        </Container>
      </Box>

      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={campaignBuilderUIState.isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
