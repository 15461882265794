import { CircularProgress, Switch } from '@mui/material';
import { GridRowParams, useGridApiContext } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import useAdsApi from '../../../../../shared/hooks/use-wmt-map-updated-api';
import { useSnackbar } from 'notistack';
import { buildUpdateAdGroupRequest } from '../../../../types/ad-group';

export function AdGroupToggleAction(params: GridRowParams) {
  const { id, row } = params;

  const { updateAdGroup } = useAdsApi();
  const apiRef = useGridApiContext();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);

    const newStatus = event.target.checked ? 'Enabled' : 'Disabled';

    const updateAdGroupRequest = buildUpdateAdGroupRequest({
      ...row,
      status: newStatus
    });

    try {
      const response = await updateAdGroup(updateAdGroupRequest);

      if (!response.success) {
        enqueueSnackbar(response.errorMessage || 'Failed to update ad group status', { variant: 'error' });
        setIsLoading(false);
        return;
      }

      //Handle case where Vector API returns a 200 status code but the WMT API response contains a failure code
      if (response.success && response?.body?.code?.toLowerCase() === 'failure') {
        enqueueSnackbar('WMT API error: ' + response?.body?.details || 'Failed to update ad group status', { variant: 'error' });
        setIsLoading(false);
        return;
      }

      apiRef.current.updateRows([{ adGroupId: id, status: newStatus }]);
      enqueueSnackbar(`Ad Group status changed to: ${newStatus}`, {
        variant: 'success'
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred while updating ad group status';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  return (
    <Switch
      key={`${id}-active-check`}
      checked={row.status.toLowerCase() === 'enabled'}
      color="primary"
      size="small"
      onChange={handleChange}
    />
  );
}

export function renderAdGroupActions(params: GridRowParams) {
  return [<AdGroupToggleAction key={`${params.id}-toggle`} {...params} />];
}
