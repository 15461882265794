import AddIcon from '@mui/icons-material/Add';
import { Backdrop, Box, Button, Container, Stack, Typography } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import VectorLoading from '../../../shared/components/loading/vector-loading';
import Page from '../../../shared/components/page';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import ProfileCreationModal from '../../../shared/components/profile-creation-modal/profile-creation-modal';
import useDialog from '../../../shared/hooks/use-dialog';
import useVectorPerformanceGrid from '../../../shared/hooks/use-vector-performance-grid';
import { useAdsApi } from '../../../shared/hooks/use-walmart-sams-club-api';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { Platform } from '../../../shared/types/platforms';
import { getUpdateProfileRequest } from '../../../shared/types/profile';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { PROFILE_COLUMNS } from '../../components/grid-components/column-configurations/profile-columns';

const Profiles: FC = () => {
  const adsApi = useAdsApi(Platform.WALMART.value);
  const { enqueueSnackbar } = useSnackbar();

  const [profiles, setProfiles] = useState<any[]>([]);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const { toggle: toggleProfileCreationDialog, isShowing: profileCreationDialogIsShowing } = useDialog();

  const [readyToInitialize, setIsReadyToInitialize] = useState(false);

  // Ensure adsApi is loaded before initializing
  // This prevents a race condition that can happen here, since this Walmart Profiles is the default route
  useEffect(() => {
    if (adsApi !== undefined) {
      setIsReadyToInitialize(true);
    }
  }, [adsApi]);

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange
  } = useVectorPerformanceGrid(GridConfigSettings.WALMART_PROFILE);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    if (!readyToInitialize) {
      return;
    }

    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchProfilesData
    });
  }, [readyToInitialize]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProfilesData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    setIsGridLoading(true);

    const response = await adsApi.getProfiles(pageable, beginDate, endDate);

    if (response.success && response.body) {
      setProfiles(response?.body?.records);
      setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsGridLoading(false);
  };

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    const updateProfileRequest = getUpdateProfileRequest(newRow, oldRow);

    if (!updateProfileRequest) {
      return oldRow;
    }

    setIsGridLoading(true);

    const response = await adsApi.updateProfile(updateProfileRequest);

    setIsGridLoading(false);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    enqueueSnackbar('Profile updated successfully', { variant: 'success' });
    return newRow;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="Profiles">
      <Container maxWidth="xl" style={{ height: '93vh' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Box display="flex" alignItems={'center'} my={'5%'}>
            <Typography variant="h4">Profiles</Typography>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => toggleProfileCreationDialog()}>
              Add Profile
            </Button>
          </Box>
          {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
            <DataGridPremium
              rows={[]}
              disableRowGrouping
              columns={PROFILE_COLUMNS}
              loading={true}
              processRowUpdate={processRowUpdate}
              initialState={{}}
            />
          ) : (
            <VectorPerformanceGrid
              apiRef={apiRef}
              processRowUpdate={processRowUpdate}
              loading={isGridLoading}
              rows={profiles}
              columns={PROFILE_COLUMNS}
              initialState={initialSettings?.config}
              saveGridConfig={saveGridConfig}
              dateConfig={{
                dateSettings: dateSettings,
                dateSettingsLoading: dateSettingsLoading,
                handleDateRangeChange: (dateRange: DateRange<Dayjs>) =>
                  handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchProfilesData, setDateSettings)
              }}
              getRowId={(row: any) => row.profileId}
              handleFilterModelChange={(newModel: GridFilterModel) => {
                handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchProfilesData);
              }}
              handleSortModelChange={(newModel: GridSortModel) => {
                handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchProfilesData);
              }}
              handlePageChange={(page: number) => {
                handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchProfilesData);
              }}
              handlePageSizeChange={(pageSize: number) => {
                handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchProfilesData);
              }}
              rowCount={rowCount}
            />
          )}
        </Stack>
      </Container>
      {profileCreationDialogIsShowing && (
        <ProfileCreationModal
          isOpen={profileCreationDialogIsShowing}
          onClose={() => toggleProfileCreationDialog()}
          retailer={Platform.WALMART.label}
          setIsPageLoading={setIsPageLoading}
          existingPlatformProfileIds={profiles.map((profile) => profile.platformId)}
        />
      )}
      <Backdrop sx={{ color: '#fff', zIndex: zIndex.modal + 1 }} open={isPageLoading}>
        <VectorLoading />
      </Backdrop>
    </Page>
  );
};

export default Profiles;
