import { Box } from '@mui/material';
import { DataGridPremium, GridColDef, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useDatelessVectorPerformanceGrid from '../../../shared/hooks/use-dateless-vector-performance-grid';
import {
  DEFAULT_ROW_COUNT,
  Pageable,
  convertGridFilterModelToCustomFilters,
  processSortsFromGrid
} from '../../../shared/types/pageable';
import { IGridConfigSettingItem } from '../../../shared/utilities/grid-config-settings';

type AuditLogGridProps = {
  entityType: string;
  selectedAuditLogTypeConfig: {
    columns: GridColDef[];
    pinnedColumns: object;
    fetchFunction: any;
    gridConfigSetting: IGridConfigSettingItem;
  };
  localDateSettings: { beginDate: string; endDate: string };
  dateSettingsLoading: boolean;
  handleDateChange: (dateRange: DateRange<Dayjs>) => void;
};

// pass date settings from parent, since changes to selectedAuditLogTypeConfig will trigger a re-render & would consequently reset dates
export default function AuditLogGrid(props: AuditLogGridProps) {
  const {
    entityType,
    selectedAuditLogTypeConfig,
    localDateSettings,
    dateSettingsLoading,
    handleDateChange: updateLocalDateSettingsInParent
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  var [auditLogData, setAuditLogData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  // we will handle date changes locally, so use the use-dateless-vector-performance-grid hook
  const {
    initializeDatelessPerformanceGrid,
    saveGridConfig,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange
  } = useDatelessVectorPerformanceGrid(selectedAuditLogTypeConfig.gridConfigSetting);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  useEffect(() => {
    initializeDatelessPerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      fetchEntity: fetchAuditLogData
    });
  }, [selectedAuditLogTypeConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAuditLogData = useCallback(
    async (pageable: Pageable, beginDate?: any, endDate?: any) => {
      setIsLoading(true);

      try {
        const response = await selectedAuditLogTypeConfig.fetchFunction(
          pageable,
          beginDate || localDateSettings.beginDate,
          endDate || localDateSettings.endDate
        );

        if (response.success) {
          setAuditLogData(response.body.records);
          setRowCount(response.body.totalFilteredRecords);
        } else {
          enqueueSnackbar('Error fetching audit logs...', { variant: 'error' });
        }
      } catch (error: any) {
        enqueueSnackbar('Error: ' + error?.message, { variant: 'error' });
      }
      setIsLoading(false);
    },
    [localDateSettings, selectedAuditLogTypeConfig, enqueueSnackbar]
  );

  // handleDateChange locally instead of using use-vector-performance-grid hook's exported function
  const handleDateChange = (newDateRange: DateRange<any>) => {
    if (
      newDateRange[0] &&
      newDateRange[1] &&
      !isEqual(localDateSettings.beginDate, newDateRange[0]) &&
      !isEqual(localDateSettings.endDate, newDateRange[1])
    ) {
      const newDateSettings = {
        beginDate: newDateRange[0].format('YYYY/MM/DD'),
        endDate: newDateRange[1].format('YYYY/MM/DD')
      };

      const currentState = apiRef.current.exportState();

      const updatedPageable: Pageable = {
        filters: currentState?.filter?.filterModel
          ? convertGridFilterModelToCustomFilters(currentState?.filter?.filterModel)
          : [],
        sorts: currentState?.sorting?.sortModel ? processSortsFromGrid(currentState.sorting.sortModel) : [],
        limit: currentState?.pagination?.paginationModel?.pageSize || DEFAULT_ROW_COUNT,
        offset: currentState?.pagination?.paginationModel?.page || 0
      };

      // Since react effects are async and may not have updated by the time we call this function,
      // pass the updated date settings to the function from the props
      fetchAuditLogData(updatedPageable, newDateSettings.beginDate, newDateSettings.endDate);

      updateLocalDateSettingsInParent(newDateRange);
    }
  };

  return (
    <Box sx={{ height: '800px' }}>
      {!settingsLoaded || !localDateSettings ? (
        <DataGridPremium
          rows={[]}
          columns={selectedAuditLogTypeConfig.columns}
          loading={true}
          disableRowGrouping
          initialState={{}}
          key={uuidv4()}
        />
      ) : (
        <VectorPerformanceGrid
          apiRef={apiRef}
          key={entityType}
          loading={isLoading}
          rows={auditLogData}
          columns={selectedAuditLogTypeConfig.columns}
          initialState={{
            ...initialSettings?.config,
            pinnedColumns: selectedAuditLogTypeConfig.pinnedColumns
          }}
          saveGridConfig={saveGridConfig}
          dateConfig={{
            dateSettings: localDateSettings,
            dateSettingsLoading: dateSettingsLoading,
            handleDateRangeChange: handleDateChange
          }}
          rowCount={rowCount}
          handleFilterModelChange={(newModel: GridFilterModel) => {
            handleFilterModelChange(newModel, settingsLoaded, apiRef, fetchAuditLogData);
          }}
          handleSortModelChange={(newModel: GridSortModel) => {
            handleSortModelChange(newModel, settingsLoaded, apiRef, fetchAuditLogData);
          }}
          handlePageChange={(page: number) => {
            handlePageChange(page, settingsLoaded, apiRef, fetchAuditLogData);
          }}
          handlePageSizeChange={(pageSize: number) => {
            handlePageSizeChange(pageSize, settingsLoaded, apiRef, fetchAuditLogData);
          }}
          processRowUpdate={function (newRow: any, oldRow: any) {
            throw new Error('Function not implemented.');
          }}
        />
      )}
    </Box>
  );
}
