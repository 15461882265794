import type { ChangeEvent, FC } from 'react';
import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Tab, Tabs } from '@mui/material';
import { useUpdateEffect } from '../../../../hooks/use-update-effect';

interface Filters {
  status?: string;
}

type TabValue = 'AVAILABLE' | 'FAILED' | 'PENDING' | 'LIVED';

interface TabOption {
  label: string;
  value: TabValue;
}

const tabOptions: TabOption[] = [
  {
    label: 'Went Live',
    value: 'LIVED'
  },
  {
    label: 'Available',
    value: 'AVAILABLE'
  },
  {
    label: 'Pending',
    value: 'PENDING'
  },
  {
    label: 'Failed',
    value: 'FAILED'
  }
];

interface OrderListSearchProps {
  onFiltersChange?: (filters: Filters) => void;
}

export const MediaListSearch: FC<OrderListSearchProps> = (props) => {
  const { onFiltersChange } = props;
  const [currentTab, setCurrentTab] = useState<TabValue>('LIVED');
  const [filters, setFilters] = useState<Filters>({
    status: 'LIVED'
  });

  const handleFiltersUpdate = useCallback(() => {
    onFiltersChange?.(filters);
  }, [filters, onFiltersChange]);

  useUpdateEffect(() => {
    handleFiltersUpdate();
  }, [filters, handleFiltersUpdate]);

  const handleTabsChange = useCallback((event: ChangeEvent<{}>, tab: TabValue): void => {
    setCurrentTab(tab);
    const status = tab;

    setFilters((prevState) => ({
      ...prevState,
      status
    }));
  }, []);

  return (
    <div>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        sx={{ px: 2 }}
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {tabOptions.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider />
    </div>
  );
};

MediaListSearch.propTypes = {
  onFiltersChange: PropTypes.func
};
