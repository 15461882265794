import { PlayArrow as PlayArrowIcon, Pause as PauseIcon, Delete as DeleteIcon } from '@mui/icons-material';

export const adGroupStatusOptions = [
  {
    label: 'Enabled',
    value: 'enabled',
    icon: PlayArrowIcon
  },
  {
    label: 'Disabled',
    value: 'disabled',
    icon: PauseIcon
  },
  {
    label: 'Deleted',
    value: 'deleted',
    icon: DeleteIcon
  }
];
