import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import useSharedApi from '../../../shared/hooks/use-shared-api';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useVectorPerformanceGrid from '../../../shared/hooks/use-vector-performance-grid';
import { BreadCrumbParams, useAmazonApi } from '../../hooks/use-amazon-api-v2';
import { BID_GROUP_PLACEMENT_HISTORY_COLUMNS } from './bid-group-placement-history-columns';

interface BidGroupPlacementHistoryRow {
  compositeKey: string;
  id: string;
  campaignBidOptimizationGroupId: string;
  campaignName?: string;
  campaignState?: string;
  createdAt: string;
  acosTarget: number;
  updatedTargetsCount: number;
  targetId: string;
  target: string;
  entityType: string;
  oldBid: number;
  newBid: number;
  reason: string;
}

interface BidGroupPlacementHistoryGridProps {
  bidGroupAcosPlacement?: number;
  breadCrumbParams: BreadCrumbParams[];
  getChangesCountByDate: (data: any[]) => {
    [date: string]: number;
  };
  setPlacementChangesCountByDate: React.Dispatch<
    React.SetStateAction<{
      [date: string]: number;
    }>
  >;
}

export const BidGroupPlacementHistoryGrid = (props: BidGroupPlacementHistoryGridProps) => {
  const { bidGroupAcosPlacement, breadCrumbParams, getChangesCountByDate, setPlacementChangesCountByDate } = props;

  const { getBidOptimizationPlacementHistory } = useAmazonApi();

  const [placementHistoryRows, setPlacementHistoryRows] = useState<any[]>([]);
  const [placementHistoryRowCount, setPlacementHistoryRowCount] = useState(0);
  const [isLoadingPlacementHistoryRows, setIsLoadingPlacementHistoryRows] = useState(true);

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange,
    handleRefreshEntities
  } = useVectorPerformanceGrid(GridConfigSettings.BID_GROUP_PLACEMENT_HISTORY_VIEW);

  const apiRef = useGridApiRef();

  const { getUserSetting, updateUserSetting } = useSharedApi();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchPlacementHistoryData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchPlacementHistoryData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    setIsLoadingPlacementHistoryRows(true);

    const response = await getBidOptimizationPlacementHistory([...breadCrumbParams], pageable, beginDate, endDate);

    if (response.success && response.body) {
      setPlacementHistoryRows(response.body?.records);
      setPlacementHistoryRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);

      // set special data for `chartOptions` in the parent's metrics chart
      const changesCount = getChangesCountByDate(response.body.records);
      setPlacementChangesCountByDate(changesCount);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoadingPlacementHistoryRows(false);
  };

  if (!settingsLoaded || dateSettingsLoading || !dateSettings) {
    return (
      <DataGridPremium
        rows={[]}
        disableRowGrouping
        columns={BID_GROUP_PLACEMENT_HISTORY_COLUMNS}
        loading={true}
        processRowUpdate={() => {}}
        initialState={{}}
      />
    );
  }

  return (
    <VectorPerformanceGrid
      apiRef={apiRef}
      key={'placement-history-grid-key'}
      loading={isLoadingPlacementHistoryRows}
      rows={placementHistoryRows}
      columns={BID_GROUP_PLACEMENT_HISTORY_COLUMNS}
      initialState={initialSettings?.config}
      saveGridConfig={saveGridConfig}
      dateConfig={{
        dateSettings: dateSettings,
        dateSettingsLoading: dateSettingsLoading,
        handleDateRangeChange: (dateRange: DateRange<Dayjs>) =>
          handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchPlacementHistoryData, setDateSettings)
      }}
      getRowId={(row: BidGroupPlacementHistoryRow) => row.compositeKey}
      getRowClassName={(params: any) => `row-status--${params.row.status}`}
      processRowUpdate={() => {}}
      bottomMargin={30}
      disableGridDateRangePicker={false}
      allowQuickFilterSearch={true}
      rowCount={placementHistoryRowCount}
      handleFilterModelChange={(newModel: GridFilterModel) => {
        handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchPlacementHistoryData);
      }}
      handleSortModelChange={(newModel: GridSortModel) => {
        handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchPlacementHistoryData);
      }}
      handlePageChange={(page: number) => {
        handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchPlacementHistoryData);
      }}
      handlePageSizeChange={(pageSize: number) => {
        handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchPlacementHistoryData);
      }}
      otherDataGridProps={{ autoHeight: placementHistoryRows?.length < 10 }}
    />
  );
};
