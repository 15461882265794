import CheckIcon from '@mui/icons-material/Check';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {
  Avatar,
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { amber, green } from '@mui/material/colors';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { VideoProfileDialog } from '../../../../media-dialog';
import { useCurrentRetailerPlatform } from '../../../../../hooks/use-current-retailer-platform';
import { useAdsApi } from '../../../../../hooks/use-walmart-sams-club-api';
import { Platform } from '../../../../../types/platforms';
import { CreateKeywordRequest, minWalmartSamsClubKeywordBid } from '../../../../../types/walmart-sams-club/keyword';
import { ReviewStepProps, SvReviewRequest as WalmartSvReviewRequest } from '../../../../../types/walmart-sams-club/review';
import { AddKeywordsDialog } from '../../../../grid-components/bulk-actions/keywords/add-keywords-dialog';
import VideoReviewDetails from '../video-review-details';
import { ReviewStatusChip } from '../../../shared/review-status-chip';

export default function VideoUnsubmittedReviewStep(props: ReviewStepProps) {
  const {
    review,
    campaign,
    adGroupMedia,
    ads,
    keywords,
    isReady,
    profileId,
    campaignId,
    adGroupId,
    toggleDialog: toggleMediaDialog,
    dialogIsShowing: mediaDialogIsShowing,
    dynamicKey,
    refreshParent
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddKeywordsOpen, setIsAddKeywordsOpen] = useState(false);
  const [showLinkIcon, setShowLinkIcon] = useState(false);

  const retailerPlatform = useCurrentRetailerPlatform();
  const { walmartCreateSbaReview, createKeywords } = useAdsApi(retailerPlatform);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  if (retailerPlatform === Platform.AMAZON.value) {
    enqueueSnackbar('Invalid retailer platform', { variant: 'error', persist: true });
    return null;
  }

  const minKeywordBid = minWalmartSamsClubKeywordBid[retailerPlatform];
  const minAdItems = 1; // Sponsored Video requires at least 1 ad item

  const handleMediaButtonClick = () => {
    toggleMediaDialog();
  };

  const reviewStatus = review?.reviewStatus === 'Cancelled' ? 'Cancelled' : 'Unsubmitted';

  const hasMedia = !!adGroupMedia;
  const hasItems = !!ads && ads?.length >= minAdItems;
  const hasKeywords = !!keywords && keywords.length >= 1;

  const itemLabel = `${ads?.length} item${ads?.length === 1 ? '' : 's'}`;
  const keywordLabel = `${keywords?.length} item${keywords?.length === 1 ? '' : 's'}`;

  const itemErrorLabel = `${ads?.length}/${minAdItems} Items`;
  const keywordErrorLabel = '0/1 Keywords';

  const submitSvReview = async () => {
    setIsSubmitting(true);

    const request: WalmartSvReviewRequest = {
      campaignId: campaignId?.toString() || '',
      adGroupId: adGroupId?.toString() || ''
    };

    const response = await walmartCreateSbaReview(profileId, request);

    if (response.success) {
      if (response?.body[0]?.code === 'Success') {
        enqueueSnackbar('Review submitted.', { variant: 'success' });
        navigate(`/${retailerPlatform}/campaigns?profileId=${profileId}`);
        return;
      }

      const walmartErrorMessage = response?.body[0].details || 'Unable to submit review';
      enqueueSnackbar(walmartErrorMessage, { variant: 'error', persist: true });
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      navigate(`/${retailerPlatform}/campaigns?profileId=${profileId}`);
    }

    setIsSubmitting(false);
  };

  const handleAddKeywordsSave = async (keywords: any) => {
    setIsSubmitting(true);

    const keywordRequests: CreateKeywordRequest[] = keywords.map((keyword: any) => ({
      campaignId: campaignId,
      adGroupId: adGroupId,
      state: 'enabled',
      keywordText: keyword.keywordText,
      matchType: keyword.matchType,
      bid: keyword.bid
    }));

    const response = await createKeywords(profileId, keywordRequests);

    if (response.body.every((responseCode: any) => responseCode.code.toLowerCase() === 'success')) {
      setIsAddKeywordsOpen(false);

      if (refreshParent) {
        refreshParent();
      }

      enqueueSnackbar('Keywords added successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Keywords could not be added', { variant: 'error' });
    }

    setIsSubmitting(false);
  };

  const PreSubmissionStatusIcon = (props: { isReadyToSubmit: boolean }) => {
    const { isReadyToSubmit } = props;

    return (
      <Avatar
        sx={{ border: '1px solid', backgroundColor: 'transparent', borderColor: isReadyToSubmit ? green[500] : amber[600] }}
      >
        {isReadyToSubmit ? <CheckIcon sx={{ color: green[500] }} /> : <ReportProblemIcon sx={{ color: amber[600] }} />}
      </Avatar>
    );
  };

  return (
    <Box sx={{ mb: 5 }}>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Grid container direction="row" alignContent={'center'} justifyContent={'space-between'}>
            <Grid item>
              <Typography variant="h6" sx={{ display: 'inline' }}>
                Review Readiness ({campaign?.name})
              </Typography>
              <ReviewStatusChip status={reviewStatus} sx={{ marginLeft: 2 }} />
              <Typography variant="caption" component={'p'} sx={{ mt: 1 }}>
                {isReady
                  ? 'Your Sponsored Video campaign is ready for review.'
                  : 'This campaign is not ready to submit for review'}
              </Typography>
            </Grid>
            <Grid item>
              {isReady ? (
                <Button variant="contained" onClick={submitSvReview}>
                  Submit Review
                </Button>
              ) : null}
            </Grid>
          </Grid>
          <List sx={{ mt: 2, bgcolor: 'background.paper' }}>
            <ListItem secondaryAction={!hasMedia ? <Button onClick={handleMediaButtonClick}>Add Media</Button> : <></>}>
              <ListItemAvatar>
                <PreSubmissionStatusIcon isReadyToSubmit={!!adGroupMedia} />
              </ListItemAvatar>
              <ListItemText primary="Video Media" />
            </ListItem>
            <ListItem
              secondaryAction={
                hasItems ? (
                  <></>
                ) : (
                  <Tooltip title={`Navigate to 'Ad Group Builder'`} placement="left">
                    <Link
                      to={`/walmart/ad-groups/create?profileId=${profileId}&campaignId=${campaignId}`}
                      component={RouterLink}
                      onMouseEnter={() => setShowLinkIcon(true)}
                      onMouseLeave={() => setShowLinkIcon(false)}
                    >
                      <Button
                        endIcon={
                          <Collapse orientation="horizontal" in={showLinkIcon}>
                            <OpenInNewIcon fontSize="small" />
                          </Collapse>
                        }
                      >
                        Update Ads
                      </Button>
                    </Link>
                  </Tooltip>
                )
              }
            >
              <ListItemAvatar>
                <PreSubmissionStatusIcon isReadyToSubmit={ads.length >= minAdItems} />
              </ListItemAvatar>
              <ListItemText
                primary="Ad Items"
                secondary={hasItems ? itemLabel : itemErrorLabel}
                secondaryTypographyProps={!hasItems ? { color: 'error' } : {}}
              />
            </ListItem>
            <ListItem
              secondaryAction={
                hasKeywords ? (
                  <></>
                ) : (
                  <>
                    <Button onClick={() => setIsAddKeywordsOpen(true)}>Add Keywords</Button>
                  </>
                )
              }
            >
              <ListItemAvatar>
                <PreSubmissionStatusIcon isReadyToSubmit={hasKeywords} />
              </ListItemAvatar>
              <ListItemText
                primary="Keywords"
                secondary={hasKeywords ? keywordLabel : keywordErrorLabel}
                secondaryTypographyProps={!hasKeywords ? { color: 'error' } : {}}
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <br />

      <VideoReviewDetails {...props} />

      {mediaDialogIsShowing ? (
        <VideoProfileDialog
          campaignName={campaign?.name}
          campaignId={campaignId || -1}
          profileId={profileId || -1}
          adGroupId={adGroupId || -1}
          toggle={toggleMediaDialog}
          isShowing={mediaDialogIsShowing}
          dynamicKey={dynamicKey}
          refreshParent={refreshParent}
        />
      ) : null}

      {isAddKeywordsOpen ? (
        <AddKeywordsDialog
          isOpen={isAddKeywordsOpen}
          handleClose={() => setIsAddKeywordsOpen(false)}
          handleSave={handleAddKeywordsSave}
          minBid={minKeywordBid}
        />
      ) : null}

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
