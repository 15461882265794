import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, Link, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridRowModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Page from '../../../shared/components/page';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useSharedApi from '../../../shared/hooks/use-shared-api';
import useVectorPerformanceGrid from '../../../shared/hooks/use-vector-performance-grid';
import useAdsApi, { BreadCrumbParams } from '../../../shared/hooks/use-wmt-map-updated-api';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { Platform } from '../../../shared/types/platforms';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { BidConfirmationDialog } from '../../../walmart/components/grid-components/bid-confirmation-dialog';
import { buildUpdateAdRequest } from '../../../walmart/types/ad';
import { AD_COLUMNS } from '../../components/grid-components/column-configurations/ad-columns';
import AdBulkActions from './bulk-actions/ad-bulk-actions';

const Ads: FC = () => {
  const { getAds, updateAd } = useAdsApi(Platform.SAMS_CLUB.value);
  const { enqueueSnackbar } = useSnackbar();

  const [ads, setAds] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [bidChangeData, setBidChangeData] = useState<any>(null);

  let [searchParams] = useSearchParams();
  let profileId = useMemo(() => (searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null), [searchParams]);
  let campaignId = useMemo(
    () => (searchParams.get('campaignId') ? Number(searchParams.get('campaignId')) : null),
    [searchParams]
  );
  let adGroupId = useMemo(() => (searchParams.get('adGroupId') ? Number(searchParams.get('adGroupId')) : null), [searchParams]);

  const breadcrumbParams: BreadCrumbParams[] = useMemo(
    () => [{ profileId }, { campaignId }, { adGroupId }],
    [profileId, campaignId, adGroupId]
  );

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange
  } = useVectorPerformanceGrid(GridConfigSettings.SAMS_CLUB_AD);

  const apiRef = useGridApiRef();

  const { getUserSetting, updateUserSetting } = useSharedApi();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchAdsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAdsData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    setIsLoading(true);

    const response = await getAds([...breadcrumbParams], pageable, beginDate, endDate);

    if (response.success) {
      setAds(response.body?.records);
      setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
    setIsLoading(false);
  };

  const processRowUpdate = useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
    if (newRow.bid === oldRow.bid && newRow.state === oldRow.state) {
      return oldRow;
    }

    // Setting the bidChangeData will open the confirmation dialog if the bid is greater than suggested max bid
    if (Number(newRow.bid) > 10) {
      return new Promise<GridRowModel>((resolve, reject) => {
        setBidChangeData({ resolve, reject, newRow, oldRow });
      });
    }

    setIsLoading(true);

    try {
      const response = await updateAd(newRow.profileId, buildUpdateAdRequest(newRow));

      setIsLoading(false);

      if (response.success && response.body?.isSuccess) {
        enqueueSnackbar('Ad updated successfully', { variant: 'success' });
        return newRow;
      } else {
        const errorMessage =
          response.body?.errors?.length > 0 ? response.body.errors[0] : 'Failed to update ad. Please try again.';

        enqueueSnackbar(errorMessage, { variant: 'error' });
        return oldRow;
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error updating ad:', error);
      enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
      return oldRow;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const discardChanges = () => {
    const { oldRow, resolve } = bidChangeData;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setBidChangeData(null);
  };

  const confirmBidChange = async () => {
    const { newRow, oldRow, reject, resolve } = bidChangeData;

    setIsLoading(true);

    try {
      const response = await updateAd(newRow.profileId, buildUpdateAdRequest(newRow));

      setIsLoading(false);

      if (response.success && response.body?.isSuccess) {
        resolve(newRow);
        enqueueSnackbar('Ad updated successfully', { variant: 'success' });
      } else {
        const errorMessage =
          response.body?.errors?.length > 0 ? response.body.errors[0] : 'Failed to update ad. Please try again.';

        enqueueSnackbar(errorMessage, { variant: 'error' });
        reject(oldRow);
      }
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
      reject(oldRow);
    }

    setBidChangeData(null);
  };

  const CreateAdsButton = () => {
    return (
      <Link
        to={`/${Platform.SAMS_CLUB.value}/ads/create${profileId ? `?profileId=${profileId}` : ''}${
          campaignId && profileId ? `&campaignId=${campaignId}` : ''
        }`}
        component={RouterLink}
        style={{ textDecoration: 'none' }}
      >
        <Button startIcon={<AddIcon fontSize="small" />} variant="contained">
          Create Ads
        </Button>
      </Link>
    );
  };

  return (
    <>
      <Page title="Ads">
        <Container maxWidth="xl" style={{ height: '93vh' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Box display="flex" alignItems={'center'}>
              <Typography variant="h4">Ads</Typography>
              <Box
                sx={{
                  mr: 'auto'
                }}
              ></Box>
              <CreateAdsButton />
            </Box>
            {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
              <DataGridPremium
                rows={[]}
                columns={AD_COLUMNS}
                loading={true}
                processRowUpdate={processRowUpdate}
                initialState={{}}
                key={uuidv4()}
                disableRowGrouping
              />
            ) : (
              <VectorPerformanceGrid
                apiRef={apiRef}
                processRowUpdate={processRowUpdate}
                loading={isLoading}
                rows={ads}
                columns={AD_COLUMNS}
                initialState={initialSettings?.config}
                saveGridConfig={saveGridConfig}
                dateConfig={{
                  dateSettings: dateSettings,
                  dateSettingsLoading: dateSettingsLoading,
                  handleDateRangeChange: (dateRange: DateRange<Dayjs>) =>
                    handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchAdsData, setDateSettings)
                }}
                getRowId={(row: any) => row.adItemId}
                getRowClassName={(params) => `row-status--${params.row.status}`}
                bulkActions={<AdBulkActions setIsLoading={setIsLoading} />}
                handleFilterModelChange={(newModel: GridFilterModel) => {
                  handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchAdsData);
                }}
                handleSortModelChange={(newModel: GridSortModel) => {
                  handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchAdsData);
                }}
                handlePageChange={(page: number) => {
                  handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchAdsData);
                }}
                handlePageSizeChange={(pageSize: number) => {
                  handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchAdsData);
                }}
                rowCount={rowCount}
              />
            )}
          </Stack>
        </Container>
      </Page>

      {bidChangeData && (
        <BidConfirmationDialog
          bidChangeData={bidChangeData}
          confirmBidChange={confirmBidChange}
          cancelBidChange={discardChanges}
        />
      )}
    </>
  );
};

export default Ads;
