import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { formatAsCurrency } from '../../../shared/utilities/numeric-format-utilities';

interface BidConfirmationDialogProps {
  bidChangeData: any;
  confirmBidChange: () => void;
  cancelBidChange: () => void;
}

export function BidConfirmationDialog({ bidChangeData, confirmBidChange, cancelBidChange }: BidConfirmationDialogProps) {
  if (!bidChangeData) {
    return null;
  }

  const { newRow, oldRow } = bidChangeData;

  return (
    <Dialog maxWidth="lg" open={!!bidChangeData} onClose={cancelBidChange} aria-labelledby="bid-confirmation-dialog-title">
      <DialogTitle id="bid-confirmation-dialog-title">Confirm Bid Change</DialogTitle>
      <DialogContent dividers>
        <p>
          You're about to change your bid from <strong>{formatAsCurrency(oldRow.bid)}</strong> to{' '}
          <strong>{formatAsCurrency(newRow.bid)}</strong>.
        </p>
        {newRow.bid > oldRow.bid && <p>Increasing your bid may improve keyword performance but will increase costs.</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelBidChange} color="inherit">
          Cancel
        </Button>
        <Button onClick={confirmBidChange} color="primary" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
