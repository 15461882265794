import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  List,
  ListItemButton,
  ListItemText,
  IconButton,
  Tooltip,
  Chip,
  TextField,
  Collapse
} from '@mui/material';
import { SearchOutlined, ClearOutlined } from '@mui/icons-material';
import {
  CategorySectionProps,
  ClientProfile,
  NotificationPreferencesDialogProps,
  NotificationReason,
  NotificationTypeDetails
} from './notifications-types-handlers';

export const NotificationPreferencesDialog: React.FC<NotificationPreferencesDialogProps> = ({
  open,
  onClose,
  onSave,
  amazonProfiles,
  walmartProfiles,
  samsProfiles,
  initialSubscribedByType
}) => {
  const [preferencesByType, setPreferencesByType] = useState<{ [key in NotificationReason]?: number[] }>(initialSubscribedByType);
  const configurableNotificationTypes = NotificationTypeDetails;
  const [selectedNotificationReason, setSelectedNotificationReason] = useState<NotificationReason>(
    configurableNotificationTypes[0].reason
  );

  useEffect(() => {
    setPreferencesByType(initialSubscribedByType);
  }, [initialSubscribedByType]);

  const getSubscribedProfileIds = (notificationReason: NotificationReason): number[] =>
    preferencesByType[notificationReason] || [];
  const setSubscribedProfileIds = (notificationReason: NotificationReason, updatedProfileIds: number[]) => {
    setPreferencesByType((previousPreferences) => ({
      ...previousPreferences,
      [notificationReason]: updatedProfileIds
    }));
  };

  const handleToggleProfile = (notificationReason: NotificationReason, profileId: number) => {
    const currentSubscriptions = getSubscribedProfileIds(notificationReason);
    if (currentSubscriptions.includes(profileId)) {
      setSubscribedProfileIds(
        notificationReason,
        currentSubscriptions.filter((identifier) => identifier !== profileId)
      );
    } else {
      setSubscribedProfileIds(notificationReason, [...currentSubscriptions, profileId]);
    }
  };

  const handleToggleAll = (notificationReason: NotificationReason, profiles: ClientProfile[]) => {
    const currentSubscriptions = getSubscribedProfileIds(notificationReason);
    const allSelected = profiles.every((profile) => currentSubscriptions.includes(profile.childClientId));
    if (allSelected) {
      const profileIdsToRemove = profiles.map((profile) => profile.childClientId);
      setSubscribedProfileIds(
        notificationReason,
        currentSubscriptions.filter((identifier) => !profileIdsToRemove.includes(identifier))
      );
    } else {
      const combinedIds = new Set([...currentSubscriptions, ...profiles.map((profile) => profile.childClientId)]);
      setSubscribedProfileIds(notificationReason, Array.from(combinedIds));
    }
  };

  const areAllSelected = (notificationReason: NotificationReason, profiles: ClientProfile[]): boolean => {
    const currentSubscriptions = getSubscribedProfileIds(notificationReason);
    return profiles.length > 0 && profiles.every((profile) => currentSubscriptions.includes(profile.childClientId));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Notification Preferences</DialogTitle>
      <DialogContent dividers sx={{ height: '100vh', overflowY: 'auto' }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Box sx={{ borderRight: '1px solid', borderColor: 'divider', flexGrow: 0, flexShrink: 0, pr: 2 }}>
            <List>
              {configurableNotificationTypes.map((notificationInfo) => (
                <Tooltip key={notificationInfo.reason} title={notificationInfo.description} placement="top">
                  <ListItemButton
                    selected={selectedNotificationReason === notificationInfo.reason}
                    onClick={() => setSelectedNotificationReason(notificationInfo.reason)}
                    sx={{ width: 200, textOverflow: 'wrap', whiteSpace: 'normal' }}
                  >
                    <ListItemText primary={notificationInfo.label} />
                  </ListItemButton>
                </Tooltip>
              ))}
            </List>
          </Box>
          <Box sx={{ flexGrow: 1, pl: 2 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              For {configurableNotificationTypes.find((info) => info.reason === selectedNotificationReason)?.label}, select which
              profiles you’d like to receive notifications for:
            </Typography>
            <CategorySection
              title="Amazon"
              profiles={amazonProfiles}
              subscribedProfileIds={getSubscribedProfileIds(selectedNotificationReason)}
              areAllSelected={(profilesArray) => areAllSelected(selectedNotificationReason, profilesArray)}
              handleToggleAll={(profilesArray) => handleToggleAll(selectedNotificationReason, profilesArray)}
              handleToggleProfile={(profileId) => handleToggleProfile(selectedNotificationReason, profileId)}
            />
            <CategorySection
              title="Walmart"
              profiles={walmartProfiles}
              subscribedProfileIds={getSubscribedProfileIds(selectedNotificationReason)}
              areAllSelected={(profilesArray) => areAllSelected(selectedNotificationReason, profilesArray)}
              handleToggleAll={(profilesArray) => handleToggleAll(selectedNotificationReason, profilesArray)}
              handleToggleProfile={(profileId) => handleToggleProfile(selectedNotificationReason, profileId)}
            />
            <CategorySection
              title="Sam's Club"
              profiles={samsProfiles}
              subscribedProfileIds={getSubscribedProfileIds(selectedNotificationReason)}
              areAllSelected={(profilesArray) => areAllSelected(selectedNotificationReason, profilesArray)}
              handleToggleAll={(profilesArray) => handleToggleAll(selectedNotificationReason, profilesArray)}
              handleToggleProfile={(profileId) => handleToggleProfile(selectedNotificationReason, profileId)}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSave(preferencesByType)} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CategorySection: React.FC<CategorySectionProps> = ({
  title,
  profiles,
  subscribedProfileIds,
  areAllSelected,
  handleToggleAll,
  handleToggleProfile
}) => {
  const allProfilesSelected = areAllSelected(profiles);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const searchInputReference = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isSearchExpanded) {
      searchInputReference.current?.focus();
    }
  }, [isSearchExpanded]);

  const filteredProfiles = profiles.filter(
    (profile) =>
      profile.clientName.toLowerCase().includes(searchTerm.toLowerCase()) || profile.childClientId.toString().includes(searchTerm)
  );

  const handleSearchIconClick = () => {
    setIsSearchExpanded(true);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 1,
          marginTop: 4,
          height: '8vh'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h6">{title}</Typography>
          <Collapse in={!isSearchExpanded} orientation="horizontal">
            <IconButton size="small" onClick={handleSearchIconClick}>
              <SearchOutlined />
            </IconButton>
          </Collapse>
          <Collapse in={isSearchExpanded} orientation="horizontal">
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search profiles or IDs..."
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              inputRef={searchInputReference}
              autoFocus
              onBlur={() => setIsSearchExpanded(false)}
              sx={{ ml: 1 }}
              InputProps={{
                endAdornment: searchTerm && (
                  <IconButton
                    onMouseDown={(event) => event.preventDefault()}
                    onClick={(event) => {
                      event.stopPropagation();
                      setSearchTerm('');
                      setTimeout(() => {
                        searchInputReference.current?.focus();
                      }, 0);
                    }}
                    edge="end"
                    size="small"
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                )
              }}
            />
          </Collapse>
        </Box>
        <Button onClick={() => handleToggleAll(profiles)} variant="text" size="small" color="inherit" sx={{ opacity: 0.8 }}>
          {allProfilesSelected ? 'Deselect All' : 'Select All'}
        </Button>
      </Box>
      <Box sx={{ position: 'relative', maxHeight: 150, overflow: 'hidden' }}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            maxHeight: 150,
            overflowY: filteredProfiles.length > 10 ? 'scroll !important' : 'auto',
            padding: 1
          }}
        >
          {filteredProfiles.map((profile) => {
            const isProfileSubscribed = subscribedProfileIds.includes(profile.childClientId);
            return (
              <Tooltip
                key={profile.childClientId}
                title={
                  <>
                    Child Client ID: {profile.childClientId}. <br /> Click to {isProfileSubscribed ? 'Unsubscribe' : 'Subscribe'}.
                  </>
                }
                placement="top"
                arrow
              >
                <Chip
                  label={profile.clientName}
                  variant={isProfileSubscribed ? 'filled' : 'outlined'}
                  color={isProfileSubscribed ? 'primary' : 'default'}
                  onClick={() => handleToggleProfile(profile.childClientId)}
                  sx={{ borderRadius: 8, cursor: 'pointer' }}
                />
              </Tooltip>
            );
          })}
        </Box>
        {filteredProfiles.length > 10 && (
          <Box
            sx={(theme) => ({
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: 30,
              pointerEvents: 'none',
              background: `linear-gradient(to bottom, rgba(255,255,255,0), ${theme.palette.divider})`
            })}
          />
        )}
      </Box>
    </>
  );
};
