import { SvgIconTypeMap } from '@mui/material';
import Chip from '@mui/material/Chip';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import * as React from 'react';

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: 'left',
  '& .icon': {
    color: 'inherit'
  },
  '&.enabled': {
    color: theme.palette.success.dark,
    border: `1px solid ${theme.palette.success.main}`
  },
  '&.disabled': {
    color: theme.palette.action.disabled,
    border: `1px solid ${theme.palette.action.disabled}`
  },
  '&.deleted': {
    color: theme.palette.error.dark,
    border: `1px solid ${theme.palette.error.main}`
  }
}));

type AdGroupStatusOption = {
  label: string;
  value: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
};

interface StatusProps {
  status: string;
  adGroupStatusOptions: AdGroupStatusOption[];
}

export const Status = React.memo((props: StatusProps) => {
  const { status, adGroupStatusOptions } = props;
  const statusObject = adGroupStatusOptions.find((option) => option.value === status.toLowerCase());

  if (!statusObject) {
    return null;
  }

  const Icon = statusObject.icon;

  return (
    <StyledChip
      className={status.toLowerCase()}
      icon={<Icon className="icon" />}
      size="small"
      label={status}
      variant="outlined"
    />
  );
});

export const renderAdGroupStatus = (adGroupStatusOptions: AdGroupStatusOption[]) => (params: GridRenderCellParams) => {
  if (params.value == null) {
    return '';
  }
  return <Status adGroupStatusOptions={adGroupStatusOptions} status={params.value} />;
};
