import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAdsApi } from '../../../../shared/hooks/use-walmart-sams-club-api';
import { CampaignBuilderAdGroup, CampaignBuilderItem, CampaignBuilderKeyword } from '../../../types/campaign-builder-request';
import { SbReviewRequest as WalmartSbaReviewRequest } from '../../../../shared/types/walmart-sams-club/review';
import { ResponseObject } from '../../../../shared/utilities/fetch-utilities';

interface MIAGSuccessfulCampaignCreationProps {
  campaignBuilderResponse: ResponseObject;
  adGroups: {
    name: string;
    adItems: CampaignBuilderItem[]; //Callout - everywhere else, we use the term "item" instead of "adItem". Only on the response object (once it's created) do we use "adItem"
    keywords: CampaignBuilderKeyword[];
  }[];
  campaignName: string;
  handleSkipReview: () => void;
  handleSuccessfulReviewSubmission: () => void;
  shouldShowReview: boolean;
}

export const MIAGSuccessfulCampaignCreation = (props: MIAGSuccessfulCampaignCreationProps) => {
  const {
    adGroups,
    campaignName,
    shouldShowReview,
    campaignBuilderResponse,
    handleSkipReview,
    handleSuccessfulReviewSubmission
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [expandedGroup, setExpandedGroup] = useState<string | false>(false);

  const { walmartCreateSbaReview } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;

  // Calculate totals across all ad groups
  const totalItems = adGroups.reduce((sum, group) => sum + group.adItems.length, 0);
  const totalKeywords = adGroups.reduce((sum, group) => sum + group.keywords.length, 0);

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedGroup(isExpanded ? panel : false);
  };

  const submitSbaReview = async () => {
    setIsSubmitting(true);

    const request: WalmartSbaReviewRequest = {
      campaignId: campaignBuilderResponse?.body?.campaign?.campaignId,
      adGroupId: campaignBuilderResponse?.body?.adGroups[0]?.adGroupId
    };

    const response = await walmartCreateSbaReview(profileId, request);

    if (response.success) {
      handleSuccessfulReviewSubmission();
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      navigate(`/campaigns?profileId=${profileId}`);
    }

    setIsSubmitting(false);
  };

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Avatar
          sx={{
            backgroundColor: 'success.main',
            color: 'success.contrastText',
            height: 40,
            width: 40
          }}
        >
          <CheckIcon />
        </Avatar>
        <Typography variant="h6" sx={{ ml: 2 }}>
          Your campaign was successfully created!
        </Typography>
      </Box>
      <Card variant="outlined">
        <CardContent>
          <Grid container direction="column" justifyContent="space-between" spacing={5}>
            <Grid item>
              <Box sx={{ width: '100%' }}>
                <Typography variant="h6">{campaignName}</Typography>
                <Typography color="textSecondary" variant="caption">
                  {adGroups.length} ad groups • {totalKeywords} total keywords •{' '}
                  <Typography color="inherit" noWrap variant="caption" component="span">
                    {totalItems} total items
                  </Typography>
                </Typography>
              </Box>

              {/* Ad Groups Summary */}
              <Box sx={{ mt: 2 }}>
                {adGroups.map((group, index) => (
                  <Accordion
                    key={index}
                    expanded={expandedGroup === `group-${index}`}
                    onChange={handleAccordionChange(`group-${index}`)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>{group.name || `Ad Group ${index + 1}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2" color="textSecondary">
                        {group.keywords.length} keywords • {group.adItems.length} items
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2">Items:</Typography>
                        {group.adItems.map((item, idx) => (
                          <Typography key={idx} variant="body2">
                            • {item.itemName} ({item.itemId})
                          </Typography>
                        ))}
                      </Box>
                      {group.keywords.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                          <Typography variant="subtitle2">Keywords:</Typography>
                          {group.keywords.map((keyword, idx) => (
                            <Typography key={idx} variant="body2">
                              • {keyword.keywordText} ({keyword.matchType}) - ${keyword.bid}
                            </Typography>
                          ))}
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            </Grid>

            <Grid item>
              <Collapse in={shouldShowReview}>
                <Divider />
                <Box sx={{ my: 4 }}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="h6">SB Review</Typography>
                      <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                        Your SB campaign is ready for review.
                      </Typography>
                      <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                        Would you like to submit a review now? (Optional)
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        sx={{ mr: 1 }}
                        onClick={() =>
                          navigate(
                            `/review/?profileId=${profileId}&campaignId=${campaignBuilderResponse?.body?.campaign?.campaignId}`
                          )
                        }
                      >
                        Go to Review Page
                      </Button>
                      <Button sx={{ mr: 1 }} onClick={handleSkipReview}>
                        Skip for now
                      </Button>
                      <Button variant="outlined" onClick={submitSbaReview}>
                        Submit Review
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
              </Collapse>
            </Grid>

            <Grid item>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h6">Budget Settings</Typography>
                  <Typography variant="caption" component="p" sx={{ mt: 1 }}>
                    Your campaign is ready for a budget.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button sx={{ mr: 1 }} onClick={() => navigate(`/walmart/campaigns?profileId=${profileId}`)}>
                    Go to Campaigns
                  </Button>
                  <Button variant="contained" onClick={() => navigate(`/walmart/budgets?profileId=${profileId}`)}>
                    Go to Budget Manager
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
