import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Stack } from '@mui/material';
import { DataGridPremium, GridAggregationModel, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { avgNoLabel, sumNoLabel } from '../../../../shared/components/grid-components/aggregationFunctions';
import MetricsChart from '../../../../shared/components/metrics-chart';
import Page from '../../../../shared/components/page';
import VectorPerformanceGrid from '../../../../shared/components/performance-grid/vector-performance-grid';
import useDialog from '../../../../shared/hooks/use-dialog';
import useVectorPerformanceGrid from '../../../../shared/hooks/use-vector-performance-grid';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../../shared/types/pageable';
import { GridConfigSettings } from '../../../../shared/utilities/grid-config-settings';
import { AD_GROUP_COLUMNS } from '../../../components/grid-components/column-configurations/ad-group-columns';
import { BreadCrumbParams, useAmazonApi } from '../../../hooks/use-amazon-api-v2';
import { UpdateAdGroupRequest } from '../../../types/ad-group';
import { AmazonCampaignType } from '../../../types/campaign';
import AddAdGroupsDialog from './create-dialogs/create-ad-groups-dialog';

interface OverviewAdGroupProps {
  campaignId: string | null;
  campaignType?: string;
  targetingType?: string;
  refreshCampaignOverview: () => Promise<void>;
}

interface AdGroupRow {
  adGroupId: string;
  name: string;
  state: string;
  defaultBid: number;
  syncState: string;
}

export const OverviewAdGroups = (props: OverviewAdGroupProps) => {
  const { campaignId, campaignType, targetingType, refreshCampaignOverview } = props;

  const { getAdGroups, buildAdGroup, updateAdGroup } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [adGroups, setAdGroups] = useState<AdGroupRow[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);

  const { isShowing: addAdGroupsDialogIsShowing, toggle: toggleAddAdGroupsDialog } = useDialog();

  const breadcrumbParams: BreadCrumbParams[] = useMemo(() => [{ campaignId }], [campaignId]);

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange
  } = useVectorPerformanceGrid(GridConfigSettings.AMAZON_OVERVIEW_ADGROUPS);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchAdGroupsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAdGroupsData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    setIsLoading(true);

    const response = await getAdGroups([...breadcrumbParams], pageable, beginDate, endDate, true);

    if (response.success) {
      setAdGroups(response.body.dataGrid.records);
      setRowCount(response.body?.dataGrid.totalFilteredRecords || DEFAULT_ROW_COUNT);
      setTimeSeriesData(response.body?.timeSeriesData);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const processRowUpdate = useCallback(async (newRow: AdGroupRow, oldRow: AdGroupRow) => {
    const updatedAdGroup: UpdateAdGroupRequest = {
      adGroupId: newRow.adGroupId,
      name: newRow.name,
      state: newRow.state,
      defaultBid: newRow.defaultBid < 0 ? 0 : newRow.defaultBid
    };

    const response = await updateAdGroup(updatedAdGroup);

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    } else {
      enqueueSnackbar('Ad Group updated successfully', { variant: 'success' });
    }

    return response.body[0];
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // When the page loads, put the scroll at the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderCreateAdGroupsButton = () => {
    if (!campaignType || campaignType !== AmazonCampaignType.SPONSORED_PRODUCTS) {
      return;
    }

    if (campaignType === AmazonCampaignType.SPONSORED_PRODUCTS) {
      return (
        <Button startIcon={<AddIcon fontSize="small" />} onClick={() => toggleAddAdGroupsDialog()} sx={{ mb: '0.5rem' }}>
          Create Ad Group
        </Button>
      );
    }
  };

  return (
    <Page>
      {/* Since this is a nested component whose parent has `Container`, use `Box` so we don't double up on margins. */}
      <Box style={{ height: '93vh', marginTop: '10px' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Box display="flex" alignItems={'center'}>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
            {renderCreateAdGroupsButton()}
          </Box>
          {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
            <DataGridPremium
              rows={[]}
              columns={AD_GROUP_COLUMNS}
              loading={true}
              processRowUpdate={processRowUpdate}
              initialState={{}}
              key={uuidv4()}
              disableRowGrouping
            />
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: '1%' }}>
                <MetricsChart
                  timeSeriesData={timeSeriesData}
                  isMetricsChartLoading={isLoading}
                  chartSettingsKey="AmazonOverviewAdGroupsChartSetting"
                />
              </Box>
              <VectorPerformanceGrid
                apiRef={apiRef}
                processRowUpdate={processRowUpdate}
                loading={isLoading}
                rows={adGroups}
                columns={AD_GROUP_COLUMNS}
                initialState={initialSettings?.config}
                saveGridConfig={saveGridConfig}
                dateConfig={{
                  dateSettings: dateSettings,
                  dateSettingsLoading: dateSettingsLoading,
                  handleDateRangeChange: (dateRange: DateRange<Dayjs>) =>
                    handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchAdGroupsData, setDateSettings)
                }}
                getRowId={(row: any) => row.adGroupId}
                handleFilterModelChange={(newModel: GridFilterModel) => {
                  handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchAdGroupsData);
                }}
                handleSortModelChange={(newModel: GridSortModel) => {
                  handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchAdGroupsData);
                }}
                handlePageChange={(page: number) => {
                  handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchAdGroupsData);
                }}
                handlePageSizeChange={(pageSize: number) => {
                  handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchAdGroupsData);
                }}
                rowCount={rowCount}
                bottomMargin={25}
                otherDataGridProps={{ autoHeight: adGroups?.length < 10 }}
                // TODO: this aggregation is temporary until Metrics Tiles are implemented (see VEC-365)
                customProps={{
                  customAggregationFunctions: {
                    sumNoLabel: sumNoLabel,
                    avgNoLabel: avgNoLabel
                  },
                  customAggregationModel: {
                    cost: 'sumNoLabel',
                    attributedRevenue: 'sumNoLabel',
                    returnOnAdSpend: 'avgNoLabel',
                    impressions: 'sumNoLabel',
                    clicks: 'sumNoLabel',
                    clickThroughRate: 'avgNoLabel',
                    units: 'sumNoLabel',
                    orders: 'sumNoLabel',
                    newToBrandOrders: 'sumNoLabel',
                    newToBrandRevenue: 'sumNoLabel',
                    newToBrandUnits: 'sumNoLabel',
                    conversionRate: 'avgNoLabel',
                    costPerClick: 'avgNoLabel',
                    costPerConversion: 'avgNoLabel'
                  } as GridAggregationModel
                }}
              />
            </>
          )}

          {addAdGroupsDialogIsShowing && (
            <AddAdGroupsDialog
              campaignId={campaignId}
              isShowing={addAdGroupsDialogIsShowing}
              toggle={toggleAddAdGroupsDialog}
              refreshAdGroups={refreshCampaignOverview}
              campaignType={campaignType || AmazonCampaignType.SPONSORED_PRODUCTS}
              targetingType={targetingType || 'MANUAL'}
            />
          )}
        </Stack>
      </Box>
    </Page>
  );
};
