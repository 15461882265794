import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, Link, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridRowModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import MetricsChart from '../../../shared/components/metrics-chart';
import Page from '../../../shared/components/page';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useVectorPerformanceGrid from '../../../shared/hooks/use-vector-performance-grid';
import useAdGroupsApi, { BreadCrumbParams } from '../../../shared/hooks/use-wmt-map-updated-api';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { AD_GROUP_COLUMNS } from '../../components/grid-components/column-configurations/ad-group-columns';
import AdGroupBulkActions from './bulk-actions/ad-group-bulk-actions';

const AdGroups: FC = () => {
  const { getAdGroups } = useAdGroupsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [adGroups, setAdGroups] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [promiseArguments, setPromiseArguments] = useState<any>(null);

  let [searchParams] = useSearchParams();
  let profileId = useMemo(() => (searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null), [searchParams]);
  let campaignId = useMemo(
    () => (searchParams.get('campaignId') ? Number(searchParams.get('campaignId')) : null),
    [searchParams]
  );

  const [rowCount, setRowCount] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);

  const breadcrumbParams: BreadCrumbParams[] = useMemo(() => [{ profileId }, { campaignId }], [profileId, campaignId]);

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange
  } = useVectorPerformanceGrid(GridConfigSettings.WALMART_AD_GROUP);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchAdGroupsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAdGroupsData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    if (!breadcrumbParams) {
      return;
    }

    setIsLoading(true);

    const response = await getAdGroups([...breadcrumbParams], pageable, beginDate, endDate, true);

    if (response.success) {
      setAdGroups(response.body.dataGrid.records);
      setRowCount(response.body?.dataGrid.totalFilteredRecords || DEFAULT_ROW_COUNT);

      if (response.body?.timeSeriesData) {
        setTimeSeriesData(response.body?.timeSeriesData);
      }
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
    setIsLoading(false);
  };

  const processRowUpdate = useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  const CreateAdGroupsButton = () => {
    return (
      <Link
        to={`/walmart/ad-groups/create${profileId ? `?profileId=${profileId}` : ''}${
          campaignId && profileId ? `&campaignId=${campaignId}` : ''
        }`}
        component={RouterLink}
        style={{ textDecoration: 'none' }}
      >
        <Button startIcon={<AddIcon fontSize="small" />} variant="contained">
          Create Ad Groups
        </Button>
      </Link>
    );
  };

  return (
    <>
      <Page title="Ad Groups">
        <Container maxWidth="xl" style={{ height: '93vh', marginTop: '10px' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Box display="flex" alignItems={'center'}>
              <Typography variant="h4">Ad Groups</Typography>
              <Box
                sx={{
                  mr: 'auto'
                }}
              ></Box>
              <CreateAdGroupsButton />
            </Box>
            {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
              <DataGridPremium
                disableRowGrouping
                rows={[]}
                columns={AD_GROUP_COLUMNS}
                loading={true}
                processRowUpdate={processRowUpdate}
                initialState={{}}
                key={uuidv4()}
              />
            ) : (
              <>
                {!!timeSeriesData && (
                  <Box display="flex" alignItems={'center'} my={'1%'}>
                    <MetricsChart
                      timeSeriesData={timeSeriesData}
                      isMetricsChartLoading={isLoading}
                      chartSettingsKey="WalmartAdGroupsChartSetting"
                    />
                  </Box>
                )}
                <VectorPerformanceGrid
                  apiRef={apiRef}
                  processRowUpdate={processRowUpdate}
                  loading={isLoading}
                  rows={adGroups}
                  columns={AD_GROUP_COLUMNS}
                  initialState={initialSettings?.config}
                  saveGridConfig={saveGridConfig}
                  dateConfig={{
                    dateSettings: dateSettings,
                    dateSettingsLoading: dateSettingsLoading,
                    handleDateRangeChange: (dateRange: DateRange<Dayjs>) =>
                      handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchAdGroupsData, setDateSettings)
                  }}
                  getRowId={(row: any) => row.adGroupId}
                  getRowClassName={(params) => `row-status--${params.row.status}`}
                  bulkActions={<AdGroupBulkActions setIsLoading={setIsLoading} />}
                  handleFilterModelChange={(newModel: GridFilterModel) => {
                    handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchAdGroupsData);
                  }}
                  handleSortModelChange={(newModel: GridSortModel) => {
                    handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchAdGroupsData);
                  }}
                  handlePageChange={(page: number) => {
                    handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchAdGroupsData);
                  }}
                  handlePageSizeChange={(pageSize: number) => {
                    handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchAdGroupsData);
                  }}
                  rowCount={rowCount}
                />
              </>
            )}
          </Stack>
        </Container>
      </Page>
    </>
  );
};

export default AdGroups;
