import { FC, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
  Collapse,
  Skeleton
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import {
  ProfileAutocomplete,
  CampaignNameTextField,
  DateRangeSettings,
  BudgetSettings,
  CampaignTypeSettings,
  SBProfileBuilder
} from './campaign-settings-inputs';
import { CampaignBuilderStepLabelButton } from './step-label-button';
import BiddingStrategySetting from './bidding-strategy-setting';
import { CampaignType } from '../../../../shared/types/walmart-sams-club/campaign';
import { AdGroupMode } from './campaign-builder-form-config';

interface CampaignTypeStepProps {
  onNext?: () => void;
  onBack?: () => void;
  formik: any;
  profiles: any[];
  adGroupMode?: AdGroupMode;
}

export const CampaignSettingsStep: FC<CampaignTypeStepProps> = (props) => {
  const { onBack, onNext, formik, profiles, adGroupMode = AdGroupMode.SIAG, ...other } = props;

  let [searchParams, setSearchParams] = useSearchParams();
  let profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;

  const [showStep, setShowStep] = useState<boolean>(true);

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  const handleProfileChange = (event: any, value: any): void => {
    if (!value?.profileId) {
      searchParams.delete('profileId');
      setSearchParams(searchParams);
    } else {
      setSearchParams({
        ...searchParams,
        profileId: `${value.profileId}`
      });
    }
  };

  return (
    <>
      <Box sx={{ mb: showStep ? 10 : 1 }} {...other}>
        <CampaignBuilderStepLabelButton
          showStep={showStep}
          handleStepButtonClick={handleStepButtonClick}
          stepNumber={1}
          stepLabel="Campaign Settings"
        />
        <Collapse in={showStep}>
          <Card>
            <List>
              <Box>
                <ListItem sx={{ p: 2 }}>
                  <Grid container>
                    <Grid item xs={3}>
                      Profile
                    </Grid>
                    <Grid item xs={9}>
                      {profiles.length === 0 ? (
                        <Skeleton sx={{ height: '3rem' }} />
                      ) : (
                        <ProfileAutocomplete
                          profiles={profiles}
                          handleProfileChange={handleProfileChange}
                          profileId={profileId}
                        />
                      )}
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </Box>
              <Box>
                <ListItem sx={{ p: 2 }}>
                  <Grid container>
                    <Grid item xs={3}>
                      Campaign Name
                    </Grid>
                    <Grid item xs={9}>
                      {profiles.length === 0 ? <Skeleton sx={{ height: '3rem' }} /> : <CampaignNameTextField formik={formik} />}
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </Box>
              <Box>
                <ListItem sx={{ p: 2 }}>
                  <Grid container>
                    <Grid item xs={3}>
                      Date Range
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Typography variant="caption">*To schedule a campaign indefinitely leave end date empty.</Typography>
                        </Grid>
                        <Grid item>
                          {profiles.length === 0 ? <Skeleton sx={{ height: '3rem' }} /> : <DateRangeSettings formik={formik} />}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider />
              </Box>
              <Box>
                <ListItem sx={{ p: 2 }}>
                  <Grid container>
                    <Grid item xs={3}>
                      Campaign Type
                    </Grid>
                    <Grid item xs={9}>
                      {profiles.length === 0 ? (
                        <Skeleton sx={{ height: '3rem' }} />
                      ) : (
                        <CampaignTypeSettings formik={formik} adGroupMode={adGroupMode} />
                      )}
                    </Grid>
                  </Grid>
                </ListItem>
              </Box>
              <Collapse in={formik.values.campaignType === CampaignType.SponsoredProducts}>
                <Divider />
                <Box>
                  <ListItem sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={3}>
                        Bidding Strategy
                      </Grid>
                      <Grid item xs={9}>
                        {profiles.length === 0 ? (
                          <Skeleton sx={{ height: '10rem' }} />
                        ) : (
                          <BiddingStrategySetting formik={formik} />
                        )}
                      </Grid>
                    </Grid>
                  </ListItem>
                </Box>
              </Collapse>
              <Collapse in={formik.values.campaignType === CampaignType.Sba}>
                <Divider />{' '}
                <Box>
                  <ListItem sx={{ p: 2 }}>
                    <Grid container>
                      <Grid item xs={3}>
                        SB Profile <Box sx={{ fontWeight: 'bold' }}>(Optional)</Box>
                      </Grid>
                      <Grid item xs={9}>
                        <SBProfileBuilder formik={formik} />
                      </Grid>
                    </Grid>
                  </ListItem>
                </Box>
              </Collapse>
            </List>
          </Card>
        </Collapse>
      </Box>
    </>
  );
};

CampaignSettingsStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func
};
