import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Card, CardContent, CardHeader, Collapse, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { ReviewStepProps } from '../../../../types/walmart-sams-club/review';
import { formatRawDate } from '../../../grid-components/renderer/campaign/date-cell-components';
import { CampaignStatusChip } from '../../shared/campaign-status-chip';
import { IndividualItemStatusChip } from '../../shared/review-status-chip';
import { ItemStatusTable, KeywordStatusTable } from '../../shared/review-status-tables';
import { ViewVideoProfile } from './view-video-profile';

export default function VideoReviewDetails(props: ReviewStepProps) {
  const { profileId, campaign, adGroupMedia, ads, keywords, review } = props;

  const [collapseOpen, setCollapseOpen] = useState(false);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Button variant="outlined" sx={{ width: '100%' }} onClick={() => setCollapseOpen(!collapseOpen)}>
          Campaign Review Details {collapseOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Button>
      </Box>

      <Collapse in={collapseOpen} sx={{ mb: 5 }}>
        <>
          <Card sx={{ mt: 2 }}>
            <CardHeader
              title={
                <Box sx={{ display: 'inline-flex' }}>
                  <Typography variant="h6">CAMPAIGN</Typography> <CampaignStatusChip status={campaign?.status} sx={{ ml: 2 }} />
                </Box>
              }
              disableTypography={true}
            />
            <CardContent>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={6}>
                <Grid item>
                  {' '}
                  <Typography variant="h6">{campaign?.name}</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Grid item>
                      <DateRangeIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">Start Date:</Typography>
                      <Typography variant="body2">{formatRawDate(campaign?.startDate)}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Grid item>
                      <DateRangeIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">End Date:</Typography>
                      <Typography variant="body2">{formatRawDate(campaign?.endDate)}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }}>
            <CardHeader
              title={
                <Box sx={{ display: 'inline-flex' }}>
                  <Typography variant="h6">VIDEO PROFILE</Typography>
                  {adGroupMedia?.status === 'enabled' ? (
                    <IndividualItemStatusChip
                      status={adGroupMedia?.reviewStatus}
                      sx={{ ml: 2 }}
                      isUnderReview={review?.reviewStatus?.toLowerCase() === 'pending'}
                    />
                  ) : null}
                </Box>
              }
              disableTypography={true}
            />
            <CardContent>
              {!!adGroupMedia ? (
                <Card variant="outlined">
                  <CardContent>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={4}>
                      <Grid item>
                        <ViewVideoProfile
                          profileId={profileId || -1}
                          adGroupMedia={adGroupMedia}
                          isUnderReview={review?.reviewStatus?.toLowerCase() === 'pending'}
                        />
                      </Grid>
                      {!!adGroupMedia?.reviewReason ? (
                        <Grid item>
                          <Typography variant="caption" sx={{ fontStyle: 'italic' }} color="error">
                            {adGroupMedia?.reviewReason}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </CardContent>
                </Card>
              ) : (
                <Typography color="error">No Video Profile exists for this campaign.</Typography>
              )}
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }}>
            <CardHeader title={'ADS'} />
            <CardContent>
              {!!ads && ads.length > 0 ? (
                <ItemStatusTable items={ads} review={review} />
              ) : (
                <Typography color="error">No Ads exist for this campaign.</Typography>
              )}
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }}>
            <CardHeader title={'KEYWORDS'} />
            <CardContent>
              {!!keywords && keywords.length > 0 ? (
                <KeywordStatusTable {...{ keywords }} review={review} />
              ) : (
                <Typography color="error">No keywords exist for this campaign.</Typography>
              )}
            </CardContent>
          </Card>
        </>
      </Collapse>
    </>
  );
}
