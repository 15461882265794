import * as Yup from 'yup';
import {
  campaignBuilderTargetingTypes,
  keywordMatchTypes,
  WalmartTargetingType
} from '../../../../shared/types/walmart-sams-club/campaign';
import { CampaignBuilderAdGroup, CampaignBuilderItem, CampaignBuilderKeyword } from '../../../types/campaign-builder-request';
import { FormikProps } from 'formik';

export enum AdGroupMode {
  SIAG = 'siag',
  MIAG = 'miag'
}

// Reuse entity schemas from campaign builder
const entitySchemas: { [key: string]: Yup.ObjectSchema<any> } = {
  item: Yup.object({
    itemId: Yup.string().required('Item ID is required'),
    itemName: Yup.string().required('Item name is required')
  }),
  keyword: Yup.object({
    keywordText: Yup.string().required('Keyword text is required'),
    matchType: Yup.string()
      .oneOf(keywordMatchTypes.map((type) => type.value))
      .required('Match type is required'),
    bid: Yup.number().required('Bid is required').min(0.01, 'Bid must be at least 0.01')
  }),
  adGroup: Yup.object({
    name: Yup.string().required('Ad group name is required'),
    items: Yup.array()
      .of(Yup.lazy(() => entitySchemas.item))
      .min(1, 'At least one item is required per ad group')
      .required('Items are required for each ad group')
  })
};

// Validation schema for the ads builder form
export const ADS_BUILDER_SCHEMA = Yup.object({
  // Required profile and campaign IDs
  profileId: Yup.number().required('Profile is required'),
  campaignId: Yup.number().required('Campaign is required'),

  // Required ad group mode selection
  adGroupMode: Yup.string().oneOf(Object.values(AdGroupMode)).required('Ad group mode is required'),

  // Items are required only for SIAG
  items: Yup.array().when('adGroupMode', {
    is: AdGroupMode.SIAG,
    then: (schema) => schema.of(entitySchemas.item).required().min(1, 'At least one item is required'),
    otherwise: (schema) => schema.strip()
  }),

  // Keywords can be empty for both modes
  keywords: Yup.array().of(entitySchemas.keyword).nullable(),

  // Ad Groups are required only for MIAG
  adGroups: Yup.array().when('adGroupMode', {
    is: AdGroupMode.MIAG,
    then: (schema) => schema.of(entitySchemas.adGroup).required().min(1, 'At least one ad group is required'),
    otherwise: (schema) => schema.strip()
  })
});

// Initial values for the ads builder form
export const ADS_BUILDER_INITIAL_VALUES = {
  // IDs default to null, will be populated from breadcrumbs if available
  profileId: null,
  campaignId: null,

  // Default ad group mode
  adGroupMode: AdGroupMode.SIAG,

  // Targeting type will be inherited from campaign if not specified
  targetingType: 'manual',

  // SIAG entities
  items: [],
  keywords: [],

  // MIAG entities
  adGroups: [
    {
      name: '',
      items: [],
      keywords: []
    }
  ]
};

// Type definitions
export interface AdsBuilderFormValues {
  profileId: number | null;
  campaignId: number | null;
  adGroupMode: AdGroupMode;
  targetingType: string;
  items: CampaignBuilderItem[];
  keywords: CampaignBuilderKeyword[];
  adGroups: CampaignBuilderAdGroup[];
}

// Helper type to ensure consistent typing throughout the application
export type AdsBuilderFormikProps = FormikProps<AdsBuilderFormValues>;
