import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export const AD_GROUP_STATUS_OPTIONS = [
  {
    label: 'Enabled',
    value: 'enabled',
    icon: PlayArrowIcon
  },
  {
    label: 'Disabled',
    value: 'disabled',
    icon: PauseIcon
  }
];

export interface AdGroupUpdateRequest {
  adGroupId: number;
  name?: string;
  status?: string;
}

export const buildUpdateAdGroupRequest = (adGroup: any): AdGroupUpdateRequest => {
  return {
    adGroupId: adGroup.adGroupId,
    name: adGroup.name,
    status: adGroup.status
  };
};

export interface WalmartAdGroup {
  adGroupId: number;
  name: string;
  status: string;
  campaignId: number;
  campaignName: string;
  profileId: number;
  adItemsCount: number;
  keywordsCount: number;
  clicks: number | null;
  impressions: number | null;
  cost: number | null;
  attributedRevenue: number | null;
  units: number | null;
  orders: number | null;
  newToBrandOrders: number | null;
  newToBrandRevenue: number | null;
  newToBrandUnits: number | null;
  lastMetricsDate: string | null;
  createdAt: string;
  updatedAt: string;
  percentNewToBrandOrders: number;
  percentNewToBrandRevenue: number;
  percentNewToBrandUnits: number;
  conversionRate: number;
  clickThroughRate: number;
  costPerClick: number;
  costPerConversion: number;
  returnOnAdSpend: number;
}
