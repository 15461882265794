import { Box, Container, Link, Paper, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MetricsChart from '../../../shared/components/metrics-chart';
import Page from '../../../shared/components/page';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useVectorPerformanceGrid from '../../../shared/hooks/use-vector-performance-grid';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { formatAsCurrency } from '../../../shared/utilities/numeric-format-utilities';
import { CAMPAIGN_COLUMNS } from '../../components/grid-components/column-configurations/campaign-columns';
import { BreadCrumbParams, useAmazonApi } from '../../hooks/use-amazon-api-v2';
import { getUpdateCampaignRequest } from '../../types/campaign';
import CampaignBulkActions from '../campaigns/bulk-actions/campaign-bulk-actions';
import { AddCampaignsToBudgetGroupActions } from './add-campaigns-to-budget-group-actions';

const BudgetGroupOverview: FC = () => {
  const { getCampaigns, updateCampaign, getCampaignBudgetGroupOverview } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();
  const [campaignBudgetOverview, setCampaignBudgetOverview] = useState<any>({});
  const navigate = useNavigate();

  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [campaignTotals, setCampaignTotals] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);

  let [searchParams] = useSearchParams();
  let profileId = searchParams.get('profileId');
  let campaignBudgetGroupId = searchParams.get('campaignBudgetGroupId');

  const breadcrumbParams: BreadCrumbParams[] = useMemo(() => [{ campaignBudgetGroupId }], [campaignBudgetGroupId]);

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange,
    handleRefreshEntities
  } = useVectorPerformanceGrid(GridConfigSettings.AMAZON_CAMPAIGN_BUDGET_GROUP_OVERVIEW);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchCampaignsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCampaignsData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    if (!breadcrumbParams) {
      return;
    }

    setIsLoading(true);

    const response = await getCampaigns([...breadcrumbParams], pageable, beginDate, endDate, true);

    if (response.success && response.body) {
      setCampaigns(response.body?.dataGrid?.records);
      setRowCount(response.body?.dataGrid?.totalFilteredRecords || DEFAULT_ROW_COUNT);

      if (response.body?.timeSeriesData) {
        setTimeSeriesData(response.body.timeSeriesData);
      }
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const fetchBudgetGroupOverview = async () => {
    if (!campaignBudgetGroupId) {
      return;
    }

    const response = await getCampaignBudgetGroupOverview(campaignBudgetGroupId);

    if (response.success) {
      setCampaignBudgetOverview(response.body);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    const changedFields = Object.keys(newRow).filter((key) => newRow[key] !== oldRow[key]);

    if (changedFields.length == 0) {
      return oldRow;
    }

    const updateCampaignRequest = getUpdateCampaignRequest(newRow, oldRow);
    const response = await updateCampaign({ campaignId: newRow.campaignId, ...updateCampaignRequest });

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    const updatedSyncState = response.body[0].syncState;

    return { ...newRow, syncState: updatedSyncState };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchBudgetGroupOverview();
  }, [campaignBudgetGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!profileId || !campaignBudgetGroupId) {
      navigate('/amazon/campaigns');
    }
  }, [profileId, campaignBudgetGroupId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Page title="Campaigns">
        <Container maxWidth="xl" sx={{ height: '100%' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Box display="flex" alignItems={'center'}>
              <Paper elevation={0} sx={{ p: 2, width: '100%' }}>
                <Box display="flex" flexDirection={'column'}>
                  <Typography variant="h5" mr={2}>
                    Budget Group: {campaignBudgetOverview?.name || 'Loading...'}
                  </Typography>
                  <Box
                    sx={{
                      mr: 'auto'
                    }}
                  ></Box>
                  <Typography variant="h6" mr={2}>
                    Current Month Budget: {formatAsCurrency(campaignBudgetOverview?.currentMonthBudget)}
                  </Typography>
                  <Link href={`/amazon/budgets?profileId=${profileId}`} component={Link} sx={{ maxWidth: '220px' }}>
                    Click here to manage budgets
                  </Link>
                </Box>
              </Paper>
            </Box>
            <Box height={'800px'}>
              {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
                <DataGridPremium
                  rows={[]}
                  disableRowGrouping
                  columns={CAMPAIGN_COLUMNS}
                  loading={true}
                  processRowUpdate={processRowUpdate}
                  initialState={{}}
                />
              ) : (
                <>
                  {!!timeSeriesData && (
                    <Box display="flex" alignItems={'center'} my={'1%'}>
                      <MetricsChart
                        timeSeriesData={timeSeriesData}
                        isMetricsChartLoading={isLoading}
                        chartSettingsKey="AmazonBudgetGroupOverviewChartSettings"
                      />
                    </Box>
                  )}
                  <VectorPerformanceGrid
                    apiRef={apiRef}
                    loading={isLoading}
                    processRowUpdate={processRowUpdate}
                    rows={campaigns}
                    columns={CAMPAIGN_COLUMNS}
                    initialState={initialSettings?.config}
                    saveGridConfig={saveGridConfig}
                    dateConfig={{
                      dateSettings: dateSettings,
                      dateSettingsLoading: dateSettingsLoading,
                      handleDateRangeChange: (dateRange: DateRange<Dayjs>) =>
                        handleDateChange(
                          dateRange,
                          dateSettings,
                          dateSettingsLoading,
                          apiRef,
                          fetchCampaignsData,
                          setDateSettings
                        )
                    }}
                    getRowId={(row: any) => row.campaignId}
                    getRowClassName={(params) => `row-status--${params.row.status}`}
                    bulkActions={
                      <>
                        <AddCampaignsToBudgetGroupActions
                          refreshEntities={() => handleRefreshEntities(settingsLoaded, dateSettings, apiRef, fetchCampaignsData)}
                        />
                        <CampaignBulkActions
                          refreshCampaigns={() => handleRefreshEntities(settingsLoaded, dateSettings, apiRef, fetchCampaignsData)}
                          setIsLoading={setIsLoading}
                        />
                      </>
                    }
                    handleFilterModelChange={(newModel: GridFilterModel) => {
                      handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                    }}
                    handleSortModelChange={(newModel: GridSortModel) => {
                      handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                    }}
                    handlePageChange={(page: number) => {
                      handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                    }}
                    handlePageSizeChange={(pageSize: number) => {
                      handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                    }}
                    rowCount={rowCount}
                    aggregatedTotals={campaignTotals}
                  />
                </>
              )}
            </Box>
          </Stack>
        </Container>
      </Page>
    </>
  );
};

export default BudgetGroupOverview;
