import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Backdrop, Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AddNegativeKeywordsDialog } from '../../../../../sams-club/pages/ads/bulk-actions/add-negative-keywords-dialog';
import { useCurrentRetailerPlatform } from '../../../../hooks/use-current-retailer-platform';
import useAdsApi from '../../../../hooks/use-walmart-sams-club-api';
import { Platform } from '../../../../types/platforms';
import { AddKeywordsDialog } from './add-keywords-dialog';

const VIEWPORT_WIDTH_THRESHOLD = 1455;

const StyledMenu = styled((props: MenuProps) => <Menu elevation={0} {...props} />)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}));

interface KeywordActionsMenuProps {
  selectionModelHasRows: boolean;
}

export default function KeywordActionsMenu(props: KeywordActionsMenuProps) {
  const { selectionModelHasRows } = props;
  const retailerPlatform = useCurrentRetailerPlatform();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isAddKeywordsOpen, setIsAddKeywordsOpen] = useState(false);
  const [isAddNegativeKeywordsOpen, setIsAddNegativeKeywordsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [minBid, setMinBid] = useState(0.3);
  const [hideKeywordBulkActionButtons, setHideKeywordBulkActionButtons] = useState<boolean>(
    window.innerWidth < VIEWPORT_WIDTH_THRESHOLD
  );

  const { enqueueSnackbar } = useSnackbar();
  const apiRef = useGridApiContext();
  const { createKeywords, getCampaign, createNegativeKeywords } = useAdsApi(retailerPlatform);
  const [searchParams, setSearchParams] = useSearchParams();

  const open = Boolean(anchorEl);
  const isSamsClub = retailerPlatform === Platform.SAMS_CLUB.value;

  const getMinBid = async () => {
    const profileId = Number(searchParams.get('profileId') || '');
    const campaignId = Number(searchParams.get('campaignId') || '');

    if (!profileId || !campaignId) {
      return;
    }

    const response = await getCampaign(profileId, campaignId);

    if (response.success) {
      setMinBid(response.body?.campaignType === 'Sba' ? 1.0 : 0.3);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }
  };

  useEffect(() => {
    getMinBid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleResize = () => setHideKeywordBulkActionButtons(window.innerWidth < 1440);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddKeywords = () => {
    setIsAddKeywordsOpen(true);
    setAnchorEl(null);
  };

  const handleAddKeywordsClose = () => {
    setIsAddKeywordsOpen(false);
  };

  const handleAddKeywordsSave = async (keywords: any) => {
    setIsLoading(true);
    setIsAddKeywordsOpen(false);

    const selectedRows = Array.from(apiRef.current.getSelectedRows().values());

    const keywordRequests = selectedRows.flatMap((row: any) =>
      keywords.map((keyword: any) => ({
        campaignId: row.campaignId,
        adGroupId: row.adGroupId,
        state: 'enabled',
        keywordText: keyword.keywordText,
        matchType: keyword.matchType,
        bid: keyword.bid
      }))
    );

    const response = await createKeywords(selectedRows[0]?.profileId, keywordRequests);

    apiRef.current.setRowSelectionModel([]);

    if (response.body.every((responseCode: any) => responseCode.code.toLowerCase() === 'success')) {
      enqueueSnackbar('Keywords added successfully', { variant: 'success' });
    } else {
      response.body?.map((responseBodyItem: { code: string; details: string }) => {
        if (responseBodyItem.code === 'Failure') {
          enqueueSnackbar(responseBodyItem.details, { variant: 'error' });
        }
      });
    }

    setIsLoading(false);
  };

  const handleAddNegativeKeywords = () => {
    setIsAddNegativeKeywordsOpen(true);
    setAnchorEl(null);
  };

  const handleAddNegativeKeywordsClose = () => {
    setIsAddNegativeKeywordsOpen(false);
  };

  const handleAddNegativeKeywordsSave = async (keywords: any) => {
    setIsLoading(true);
    setIsAddNegativeKeywordsOpen(false);

    const selectedRows = Array.from(apiRef.current.getSelectedRows().values());

    const negativeKeywordRequests = selectedRows.flatMap((row: any) =>
      keywords.map((keyword: any) => ({
        adGroupId: row.adGroupId,
        keywordText: keyword.keywordText
      }))
    );

    const response = await createNegativeKeywords(negativeKeywordRequests);

    apiRef.current.setRowSelectionModel([]);

    if (response.body.every((responseCode: any) => responseCode.code.toLowerCase() === 'success')) {
      enqueueSnackbar('Negative Keywords added successfully', { variant: 'success' });
    } else {
      response.body?.map((responseBodyItem: { code: string; details: string }) => {
        if (responseBodyItem.code === 'Failure') {
          enqueueSnackbar(responseBodyItem.details, { variant: 'error' });
        }
      });
    }

    setIsLoading(false);
  };

  return (
    <div>
      {hideKeywordBulkActionButtons ? (
        <>
          <IconButton onClick={handleClick} color="primary" disabled={!selectionModelHasRows}>
            <MoreVertIcon />
          </IconButton>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleAddKeywords} disableRipple>
              <AddIcon />
              Add Keywords
            </MenuItem>
            {isSamsClub && (
              <MenuItem onClick={handleAddNegativeKeywords} disableRipple>
                <AddIcon />
                Add Negative Keywords
              </MenuItem>
            )}
          </StyledMenu>
        </>
      ) : (
        <>
          <Tooltip title={selectionModelHasRows ? '' : `Select one or more items to add keywords`}>
            <span>
              <Button
                sx={{ fontSize: '8pt' }}
                onClick={handleAddKeywords}
                disabled={!selectionModelHasRows}
                startIcon={<AddIcon />}
              >
                Add Keywords
              </Button>
            </span>
          </Tooltip>
          {isSamsClub && (
            <Tooltip title={selectionModelHasRows ? '' : `Select one or more items to add negative keywords`}>
              <span>
                <Button
                  sx={{ fontSize: '8pt' }}
                  onClick={handleAddNegativeKeywords}
                  disabled={!selectionModelHasRows}
                  startIcon={<AddIcon />}
                >
                  Add Negative Keywords
                </Button>
              </span>
            </Tooltip>
          )}
        </>
      )}

      {/* components rendered by buttons */}

      {isAddKeywordsOpen ? (
        <AddKeywordsDialog
          isOpen={isAddKeywordsOpen}
          handleClose={handleAddKeywordsClose}
          handleSave={handleAddKeywordsSave}
          minBid={minBid}
          selectedRows={Array.from(apiRef.current.getSelectedRows().values())}
        />
      ) : null}

      {isAddNegativeKeywordsOpen && isSamsClub ? (
        <AddNegativeKeywordsDialog
          isOpen={isAddNegativeKeywordsOpen}
          handleClose={handleAddNegativeKeywordsClose}
          handleSave={handleAddNegativeKeywordsSave}
        />
      ) : null}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
