import { AutoAwesome, LayersOutlined } from '@mui/icons-material';
import { Box, Button, Card, Chip, Grid, RadioGroup, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { FC, useState } from 'react';
import { AdGroupMode } from './campaign-builder-form/campaign-builder-form-config';
import { CampaignBuilderStepLabelButton } from './campaign-builder-form/step-label-button';

interface AdGroupModeSelectionProps {
  onModeSelect: (mode: AdGroupMode) => void;
  stepNumber?: number;
}

const AdGroupModeCard: FC<{
  title: string;
  callout?: string;
  description: string;
  bulletPoints: string[];
  icon: JSX.Element;
  buttonText: string;
  selected: boolean;
  onClick: () => void;
}> = ({ title, callout, description, bulletPoints, icon, buttonText, selected, onClick }) => (
  <motion.div whileHover={{ y: -4 }} style={{ height: '100%' }}>
    <Card
      sx={{
        p: 3,
        cursor: 'pointer',
        transition: 'all 0.2s',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderColor: selected ? 'primary.main' : 'transparent',
        borderWidth: 2,
        borderStyle: 'solid',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: 4
        }
      }}
      onClick={onClick}
    >
      <Stack spacing={2} alignItems="flex-start" sx={{ height: '100%' }}>
        <Box>{icon}</Box>
        <Box sx={{ flex: 1 }}>
          <Box display="flex" alignItems="center" justifyItems="center" mb={1}>
            <Typography variant="h6">{title}</Typography>
            {callout && <Chip label={callout} color="secondary" size="small" sx={{ ml: 1 }} />}
          </Box>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {description}
          </Typography>
          <Box component="ul" sx={{ pl: 2, mb: 'auto' }}>
            {bulletPoints.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </Box>
        </Box>
        <Box>
          <Button variant="outlined" fullWidth>
            {buttonText}
          </Button>
        </Box>
      </Stack>
    </Card>
  </motion.div>
);

export const AdGroupModeSelection: FC<AdGroupModeSelectionProps> = ({ onModeSelect, stepNumber = 2 }) => {
  const [selectedMode, setSelectedMode] = useState<AdGroupMode | null>(AdGroupMode.SIAG);

  const adGroupTypes = [
    {
      mode: AdGroupMode.SIAG,
      title: 'Single-item Ad Groups (SIAG)',
      callout: 'Default',
      description: 'Create a campaign where each item gets its own ad group.',
      bulletPoints: [
        'Maximum control over performance',
        'Bid control at the item-keyword level',
        'Detailed performance metrics for each item'
      ],
      icon: <AutoAwesome color="primary" sx={{ fontSize: 40 }} />,
      buttonText: 'Select SIAG'
    },
    {
      mode: AdGroupMode.MIAG,
      title: 'Multi-item Ad Groups (MIAG)',
      description: 'Create a campaign where ad groups contain multiple items.',
      bulletPoints: [
        'Prevent self-competing bids for items bidding on the same keywords',
        'Group variants together for simpler ad management',
        'All items in an ad group share the same keywords and bids'
      ],
      icon: <LayersOutlined color="primary" sx={{ fontSize: 40 }} />,
      buttonText: 'Select MIAG'
    }
  ];

  const handleModeSelect = (mode: AdGroupMode) => {
    setSelectedMode(mode);
    onModeSelect(mode);
  };

  return (
    <Box sx={{ mb: 4 }}>
      <CampaignBuilderStepLabelButton
        showStep={true}
        handleStepButtonClick={() => {}}
        stepNumber={stepNumber}
        stepLabel="Ad Group Settings"
      />
      <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
        Select how you want to organize your ads within this campaign
      </Typography>

      <RadioGroup value={selectedMode} onChange={(e) => handleModeSelect(e.target.value as AdGroupMode)}>
        <Grid container spacing={3}>
          {adGroupTypes.map((type) => (
            <Grid item xs={12} md={6} key={type.mode} alignContent={'stretch'}>
              <AdGroupModeCard
                title={type.title}
                callout={type.callout}
                description={type.description}
                bulletPoints={type.bulletPoints}
                icon={type.icon}
                buttonText={type.buttonText}
                selected={selectedMode === type.mode}
                onClick={() => handleModeSelect(type.mode)}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </Box>
  );
};
