import { ResponseObject } from '../../shared/utilities/fetch-utilities';
import { Campaign, WalmartCampaignOption, WalmartCampaignOptions } from '../../shared/types/walmart-sams-club/campaign';
import { CampaignOptionsSelections } from '../../shared/types/walmart-sams-club/campaign-options-selections';
import { CampaignFormValues } from '../pages/campaigns/campaign-builder-form/campaign-builder-form-config';

export interface CampaignBuilderSbProfile {
  searchAmpName: string;
  headlineText: string;
  logoDataUri: string;
  clickUrl: string;
}

export type CampaignBuilderAdGroup = {
  name: string;
  items: CampaignBuilderItem[];
  keywords: CampaignBuilderKeyword[];
};

export interface CampaignBuilderItem {
  itemId: number;
  itemName: string;
}

export interface CampaignBuilderKeyword {
  keywordText: string;
  matchType: string;
  bid: number;
}

export interface CampaignBuilderRequest {
  campaign: Campaign;
  sbaProfile?: CampaignBuilderSbProfile;
  items: CampaignBuilderItem[];
  keywords: CampaignBuilderKeyword[];
}

export interface AdBuilderRequest {
  items: CampaignBuilderItem[];
  keywords: CampaignBuilderKeyword[];
}

export const createCampaignDto = (values: any): Campaign => {
  let campaignOptions: WalmartCampaignOptions | null = null;

  if (values.campaignType === 'sponsoredProducts' && values.targetingType === 'auto') {
    campaignOptions = [];
    if (values.brandTermTargeting === CampaignOptionsSelections.OptOut) {
      campaignOptions.push(WalmartCampaignOption.BrandTermOptOut);
    }
    if (values.complementaryTargeting === CampaignOptionsSelections.OptOut) {
      campaignOptions.push(WalmartCampaignOption.ComplementaryTermOptOut);
    }
  }
  return {
    name: values.name,
    campaignType: values.campaignType,
    targetingType: values.targetingType,
    startDate: values.startDate,
    ...(values.endDate ? { endDate: values.endDate } : {}),
    budgetType: values.budgetType,
    ...(['daily', 'both'].includes(values.budgetType) && { dailyBudget: Number(values.dailyBudget) }),
    ...(['total', 'both'].includes(values.budgetType) && { totalBudget: Number(values.totalBudget) }),
    biddingStrategy: values.biddingStrategy,
    campaignOptions: campaignOptions
  };
};

export interface MultiItemAdGroupCampaignBuilderRequestDto {
  campaign: Campaign;
  adGroups: CampaignBuilderAdGroup[];
}

export const createSingleItemAdGroupCampaignBuilderRequest = (values: CampaignFormValues): CampaignBuilderRequest => {
  return {
    campaign: createCampaignDto(values),
    ...(values.campaignType === 'sba' &&
      values?.searchAmpName &&
      values?.headlineText &&
      values?.clickUrl &&
      values?.logoDataUri && {
        sbaProfile: {
          searchAmpName: values.searchAmpName,
          headlineText: values.headlineText,
          clickUrl: values.clickUrl,
          logoDataUri: values.logoDataUri
        }
      }),
    items: values.items.map((item: CampaignBuilderItem) => ({
      itemId: item.itemId,
      itemName: item.itemName
    })),
    keywords: values.keywords
  };
};

export const createMultiItemAdGroupCampaignBuilderRequest = (
  values: CampaignFormValues
): MultiItemAdGroupCampaignBuilderRequestDto => {
  return {
    campaign: createCampaignDto(values),
    adGroups: values.adGroups.map((adGroup: CampaignBuilderAdGroup) => ({
      name: adGroup.name,
      items: adGroup.items.map((item: CampaignBuilderItem) => ({
        itemId: item.itemId,
        itemName: item.itemName
      })),
      keywords: adGroup.keywords.map((keyword: CampaignBuilderKeyword) => ({
        keywordText: keyword.keywordText,
        matchType: keyword.matchType,
        bid: keyword.bid
      }))
    }))
  };
};

export const isCampaignCreationSuccessful = (response: ResponseObject) => {
  const { adGroups, keywords, adItems, campaign, sbaProfileImage, sbaProfile } = response.body;

  return (
    adGroups?.every((adGroup: any) => adGroup?.code?.toLowerCase() === 'success') &&
    keywords?.every((keyword: any) => keyword?.code?.toLowerCase() === 'success') &&
    adItems?.every((adItem: any) => adItem?.code?.toLowerCase() === 'success') &&
    campaign?.code?.toLowerCase() === 'success' &&
    (sbaProfileImage?.code ? sbaProfileImage?.code?.toLowerCase() === 'success' : true) &&
    (sbaProfile?.code ? sbaProfile?.code?.toLowerCase() === 'success' : true)
  );
};
