import EditIcon from '@mui/icons-material/Edit';
import LaunchIcon from '@mui/icons-material/Launch';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Button, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { IndividualItemStatusChip } from '../../shared/review-status-chip';
import { SBProfileStatus } from '../../../../types/walmart-sams-club/sb-profile';

interface ViewSbProfileProps {
  searchAmpName?: string;
  headlineText?: string;
  clickUrl?: string;
  logoUrl?: string;
  reviewStatus?: string;
  status?: string;
  handleEditClick?: () => void;
  handleEnableClick?: () => void;
  isStatusLoading?: boolean;
  allowEdit?: boolean;
  isUnderReview?: boolean;
}

export const ViewSBProfile = (props: ViewSbProfileProps) => {
  const {
    searchAmpName,
    headlineText,
    clickUrl,
    logoUrl,
    reviewStatus,
    status,
    handleEditClick,
    handleEnableClick,
    isStatusLoading,
    allowEdit,
    isUnderReview
  } = props;

  const ProfileStatus = () => {
    if (allowEdit) {
      return status === SBProfileStatus.DISABLED ? (
        <Tooltip title="Click to enable this profile" placement="bottom">
          <Button
            onClick={() => handleEnableClick?.()}
            startIcon={<RadioButtonUncheckedIcon />}
            disabled={isStatusLoading}
            color="primary"
          >
            Disabled
          </Button>
        </Tooltip>
      ) : (
        <Button startIcon={<RadioButtonCheckedIcon />} disabled={isStatusLoading} color="success">
          Enabled
        </Button>
      );
    }

    if (status === SBProfileStatus.DISABLED) {
      return (
        <Chip
          label="Disabled"
          icon={<RadioButtonUncheckedIcon />}
          variant={isStatusLoading ? 'filled' : 'outlined'}
          size="small"
        />
      );
    }

    return (
      <Chip
        label="Enabled"
        icon={<RadioButtonCheckedIcon />}
        color={isStatusLoading ? 'default' : 'success'}
        variant={isStatusLoading ? 'filled' : 'outlined'}
        size="small"
      />
    );
  };

  return (
    <Grid container direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 1 }} spacing={3}>
      <Grid item>
        <img src={logoUrl} height="90" width="150" />
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item sx={{ pb: 1 }}>
            <Typography variant="h6">{searchAmpName}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{headlineText}</Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', alignItems: 'center', py: 1 }}>
            <LaunchIcon sx={{ mr: 1 }} />{' '}
            <Tooltip title={clickUrl || ''}>
              <Typography
                variant="body2"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  fontSize: '11px'
                }}
              >
                <Link href={clickUrl} target="_blank" rel="noopener">
                  {clickUrl?.substring(0, 20) + '...' + clickUrl?.substring(clickUrl?.length - 20)}
                </Link>
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <IndividualItemStatusChip status={reviewStatus} isUnderReview={isUnderReview} />
      </Grid>
      <Grid item>
        <ProfileStatus />
      </Grid>
      <Grid item>
        <IconButton onClick={() => handleEditClick?.()} sx={{ visibility: allowEdit ? 'visible' : 'hidden' }}>
          <EditIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
