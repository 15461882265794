import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, Link, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import MetricsChart from '../../../shared/components/metrics-chart';
import Page from '../../../shared/components/page';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useVectorPerformanceGrid from '../../../shared/hooks/use-vector-performance-grid';
import { BreadCrumbParams } from '../../../shared/hooks/use-wmt-map-updated-api';
import { amazonRoutes } from '../../../shared/routing/routes-objects';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { CAMPAIGN_COLUMNS } from '../../components/grid-components/column-configurations/campaign-columns';
import { useAmazonApi } from '../../hooks/use-amazon-api-v2';
import { getUpdateCampaignRequest } from '../../types/campaign';
import CampaignBulkActions from './bulk-actions/campaign-bulk-actions';

const Campaigns: FC = () => {
  const { getCampaigns, updateCampaign } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);

  let [searchParams] = useSearchParams();
  let profileId = useMemo(() => searchParams.get('profileId'), [searchParams]);

  const breadcrumbParams: BreadCrumbParams[] = useMemo(() => [{ profileId }], [profileId]);

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange,
    handleRefreshEntities
  } = useVectorPerformanceGrid(GridConfigSettings.AMAZON_CAMPAIGN);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchCampaignsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCampaignsData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    if (!breadcrumbParams) {
      return;
    }

    setIsLoading(true);

    const response = await getCampaigns([...breadcrumbParams], pageable, beginDate, endDate, true);

    if (response.success && response.body) {
      setCampaigns(response.body?.dataGrid?.records);
      setRowCount(response.body?.dataGrid?.totalFilteredRecords || DEFAULT_ROW_COUNT);

      if (response.body?.timeSeriesData) {
        setTimeSeriesData(response.body.timeSeriesData);
      }
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const processRowUpdate = useCallback(async (newRow: any, oldRow: any) => {
    const changedFields = Object.keys(newRow).filter((key) => newRow[key] !== oldRow[key]);

    if (changedFields.length == 0) {
      return oldRow;
    }

    const updateCampaignRequest = getUpdateCampaignRequest(newRow, oldRow);
    const response = await updateCampaign({ campaignId: newRow.campaignId, ...updateCampaignRequest });

    if (!response.success) {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
      return oldRow;
    }

    const updatedSyncState = response.body[0].syncState;

    return { ...newRow, syncState: updatedSyncState };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page title="Campaigns">
      <Container maxWidth="xl" style={{ height: '93vh', marginTop: '10px' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Box display="flex" alignItems={'center'}>
            <Typography variant="h5" mr={2}>
              Campaigns
            </Typography>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
            <Link
              to={`${amazonRoutes.find((route) => route.key === 'amazon-create-campaigns')?.path}${
                profileId ? `?profileId=${profileId}` : ''
              }`}
              component={RouterLink}
              style={{ textDecoration: 'none' }}
            >
              <Button startIcon={<AddIcon fontSize="small" />} variant="contained" sx={{ my: '5%' }}>
                Create Campaign
              </Button>
            </Link>
          </Box>
          {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
            <DataGridPremium
              rows={[]}
              disableRowGrouping
              columns={CAMPAIGN_COLUMNS}
              loading={true}
              processRowUpdate={processRowUpdate}
              initialState={{}}
            />
          ) : (
            <>
              {!!timeSeriesData && (
                <Box display="flex" alignItems={'center'} my={'1%'}>
                  <MetricsChart
                    timeSeriesData={timeSeriesData}
                    isMetricsChartLoading={isLoading}
                    chartSettingsKey="AmazonCampaignsChartSettings"
                  />
                </Box>
              )}
              <VectorPerformanceGrid
                apiRef={apiRef}
                loading={isLoading}
                processRowUpdate={processRowUpdate}
                rows={campaigns}
                columns={CAMPAIGN_COLUMNS}
                initialState={initialSettings?.config}
                saveGridConfig={saveGridConfig}
                dateConfig={{
                  dateSettings: dateSettings,
                  dateSettingsLoading: dateSettingsLoading,
                  handleDateRangeChange: (dateRange: DateRange<Dayjs>) =>
                    handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchCampaignsData, setDateSettings)
                }}
                getRowId={(row: any) => row.campaignId}
                getRowClassName={(params) => `row-status--${params.row.status}`}
                bulkActions={
                  <CampaignBulkActions
                    setIsLoading={setIsLoading}
                    refreshCampaigns={() => handleRefreshEntities(settingsLoaded, dateSettings, apiRef, fetchCampaignsData)}
                  />
                }
                handleFilterModelChange={(newModel: GridFilterModel, searchableColumns?: string[]) => {
                  handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchCampaignsData, searchableColumns);
                }}
                handleSortModelChange={(newModel: GridSortModel) => {
                  handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                }}
                handlePageChange={(page: number) => {
                  handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                }}
                handlePageSizeChange={(pageSize: number) => {
                  handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchCampaignsData);
                }}
                rowCount={rowCount}
                allowQuickFilterSearch={true}
              />
            </>
          )}
        </Stack>
      </Container>
    </Page>
  );
};

export default Campaigns;
