import React, { useState, useEffect } from 'react';
import { Badge, IconButton, Menu, Typography, Box, Link, Divider, Tab, Tabs, Tooltip, CircularProgress } from '@mui/material';
import { NotificationsOutlined, ArchiveOutlined, InboxOutlined, TuneOutlined, Inbox, MoveToInbox } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';
import { ClientProfile, Notification, NotificationReason } from './notifications-types-handlers';
import { handleNotificationClick } from './notifications-types-handlers';
import useSharedApi from '../../hooks/use-shared-api';
import { NotificationItem } from './notification-item';
import EmptyNotifications from './empty-notifications';
import { NotificationPreferencesDialog } from './notifications-preferences-dialog';
import { Pageable } from '../../types/pageable';

export function NotificationComponent() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [copiedIds, setCopiedIds] = useState<Record<number, boolean>>({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activeNotifications, setActiveNotifications] = useState<Notification[]>([]);
  const [archivedNotifications, setArchivedNotifications] = useState<Notification[]>([]);
  const [activeTotalCount, setActiveTotalCount] = useState(0);
  const [archivedTotalCount, setArchivedTotalCount] = useState(0);
  const [isExpandedActive, setIsExpandedActive] = useState(false);
  const [isExpandedArchived, setIsExpandedArchived] = useState(false);

  const {
    getNotificationByUser,
    updateIsReadNotification,
    markAllNotificationAsRead,
    updateIsArchivedNotification,
    archiveAllNotifications,
    getUserNotificationsPreferences,
    updateUserNotificationsPreferences,
    getAllProfilesWithAccess
  } = useSharedApi();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();

  const open = Boolean(anchorEl);
  const FIVE_MINUTES_IN_MS = 300000;
  const FOURTEEN_DAYS_IN_MS = 14 * 24 * 60 * 60 * 1000;

  const unreadCount =
    activeNotifications.filter((notification) => !notification.isRead).length +
    archivedNotifications.filter((notification) => !notification.isRead).length;
  const shouldShowMarkAllAsRead =
    (activeNotifications.some((notification) => !notification.isRead) && selectedTab === 0) ||
    (archivedNotifications.some((notification) => !notification.isRead) && selectedTab === 1);
  const [preferencesByType, setPreferencesByType] = useState<{ [key in NotificationReason]?: number[] }>({});
  const [openPreferences, setOpenPreferences] = useState(false);
  const [allProfiles, setAllProfiles] = useState<ClientProfile[]>([]);

  useEffect(() => {
    async function fetchProfiles() {
      const response = await getAllProfilesWithAccess();
      if (response?.body) {
        setAllProfiles(response.body);
      }
    }
    fetchProfiles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const amazonProfiles = allProfiles
    .filter((profile) => profile.clientName.toLowerCase().includes('amazon'))
    .sort((a, b) => a.clientName.localeCompare(b.clientName));
  const samsProfiles = allProfiles
    .filter((profile) => profile.clientName.toLowerCase().includes("sam's club"))
    .sort((a, b) => a.clientName.localeCompare(b.clientName));
  const walmartProfiles = allProfiles
    .filter((profile) => profile.clientName.toLowerCase().includes('walmart'))
    .sort((a, b) => a.clientName.localeCompare(b.clientName));

  useEffect(() => {
    if (user?.email) {
      fetchNotifications();
    }
  }, [user, isExpandedActive, isExpandedArchived, location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const interval = setInterval(() => {
      if (user?.email) {
        fetchNotifications();
      }
    }, FIVE_MINUTES_IN_MS);

    return () => clearInterval(interval);
  }, [user, location]); // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchNotifications() {
    try {
      setIsLoading(true);

      const pageableActive: Pageable = {
        limit: isExpandedActive ? 100 : 10,
        offset: 0,
        filters: [
          { column: 'userEmail', comparator: 'Equals', value: user.email },
          { column: 'isArchived', comparator: 'Equals', value: false }
        ],
        sorts: [{ column: 'createdAt', direction: 'Descending' }]
      };
      const responseActive = await getNotificationByUser(pageableActive);

      const pageableArchived = {
        limit: isExpandedArchived ? 100 : 10,
        offset: 0,
        filters: [
          { column: 'userEmail', comparator: 'Equals', value: user.email },
          { column: 'isArchived', comparator: 'Equals', value: true },
          {
            column: 'archivedDate',
            comparator: 'GreaterThanOrEquals',
            value: new Date(Date.now() - FOURTEEN_DAYS_IN_MS).toISOString()
          }
        ],
        sort: [{ column: 'archivedDate', order: 'desc' }]
      };
      const responseArchived = await getNotificationByUser(pageableArchived);

      if (responseActive?.body) {
        setActiveNotifications(responseActive.body.records);
        setActiveTotalCount(responseActive.body.totalRecords);
      }
      if (responseArchived?.body) {
        setArchivedNotifications(responseArchived.body.records);
        setArchivedTotalCount(responseArchived.body.totalRecords);
      }
    } catch (error) {
      enqueueSnackbar('Failed to fetch notifications', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }

  function handleCopyCampaignId(e: React.MouseEvent, campaignId: number) {
    e.stopPropagation();
    navigator.clipboard.writeText(campaignId.toString());
    setCopiedIds((prev) => ({ ...prev, [campaignId]: true }));
    setTimeout(() => {
      setCopiedIds((prev) => ({ ...prev, [campaignId]: false }));
    }, 2000);
  }

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  async function handleToggleRead(notificationId: number, currentReadStatus: boolean) {
    try {
      await updateIsReadNotification(notificationId, !currentReadStatus);
      const updateNotification = (notifications: any[]) =>
        notifications.map((notification) =>
          notification.id === notificationId ? { ...notification, isRead: !currentReadStatus } : notification
        );

      if (selectedTab === 0) {
        setActiveNotifications(updateNotification);
      } else {
        setArchivedNotifications(updateNotification);
      }
    } catch (error) {
      enqueueSnackbar('Failed to update notification status', { variant: 'error' });
    }
  }

  async function handleMarkAllAsRead() {
    try {
      await markAllNotificationAsRead(selectedTab === 0 ? false : true);

      if (selectedTab === 0) {
        setActiveNotifications((prev) => prev.map((notification) => ({ ...notification, isRead: true })));
      } else {
        setArchivedNotifications((prev) => prev.map((notification) => ({ ...notification, isRead: true })));
      }
    } catch (error) {
      enqueueSnackbar('Failed to mark all notifications as read', { variant: 'error' });
    }
  }

  async function handleArchiveAll() {
    try {
      await archiveAllNotifications(selectedTab === 0 ? false : true);

      if (selectedTab === 0) {
        const currentTime = new Date().toISOString();
        setArchivedNotifications((notifications) => [
          ...activeNotifications.map((notification) => ({ ...notification, isArchived: true, archivedDate: currentTime })),
          ...notifications
        ]);
        setActiveNotifications([]);
        setActiveTotalCount(0);
        setArchivedTotalCount((total) => total + activeNotifications.length);
      } else {
        setActiveNotifications((notifications) => [
          ...archivedNotifications.map((notification) => ({ ...notification, isArchived: false, archivedDate: undefined })),
          ...notifications
        ]);
        setArchivedNotifications([]);
        setArchivedTotalCount(0);
        setActiveTotalCount((total) => total + archivedNotifications.length);
      }
    } catch (error) {
      enqueueSnackbar('Failed to archive all notifications', { variant: 'error' });
    }
  }

  async function handleNotificationItemClick(notification: Notification) {
    try {
      if (!notification.isRead) {
        await handleToggleRead(notification.id, false);
      }
      handleNotificationClick(notification.reason, notification.notification.details, navigate);
      handleClose();
    } catch (error) {
      enqueueSnackbar('Failed to process notification', { variant: 'error' });
    }
  }

  async function handleArchive(notificationId: number, shouldArchive: boolean) {
    try {
      await updateIsArchivedNotification(notificationId, shouldArchive);
      const currentTime = new Date().toISOString();

      if (shouldArchive) {
        const notification = activeNotifications.find((notification) => notification.id === notificationId);
        if (notification) {
          setActiveNotifications((prev) => prev.filter((notification) => notification.id !== notificationId));
          setArchivedNotifications((prev) => [{ ...notification, isArchived: true, archivedDate: currentTime }, ...prev]);
          setActiveTotalCount((count) => count - 1);
          setArchivedTotalCount((count) => count + 1);
        }
      } else {
        const notification = archivedNotifications.find((notification) => notification.id === notificationId);
        if (notification) {
          setArchivedNotifications((prev) => prev.filter((notification) => notification.id !== notificationId));
          setActiveNotifications((prev) => [{ ...notification, isArchived: false, archivedDate: undefined }, ...prev]);
          setArchivedTotalCount((count) => count - 1);
          setActiveTotalCount((count) => count + 1);
        }
      }

      setActiveNotifications((prev) => {
        const updated = [...prev];
        updated.sort(
          (notificationA, notificationB) =>
            new Date(notificationB.notification.createdAt || 0).getTime() -
            new Date(notificationA.notification.createdAt || 0).getTime()
        );
        return updated;
      });

      setArchivedNotifications((prev) => {
        const updated = [...prev];
        updated.sort(
          (notificationA, notificationB) =>
            new Date(notificationB.archivedDate || 0).getTime() - new Date(notificationA.archivedDate || 0).getTime()
        );
        return updated;
      });
    } catch (error) {
      enqueueSnackbar(shouldArchive ? 'Failed to archive notification' : 'Failed to unarchive notification', {
        variant: 'error'
      });
    }
  }

  async function handleOpenPreferences() {
    try {
      setOpenPreferences(true);
      const response = await getUserNotificationsPreferences();
      const prefs = response?.body;
      if (!prefs || !prefs.preferencesByReason) {
        setPreferencesByType({});
      } else {
        setPreferencesByType(prefs.preferencesByReason);
      }
    } catch (error) {
      enqueueSnackbar('Failed to fetch user preferences', { variant: 'error' });
    }
  }

  async function handleSavePreferences(updatedPreferences: { [key in NotificationReason]?: number[] }) {
    try {
      const body = {
        userEmail: user?.email || '',
        preferencesByReason: updatedPreferences
      };
      let response = await updateUserNotificationsPreferences(body);
      if (!response.success) {
        enqueueSnackbar('Failed to update preferences', { variant: 'error' });
        return;
      }
      enqueueSnackbar('Preferences saved!', { variant: 'success' });
      setOpenPreferences(false);
    } catch (error) {
      enqueueSnackbar('Failed to update preferences', { variant: 'error' });
    }
  }

  return (
    <>
      <Tooltip title={`${unreadCount} unread notifications`}>
        <IconButton
          onClick={handleClick}
          size="large"
          aria-controls={open ? 'notifications-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={{
            transition: 'transform 0.2s',
            '&:hover': { transform: 'scale(1.1)' }
          }}
        >
          <Badge
            badgeContent={unreadCount}
            color="error"
            sx={{
              '& .MuiBadge-badge': {
                animation: unreadCount > 0 ? 'pulse 3s infinite' : 'none',
                '@keyframes pulse': {
                  '0%': { transform: 'scale(0.65)' },
                  '50%': { transform: 'scale(0.75)' },
                  '100%': { transform: 'scale(0.65)' }
                }
              }
            }}
          >
            <NotificationsOutlined />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        id="notifications-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxHeight: isExpandedActive || isExpandedArchived ? '90vh' : '70vh',
            width: '500px',
            overflow: 'auto',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiList-root': { padding: 0 },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Notifications</Typography>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            {shouldShowMarkAllAsRead && (
              <IconButton
                onClick={handleMarkAllAsRead}
                disabled={unreadCount === 0}
                sx={{
                  color: unreadCount > 0 ? 'primary.main' : 'text.disabled',
                  cursor: unreadCount > 0 ? 'pointer' : 'default',
                  '&:hover': { opacity: unreadCount > 0 ? 0.8 : 1 }
                }}
              >
                <Typography variant="body2">Mark all as read</Typography>
              </IconButton>
            )}
            <Tooltip title="Preferences">
              <Link
                component="button"
                variant="body2"
                onClick={handleOpenPreferences}
                sx={{ display: 'flex', alignItems: 'center', ml: '0.5rem' }}
              >
                <TuneOutlined color="action" />
              </Link>
            </Tooltip>
          </Box>
        </Box>
        <Divider />
        <Tabs
          value={selectedTab}
          onChange={(_, newValue) => setSelectedTab(newValue)}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            paddingX: 2,
            '& .MuiTab-root': {
              minHeight: '48px',
              display: 'flex',
              flexDirection: 'row',
              gap: 0.2
            }
          }}
        >
          <Tab icon={<InboxOutlined />} label={`Active (${activeNotifications.length})`} iconPosition="start" />
          <Tab icon={<ArchiveOutlined />} label={`Archived (${archivedNotifications.length})`} iconPosition="start" />
          <Box sx={{ flex: 1 }} />
          {selectedTab === 0 && activeNotifications.length > 0 && (
            <Tooltip title="Archive all notifications">
              <Link
                component="button"
                underline="always"
                onClick={handleArchiveAll}
                sx={{
                  color: 'primary.main',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': { opacity: 0.8 }
                }}
              >
                <Inbox fontSize="small" />
              </Link>
            </Tooltip>
          )}
          {selectedTab === 1 && archivedNotifications.length > 0 && (
            <Tooltip title="Unarchive all notifications">
              <Link
                component="button"
                underline="always"
                onClick={handleArchiveAll}
                sx={{
                  color: 'primary.main',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  '&:hover': { opacity: 0.8 }
                }}
              >
                <MoveToInbox fontSize="small" />
              </Link>
            </Tooltip>
          )}
        </Tabs>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress size={24} />
          </Box>
        ) : selectedTab === 0 ? (
          activeNotifications.length === 0 ? (
            <Box sx={{ p: 3, textAlign: 'center' }}>
              <EmptyNotifications />
            </Box>
          ) : (
            activeNotifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                selectedTab={selectedTab}
                handleNotificationItemClick={handleNotificationItemClick}
                handleArchive={handleArchive}
                handleToggleRead={handleToggleRead}
                copiedIds={copiedIds}
                handleCopyCampaignId={handleCopyCampaignId}
              />
            ))
          )
        ) : archivedNotifications.length === 0 ? (
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <EmptyNotifications />
          </Box>
        ) : (
          archivedNotifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              selectedTab={selectedTab}
              handleNotificationItemClick={handleNotificationItemClick}
              handleArchive={handleArchive}
              handleToggleRead={handleToggleRead}
              copiedIds={copiedIds}
              handleCopyCampaignId={handleCopyCampaignId}
            />
          ))
        )}
        {!isLoading && ((selectedTab === 0 && activeTotalCount > 10) || (selectedTab === 1 && archivedTotalCount > 10)) && (
          <Box
            sx={{
              p: 0.5,
              display: 'flex',
              justifyContent: 'center',
              bgcolor: 'background.paper',
              position: 'sticky',
              bottom: 0,
              borderTop: 1,
              borderColor: 'divider'
            }}
          >
            <Typography
              variant="caption"
              onClick={() => {
                if (selectedTab === 0) {
                  setIsExpandedActive(!isExpandedActive);
                } else {
                  setIsExpandedArchived(!isExpandedArchived);
                }
              }}
              sx={{
                textTransform: 'none',
                textDecoration:
                  (selectedTab === 0 && isExpandedActive) || (selectedTab === 1 && isExpandedArchived) ? 'none' : 'underline',
                color:
                  (selectedTab === 0 && isExpandedActive) || (selectedTab === 1 && isExpandedArchived)
                    ? 'text.secondary'
                    : 'primary.main',
                cursor: 'pointer',
                paddingY: 0.5,
                '&:hover': { opacity: 0.8 }
              }}
            >
              {selectedTab === 0
                ? isExpandedActive
                  ? 'Show less'
                  : `See all ${activeTotalCount} active notifications`
                : isExpandedArchived
                  ? 'Show less'
                  : `See all ${archivedTotalCount} archived notifications`}
            </Typography>
          </Box>
        )}
      </Menu>

      <NotificationPreferencesDialog
        open={openPreferences}
        onClose={() => setOpenPreferences(false)}
        onSave={handleSavePreferences}
        amazonProfiles={amazonProfiles}
        walmartProfiles={walmartProfiles}
        samsProfiles={samsProfiles}
        initialSubscribedByType={preferencesByType}
      />
    </>
  );
}
