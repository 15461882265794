import AddIcon from '@mui/icons-material/Add';
import { Backdrop, Box, Button, CircularProgress, Container, Stack, Typography } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import { DataGridPremium, GridFilterModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Page from '../../../shared/components/page';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import ProfileCreationModal from '../../../shared/components/profile-creation-modal/profile-creation-modal';
import useDialog from '../../../shared/hooks/use-dialog';
import useVectorPerformanceGrid from '../../../shared/hooks/use-vector-performance-grid';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { Platform } from '../../../shared/types/platforms';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { PROFILE_COLUMNS } from '../../components/grid-components/column-configurations/profile-columns';
import { useAmazonApi } from '../../hooks/use-amazon-api';

export interface DateConfig {
  beginDate: string;
  endDate: string;
  datePickerIncrementInDays: number;
}

const Profiles: FC = () => {
  const { getProfiles } = useAmazonApi();
  const { enqueueSnackbar } = useSnackbar();

  const [profiles, setProfiles] = useState<any[]>([]);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const { toggle: toggleProfileCreationDialog, isShowing: profileCreationDialogIsShowing } = useDialog();

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange
  } = useVectorPerformanceGrid(GridConfigSettings.AMAZON_PROFILE);

  const apiRef = useGridApiRef();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchProfilesData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProfilesData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    setIsGridLoading(true);

    const response = await getProfiles(pageable, beginDate, endDate);

    if (response.success && response.body) {
      setProfiles(response.body.records);
      setRowCount(response.body?.totalFilteredRecords || DEFAULT_ROW_COUNT);
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsGridLoading(false);
  };

  const processRowUpdate = useCallback((newRow: any, oldRow: any) => {
    //Execute Put Request here
    return newRow;
  }, []);

  return (
    <Page title="Profiles">
      <Container maxWidth="xl" style={{ height: '93vh' }}>
        <Stack spacing={1} style={{ height: '100%' }}>
          <Box display="flex" alignItems={'center'} my={'5%'}>
            <Typography variant="h4">Profiles</Typography>
            <Box
              sx={{
                mr: 'auto'
              }}
            ></Box>
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => toggleProfileCreationDialog()}>
              Add Profile
            </Button>
          </Box>
          {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
            <DataGridPremium
              rows={[]}
              columns={PROFILE_COLUMNS}
              loading={true}
              processRowUpdate={processRowUpdate}
              initialState={{}}
              key={uuidv4()}
              disableRowGrouping
            />
          ) : (
            <VectorPerformanceGrid
              apiRef={apiRef}
              processRowUpdate={processRowUpdate}
              loading={isGridLoading}
              rows={profiles}
              columns={PROFILE_COLUMNS}
              initialState={initialSettings?.config}
              saveGridConfig={saveGridConfig}
              dateConfig={{
                dateSettings: dateSettings,
                dateSettingsLoading: dateSettingsLoading,
                handleDateRangeChange: (dateRange: DateRange<Dayjs>) =>
                  handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchProfilesData, setDateSettings)
              }}
              getRowId={(row: any) => row.profileId}
              handleFilterModelChange={(newModel: GridFilterModel) => {
                handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchProfilesData);
              }}
              handleSortModelChange={(newModel: GridSortModel) => {
                handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchProfilesData);
              }}
              handlePageChange={(page: number) => {
                handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchProfilesData);
              }}
              handlePageSizeChange={(pageSize: number) => {
                handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchProfilesData);
              }}
              rowCount={rowCount}
            />
          )}
        </Stack>
      </Container>
      {profileCreationDialogIsShowing && (
        <ProfileCreationModal
          isOpen={profileCreationDialogIsShowing}
          onClose={() => toggleProfileCreationDialog()}
          retailer={Platform.AMAZON.label}
          setIsPageLoading={setIsPageLoading}
          existingPlatformProfileIds={profiles.map((profile) => profile.platformProfileId)}
        />
      )}
      {isPageLoading && (
        <Backdrop sx={{ color: '#fff', zIndex: zIndex.modal + 1 }} open={isPageLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Page>
  );
};

export default Profiles;
