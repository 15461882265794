export enum NotificationReason {
  General = 'General',
  WalmartCampaignWithoutBudget = 'WalmartCampaignWithoutBudget',
  WalmartCampaignMissingNextMonthBudget = 'WalmartCampaignMissingNextMonthBudget'
}

export interface NotificationTypeInformation {
  reason: NotificationReason;
  label: string;
  description: string;
}

export const NotificationTypeDetails: NotificationTypeInformation[] = [
  {
    reason: NotificationReason.WalmartCampaignWithoutBudget,
    label: 'WMT Campaigns Missing Budget',
    description: 'Receive notifications when a newly created Walmart campaign is missing a budget.'
  },
  {
    reason: NotificationReason.WalmartCampaignMissingNextMonthBudget,
    label: 'WMT Campaigns Missing Next Month Budget',
    description: 'Receive notifications when Walmart campaigns will run out of budget at the end of the month.'
  }
];

interface NotificationDetails {
  ProfileId: number;
  ProfileName: string;
  CampaignIds: number[];
  UserEmails: string[];
  CurrentMonth?: number;
  CurrentYear?: number;
  NextMonth?: number;
  NextYear?: number;
}

export interface Notification {
  id: number;
  userEmail: string;
  notificationId: number;
  notification: {
    id: number;
    message: string;
    details: string;
    createdAt: string;
    updatedAt: string;
  };
  reason: NotificationReason;
  isRead: boolean;
  isArchived: boolean;
  archivedDate?: string;
}

export interface NotificationItemProps {
  notification: Notification;
  selectedTab: number;
  handleNotificationItemClick: (notification: Notification) => void;
  handleArchive: (notificationId: number, shouldArchive: boolean) => void;
  handleToggleRead: (notificationId: number, currentReadStatus: boolean) => void;
  copiedIds: Record<number, boolean>;
  handleCopyCampaignId: (e: React.MouseEvent, campaignId: number) => void;
}

export interface NotificationPreferencesDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (preferencesByType: { [key in NotificationReason]?: number[] }) => void;
  amazonProfiles: ClientProfile[];
  walmartProfiles: ClientProfile[];
  samsProfiles: ClientProfile[];
  initialSubscribedByType: { [key in NotificationReason]?: number[] };
}

export interface CategorySectionProps {
  title: string;
  profiles: ClientProfile[];
  subscribedProfileIds: number[];
  areAllSelected: (profiles: ClientProfile[]) => boolean;
  handleToggleAll: (profiles: ClientProfile[]) => void;
  handleToggleProfile: (childClientId: number) => void;
}

export interface ClientProfile {
  clientName: string;
  childClientId: number;
}

export const parseNotificationDetails = (details: string): NotificationDetails | null => {
  try {
    return JSON.parse(details);
  } catch {
    return null;
  }
};

export const handleNotificationClick = (reason: NotificationReason, details: string, navigate: (path: string) => void): void => {
  switch (reason) {
    case NotificationReason.WalmartCampaignWithoutBudget:
    case NotificationReason.WalmartCampaignMissingNextMonthBudget:
      const parsedDetails = parseNotificationDetails(details);
      if (parsedDetails) {
        navigate(
          `/walmart/budgets?profileId=${parsedDetails.ProfileId}&highlightCampaigns=${parsedDetails.CampaignIds.join(',')}`
        );
      }
      break;
    case NotificationReason.General:
    default:
      break;
  }
};

export function formatDateForNotifications(dateString: string): string {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} ${diffInSeconds === 1 ? 'second' : 'seconds'} ago`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
  } else if (diffInHours < 48) {
    return 'Yesterday';
  } else {
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  }
}
