import React from 'react';
import {
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Box,
  Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ResponseObject } from '../../../../shared/utilities/fetch-utilities';

// Define our TypeScript interfaces
export enum EntityType {
  Campaign = 'Campaign',
  AdGroup = 'AdGroup',
  Keyword = 'Keyword',
  AdItem = 'AdItem',
  BidMultiplier = 'BidMultiplier',
  Placement = 'Placement',
  SbaProfile = 'SbaProfile'
}

export enum ResponseCode {
  Success = 'Success',
  Failure = 'Failure'
}

interface EntityError {
  entityType: EntityType;
  details: string;
  code: ResponseCode;
  entityId?: number;
}

interface CampaignBuilderResponse {
  campaign: any;
  sbaProfile?: any;
  sbaProfileImage?: any;
  adGroups: any[];
  adItems: any[];
  keywords: any[];
  errors: EntityError[];
  hasErrors: boolean;
}

interface ErrorReviewProps {
  campaignBuilderResponse: ResponseObject;
  handleBackButtonClick?: () => void;
  genericErrorText?: string;
}

export const ErrorReview: React.FC<ErrorReviewProps> = ({ campaignBuilderResponse, handleBackButtonClick, genericErrorText }) => {
  const errors = React.useMemo(() => campaignBuilderResponse?.body?.errors || {}, [campaignBuilderResponse]);

  // Group errors by entity type
  const groupedErrors = React.useMemo(() => {
    return errors.reduce(
      (acc: { [x: string]: any[] }, error: { entityType: any }) => {
        const key = error.entityType;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(error);
        return acc;
      },
      {} as Record<EntityType, EntityError[]>
    );
  }, [errors]);

  // Get error count for entity type
  const getErrorCount = (entityType: EntityType): number => {
    return groupedErrors[entityType]?.length || 0;
  };

  return (
    <Box sx={{ maxWidth: 800, margin: '0 auto' }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Uh oh! Something went wrong.
      </Typography>

      <Typography variant="body1" sx={{ mb: 3 }}>
        {genericErrorText}
      </Typography>

      {Object.entries(groupedErrors).map(([entityType, entityErrors]) => (
        <Accordion key={entityType} sx={{ mb: 1 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Typography sx={{ flexGrow: 1 }}>{entityType} Errors</Typography>
              <Chip label={getErrorCount(entityType as EntityType)} color="error" size="small" sx={{ ml: 2 }} />
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <List dense>
              {(entityErrors as EntityError[]).map((error, index) => (
                <ListItem key={index} sx={{ py: 1 }}>
                  <ListItemIcon>
                    <ErrorOutlineIcon color="error" />
                  </ListItemIcon>
                  <ListItemText primary={error.details} secondary={error.entityId ? `ID: ${error.entityId}` : undefined} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}

      <Box sx={{ mt: 3 }}>
        <Button startIcon={<KeyboardBackspaceIcon />} onClick={handleBackButtonClick} variant="outlined">
          Back to Campaign Builder
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorReview;
