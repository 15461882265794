import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { Box, Button, createTheme, styled, Tooltip, Typography } from '@mui/material';
import {
  DateRangePicker,
  PickerShortcutChangeImportance,
  PickersShortcutsItem,
  PickersShortcutsItemContext,
  SingleInputDateRangeField
} from '@mui/x-date-pickers-pro';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { set } from 'lodash';
import { useEffect, useState } from 'react';

interface GridDateRangePickerProps {
  initialDateRange?: DateRange<Dayjs>;
  handleDateRangeChange?: (dateRange: DateRange<Dayjs>) => void;
  handleCompareChange?: (compareDateRange: DateRange<Dayjs>) => void;
  handleOnSelectShortcut?: (newDateRange: DateRange<Dayjs>, newCompareDateRange: DateRange<Dayjs>) => void;
  maxDate?: Dayjs;
  enableCompare?: boolean;
  initialCompareDateRange?: DateRange<Dayjs>;
}

const defaultDateRange: DateRange<Dayjs> = [dayjs().subtract(7, 'day'), dayjs()];

export default function GridDateRangePicker({
  initialDateRange,
  handleDateRangeChange: onChange,
  handleCompareChange: onCompareChange = () => {},
  handleOnSelectShortcut: onShortcutSelect,
  maxDate = dayjs(),
  enableCompare = false,
  initialCompareDateRange
}: GridDateRangePickerProps) {
  const [value, setValue] = useState<DateRange<Dayjs>>(
    initialDateRange
      ? [
          initialDateRange[0] && initialDateRange[0].isAfter(maxDate) ? maxDate : initialDateRange[0],
          initialDateRange[1] && initialDateRange[1].isAfter(maxDate) ? maxDate : initialDateRange[1]
        ]
      : defaultDateRange
  );
  const [compareValue, setCompareValue] = useState<DateRange<Dayjs>>(initialCompareDateRange || [null, null]);
  const [isComparing, setIsComparing] = useState(false);
  const dateRangeShortcuts: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: 'Last 7 Days',
      getValue: () => {
        return [maxDate.subtract(6, 'day'), maxDate];
      },
      id: '7-days'
    },
    {
      label: 'Last 14 Days',
      getValue: () => {
        return [maxDate.subtract(13, 'day'), maxDate];
      },
      id: '14-days'
    },
    {
      label: 'Last 30 Days',
      getValue: () => {
        return [maxDate.subtract(29, 'day'), maxDate];
      },
      id: '30-days'
    },
    {
      label: 'Last Month',
      getValue: () => {
        return [maxDate.subtract(1, 'month').startOf('month'), maxDate.subtract(1, 'month').endOf('month')];
      },
      id: 'month'
    },
    {
      label: 'Last Year',
      getValue: () => {
        return [maxDate.subtract(1, 'year').startOf('year'), maxDate.subtract(1, 'year').endOf('year')];
      },
      id: 'year'
    },
    {
      label: 'Year To Date',
      getValue: () => {
        return [maxDate.startOf('year'), maxDate];
      },
      id: 'ytd'
    },
    {
      label: 'Month To Date',
      getValue: () => {
        return [maxDate.startOf('month'), maxDate];
      },
      id: 'mtd'
    }
  ];
  const compareDateRangeShortcuts: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      id: '7-days',
      label: 'Previous 7 Days',
      getValue: () => {
        const endDay = maxDate.subtract(7, 'day');
        const beginDay = endDay.subtract(6, 'day');
        return [beginDay, endDay];
      }
    },
    {
      id: '14-days',
      label: 'Previous 14 Days',
      getValue: () => {
        const endDay = maxDate.subtract(14, 'day');
        const beginDay = endDay.subtract(13, 'day');
        return [beginDay, endDay];
      }
    },
    {
      id: '30-days',
      label: 'Previous 30 Days',
      getValue: () => {
        const endDay = maxDate.subtract(30, 'day');
        const beginDay = endDay.subtract(29, 'day');
        return [beginDay, endDay];
      }
    },
    {
      id: 'month',
      label: 'Previous Month',
      getValue: () => {
        return [maxDate.subtract(2, 'month').startOf('month'), maxDate.subtract(2, 'month').endOf('month')];
      }
    },
    {
      id: 'year',
      label: 'Previous Year',
      getValue: () => {
        return [maxDate.subtract(2, 'year').startOf('year'), maxDate.subtract(2, 'year').endOf('year')];
      }
    },
    {
      id: 'ytd',
      label: 'Previous Year To Date',
      getValue: () => {
        return [maxDate.subtract(1, 'year').startOf('year'), maxDate.subtract(1, 'year')];
      }
    },
    {
      id: 'mtd',
      label: 'Previous Month To Date',
      getValue: () => {
        return [maxDate.subtract(1, 'month').startOf('month'), maxDate.subtract(1, 'month')];
      }
    }
  ];

  useEffect(() => {
    setIsComparing(!!(compareValue && compareValue[0] && compareValue[1]));
  }, [compareValue]);

  const isCurrentDateRangeSelected = value[0] !== null && value[1] !== null;

  const previousMaxDate = value[0] ? value[0].subtract(1, 'day') : undefined;

  const handleDateRangeChange = (newRange: DateRange<Dayjs>) => {
    if (newRange[0] && newRange[1]) {
      setValue(newRange);
      onChange?.(newRange);
      setCompareValue([null, null]);
      onCompareChange([null, null]);
    }
  };

  const handleOnSelectShortcut = (
    newValue: DateRange<Dayjs>,
    importance: PickerShortcutChangeImportance,
    shortcut: PickersShortcutsItemContext
  ) => {
    // When a shortcut is selected for the main date range,
    // update the comparison range based on the corresponding previous period
    if (isComparing && shortcut.id) {
      const matchingShortcut = compareDateRangeShortcuts.find((s) => s.id === shortcut.id);
      if (matchingShortcut) {
        const newCompareRange = matchingShortcut.getValue({ isValid: () => true });
        setValue(newValue);
        setCompareValue(newCompareRange);
        onShortcutSelect?.(newValue, newCompareRange);
        return;
      }
    }

    setValue(newValue);
    onChange?.(newValue);
  };

  // Handle comparison date range changes
  const handleCompareDateRangeChange = (newRange: DateRange<Dayjs>) => {
    if (isComparing && newRange[0] && newRange[1]) {
      setCompareValue(newRange);
      onCompareChange(newRange);
    }
  };

  // Handle compare toggle
  const handleCompareToggle = () => {
    if (isComparing) {
      //handle in parent, i.e. update grid
      onCompareChange([null, null]);
      setIsComparing(false);
      return;
    }

    setIsComparing(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: { xs: 'flex-start', md: 'center' },
          gap: 2,
          width: '100%',
          p: 1
        }}
      >
        {/* Comparison date range picker - Only shown when comparison is enabled */}
        {isComparing && enableCompare && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              width: { xs: '100%', md: 'auto' }
            }}
          >
            <DateRangePicker
              slots={{ field: SingleInputDateRangeField }}
              slotProps={{
                textField: {
                  size: 'small',
                  fullWidth: true,
                  label: 'Previous',
                  sx: {
                    '& .MuiInputLabel-root': {
                      color: 'secondary.main',
                      fontWeight: 'bold'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'secondary.main'
                    },
                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'secondary.dark'
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'secondary.main',
                      borderWidth: '2px'
                    }
                  }
                },
                shortcuts: {
                  items: compareDateRangeShortcuts
                }
              }}
              format="MM/DD/YY"
              calendars={2}
              value={compareValue}
              onAccept={handleCompareDateRangeChange}
              maxDate={previousMaxDate || maxDate}
            />
          </Box>
        )}

        {/* Compare toggle button */}
        {enableCompare && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={isComparing ? 'Click to reset' : ''}>
              <Button
                variant={isComparing ? 'outlined' : 'contained'}
                color={isComparing ? 'secondary' : 'primary'}
                disabled={!isCurrentDateRangeSelected}
                onClick={handleCompareToggle}
                startIcon={<CompareArrowsIcon />}
              >
                {isComparing ? 'Comparing' : 'Compare'}
              </Button>
            </Tooltip>
          </Box>
        )}

        {/* Main date range picker */}
        <Tooltip title={`Max Date: ${maxDate.format('MM/DD/YY')}`}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              width: { xs: '100%', md: 'auto' }
            }}
          >
            <DateRangePicker
              slots={{ field: SingleInputDateRangeField }}
              slotProps={{
                textField: {
                  size: 'small',
                  fullWidth: false,
                  label: isComparing ? 'Current' : 'Date Range',
                  sx: {
                    '& .MuiInputLabel-root': {
                      fontWeight: isComparing ? 'bold' : 'normal',
                      color: isComparing ? 'primary.main' : 'inherit'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: isComparing ? 'primary.main' : 'inherit'
                    }
                  }
                },
                shortcuts: {
                  items: dateRangeShortcuts,
                  changeImportance: 'accept'
                },
                layout: {
                  onSelectShortcut: handleOnSelectShortcut
                }
              }}
              format="MM/DD/YY"
              calendars={2}
              value={value}
              onAccept={handleDateRangeChange}
              maxDate={maxDate}
            />
          </Box>
        </Tooltip>
      </Box>
    </LocalizationProvider>
  );
}
