import { useState } from 'react';
import type { FC } from 'react';
import { Card, CardContent, Typography, Collapse } from '@mui/material';
import { CampaignBuilderStepLabelButton } from '../campaigns/campaign-builder-form/step-label-button';
import AddKeywords from '../../../shared/components/add-keywords';

interface TargetingSettingsStepProps {
  keywords: any;
  setKeywords: (keywords: any) => void;
  stepNumber: number;
  selectedCampaignType?: string;
}

export const AddKeywordsStep: FC<TargetingSettingsStepProps> = (props) => {
  const { keywords, setKeywords, stepNumber, selectedCampaignType, ...other } = props;

  const [showStep, setShowStep] = useState<boolean>(true);

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  const getMinBid = () => {
    if (selectedCampaignType?.toLowerCase() === 'sba') {
      return 1;
    }

    return 0.3;
  };

  return (
    <div {...other}>
      <CampaignBuilderStepLabelButton
        showStep={showStep}
        handleStepButtonClick={handleStepButtonClick}
        stepNumber={stepNumber}
        stepLabel="Add Keywords"
      />
      <Collapse in={showStep}>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Add Keywords (Optional)
            </Typography>
            <Typography variant="caption" gutterBottom>
              Enter a bid, select match types, and enter keywords. Once finished click create ads to create your new ads and
              keywords.
            </Typography>
            <AddKeywords keywords={keywords} setKeywords={setKeywords} minBid={getMinBid()} />
          </CardContent>
        </Card>
      </Collapse>
    </div>
  );
};
