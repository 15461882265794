import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo } from 'react';
import { REVIEW_STATUS_OPTIONS, ITEM_STATUS_OPTIONS } from '../../../types/walmart-sams-club/review';
import NearMeDisabledIcon from '@mui/icons-material/NearMeDisabled';

const ReviewStyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: 'left',
  '& .icon': {
    color: 'inherit'
  },
  '&.Unsubmitted': {
    color: theme.palette.action.disabled,
    border: `1px solid ${theme.palette.action.disabled}`
  },
  '&.Pending': {
    color: theme.palette.secondary.dark,
    border: `1px solid ${theme.palette.secondary.main}`
  },
  '&.InProgress': {
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.main}`
  },
  '&.Complete': {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`
  },
  '&.UnderReview': {
    color: theme.palette.warning.dark,
    border: `1px solid ${theme.palette.warning.main}`
  },
  '&.Cancelled': {
    color: theme.palette.action.disabled,
    border: `1px solid ${theme.palette.action.disabled}`
  },
  '&.Approved': {
    color: theme.palette.success.main,
    border: `1px solid ${theme.palette.success.main}`
  },
  '&.Rejected': {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`
  }
}));

export const ReviewStatusChip = memo((props: any) => {
  const { status, sx } = props;

  let statusObject = REVIEW_STATUS_OPTIONS.find((option) => option?.value?.toLowerCase() === status?.toLowerCase());

  if (!statusObject) {
    statusObject = {
      label: 'Unsubmitted',
      value: 'unsubmitted',
      icon: NearMeDisabledIcon
    };
  }

  const Icon = statusObject.icon;

  return (
    <ReviewStyledChip
      className={!status ? 'Unsubmitted' : status}
      icon={<Icon className="icon" />}
      size="small"
      label={statusObject.label}
      sx={sx}
      variant="outlined"
    />
  );
});

export const IndividualItemStatusChip = memo((props: any) => {
  const { status, sx, isUnderReview } = props;

  let statusObject = ITEM_STATUS_OPTIONS.find((option) => option.value === status?.toLowerCase());

  if (!statusObject) {
    return null;
  }

  if (statusObject.value === 'pending' && isUnderReview) {
    statusObject = {
      label: 'Under Review',
      value: 'underReview',
      icon: statusObject.icon
    };
  }

  const Icon = statusObject.icon;

  return (
    <ReviewStyledChip
      className={statusObject.value === 'underReview' ? 'UnderReview' : status}
      icon={<Icon className="icon" />}
      size="small"
      label={statusObject.label}
      sx={sx}
      variant="outlined"
    />
  );
});
