import { API_AUTH_SCOPE, API_ROOT } from '../../config';
import { AdItemCreateDto, UpdateAdRequest } from '../../walmart/types/ad';
import { Comparator, Pageable, defaultPageable } from '../types/pageable';
import { PlatformValue } from '../types/platforms';
import { UpdateCampaignRequest } from '../types/walmart-sams-club/campaign';
import { UpdateKeywordRequest, UpdateNegativeKeywordRequest } from '../types/walmart-sams-club/keyword';
import { ResponseObject, executeGetRequest, executePostRequest, executePutRequest } from '../utilities/fetch-utilities';
import { useAuth } from './use-auth';
import { useCurrentRetailerPlatform } from './use-current-retailer-platform';
import type {
  AdBuilderRequest,
  MultiItemAdGroupCampaignBuilderRequestDto,
  CampaignBuilderRequest as WalmartCampaignBuilderRequest
} from '../../walmart/types/campaign-builder-request';
import { MultiItemAdGroupAdBuilderRequest, SingleItemAdGroupAdBuilderRequest } from '../../walmart/types/ad-builder-request';
import { AdGroupUpdateRequest } from '../../walmart/types/ad-group';

export const buildPageableParams = (params: any, pageable: Pageable | null | undefined): string => {
  let result = '';

  result += serializeParams(params);
  result += serializePageableParams(!!pageable ? pageable : defaultPageable);

  return result;
};

export function serializePageableParams(params: Pageable): string {
  let result = '';

  if (params.limit) {
    result += `&limit=${params.limit}`;
  }

  if (params.offset) {
    result += `&offset=${params.offset}`;
  }

  if (params.filters) {
    params.filters?.map((filter) => {
      if (filter) {
        if (filter.comparator === 'isAnyOf' && Array.isArray(filter.value)) {
          filter.value = filter.value.join('|');
        }
        result += `&filters=${encodeURIComponent(JSON.stringify(filter))}`;
      }
    });
  }

  if (params.sorts) {
    params.sorts?.map((sort) => {
      if (sort) {
        result += `&sorts=${encodeURIComponent(JSON.stringify(sort))}`;
      }
    });
  }

  return result;
}

export const serializeParams = (params: any): string => {
  return Object.keys(params)
    .map((key) => (params[key] ? `${key}=${params[key]}` : ''))
    .join('&');
};

export interface BreadCrumbParams {
  [key: string]: string | number | boolean | null | undefined;
}

export interface FetchEntityParams {
  beginDate?: string | null;
  endDate?: string | null;
  shouldIncludeChartMetrics?: boolean;
}

export const buildQueryString = (breadcrumbParams: BreadCrumbParams[], params: FetchEntityParams, pageable: Pageable): string => {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value != null) {
      queryParams.append(key, value.toString());
    }
  });

  if (pageable.limit) queryParams.append('limit', pageable.limit.toString());
  if (pageable.offset) queryParams.append('offset', pageable.offset.toString());

  pageable.filters?.forEach((filter) => {
    if (filter.comparator === 'isAnyOf' && Array.isArray(filter.value)) {
      const combinedFilter = filter.value.join('|');
      queryParams.append('filters', JSON.stringify({ ...filter, value: combinedFilter }));
    } else {
      queryParams.append('filters', JSON.stringify(filter));
    }
  });

  // inject breadcrumb params into filters
  breadcrumbParams.forEach((breadcrumbParam) => {
    //check if value is null or undefined
    if (breadcrumbParam[Object.keys(breadcrumbParam)[0]] == null) {
      return;
    }

    const filter = {
      column: Object.keys(breadcrumbParam)[0],
      comparator: Comparator.Equals,
      value: breadcrumbParam[Object.keys(breadcrumbParam)[0]]
    };

    queryParams.append('filters', JSON.stringify(filter));
  });

  pageable.sorts?.forEach((sort) => {
    queryParams.append('sorts', JSON.stringify(sort));
  });

  return queryParams.toString();
};

export const useAdsApi = (retailerPlatform?: PlatformValue) => {
  const { acquireToken } = useAuth();
  const currentRetailerPlatform = useCurrentRetailerPlatform(retailerPlatform);

  if (!currentRetailerPlatform) {
    return {} as {
      [functionName: string]: () => Promise<ResponseObject>;
    };
  }

  const getCampaigns = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable?: Pageable,
    beginDate?: string | null,
    endDate?: string | null,
    shouldIncludeChartMetrics?: boolean
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(
      breadcrumbParams,
      { beginDate, endDate, shouldIncludeChartMetrics } as FetchEntityParams,
      pageable || defaultPageable
    );

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignTotals = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable?: Pageable,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(
      breadcrumbParams,
      { beginDate, endDate } as FetchEntityParams,
      pageable || defaultPageable
    );

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns/gettotals?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaign = async (profileId: number | null, campaignId: number | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = serializeParams({
      profileId
    });

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns/${campaignId}${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const updateCampaign = async (campaign: UpdateCampaignRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns/${campaign.campaignId}`,
      authToken: authResponse.accessToken,
      body: campaign
    });
  };

  const getAds = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable: Pageable | null,
    beginDate: string | null,
    endDate: string | null,
    shouldIncludeChartMetrics?: boolean
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(
      breadcrumbParams,
      { beginDate, endDate, shouldIncludeChartMetrics } as FetchEntityParams,
      pageable || defaultPageable
    );

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/ads?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const updateAd = async (profileId: number | null, ad: UpdateAdRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/ads?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: [ad]
    });
  };

  const getKeywords = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable: Pageable | null,
    beginDate: string | null,
    endDate: string | null,
    shouldIncludeChartMetrics?: boolean
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(
      breadcrumbParams,
      { beginDate, endDate, shouldIncludeChartMetrics } as FetchEntityParams,
      pageable || defaultPageable
    );

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywords?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const getSuggestedKeywords = async (adGroupId: number | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = serializeParams({
      adGroupId
    });

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/passThrough/getKeywordSuggestion${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getKeywordTotals = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable: Pageable | null,
    beginDate: string | null,
    endDate: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(
      breadcrumbParams,
      { beginDate, endDate } as FetchEntityParams,
      pageable || defaultPageable
    );

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywords/getTotals?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const updateKeyword = async (profileId: number | null, keyword: UpdateKeywordRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywords?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: [keyword]
    });
  };

  const getNegativeKeywords = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable: Pageable | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(breadcrumbParams, {} as FetchEntityParams, pageable || defaultPageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/negativeKeywords?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const updateNegativeKeywords = async (negativeKeywords: UpdateNegativeKeywordRequest[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/negativeKeywords`,
      authToken: authResponse.accessToken,
      body: negativeKeywords
    });
  };

  const walmartBuildMultiItemAdGroupCampaign = async (
    profileId: number | null,
    campaignBuilderRequest: MultiItemAdGroupCampaignBuilderRequestDto
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns/buildMultiItemAdGroupCampaign?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: campaignBuilderRequest
    });
  };

  const walmartBuildSingleItemAdGroupAds = async (
    profileId: number | null,
    campaignId: number | null,
    adBuilderRequest: SingleItemAdGroupAdBuilderRequest
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/ads/BuildAds?profileId=${profileId}&campaignId=${campaignId}`,
      authToken: authResponse.accessToken,
      body: adBuilderRequest
    });
  };

  const walmartBuildMultiItemAdGroupAds = async (
    profileId: number | null,
    campaignId: number | null,
    adBuilderRequest: MultiItemAdGroupAdBuilderRequest
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/ads/BuildMultiItemAdGroupAds?profileId=${profileId}&campaignId=${campaignId}`,
      authToken: authResponse.accessToken,
      body: adBuilderRequest
    });
  };

  const getAdGroups = async (
    breadcrumbParams: BreadCrumbParams[] = [],
    pageable: Pageable | null,
    beginDate: string | null,
    endDate: string | null,
    shouldIncludeChartMetrics: boolean = false
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const queryString = buildQueryString(
      breadcrumbParams,
      { beginDate, endDate, shouldIncludeChartMetrics } as FetchEntityParams,
      pageable || defaultPageable
    );

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/adGroups/getAllWithMetrics?${queryString}`,
      authToken: authResponse.accessToken
    });
  };

  const createAdGroups = async (adGroups: any[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/adGroups`,
      authToken: authResponse.accessToken,
      body: adGroups
    });
  };

  const updateAdGroups = async (profileId: number | null, adGroups: any[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/adGroups?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: adGroups
    });
  };

  const updateAdGroup = async (adGroupUpdateRequest: AdGroupUpdateRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/adGroups/${adGroupUpdateRequest.adGroupId}`,
      authToken: authResponse.accessToken,
      body: adGroupUpdateRequest
    });
  };

  const createBulkAdItems = async (profileId: number | null, adItems: AdItemCreateDto[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/ads/CreateAdItemsInBulk`,
      authToken: authResponse.accessToken,
      body: adItems
    });
  };

  return {
    getCampaign,
    getCampaigns,
    getCampaignTotals,
    updateCampaign,
    getAds,
    updateAd,
    getKeywords,
    getSuggestedKeywords,
    getKeywordTotals,
    updateKeyword,
    getNegativeKeywords,
    updateNegativeKeywords,
    walmartBuildMultiItemAdGroupCampaign,
    walmartBuildSingleItemAdGroupAds,
    walmartBuildMultiItemAdGroupAds,
    getAdGroups,
    createAdGroups,
    updateAdGroups,
    updateAdGroup,
    createBulkAdItems
  };
};

export default useAdsApi;
