import { API_AUTH_SCOPE, API_ROOT } from '../../config';
import { PlacementMultiplierRequestObject, PlatformMultiplierRequestObject } from '../../walmart/types/bid-multipliers';
import { CampaignBudget, UpdateProfileMonthlyBudgetRequest } from '../types/budget';
import { Campaign, UpdateCampaignRequest } from '../types/walmart-sams-club/campaign';
import type {
  AdBuilderRequest,
  CampaignBuilderRequest as WalmartCampaignBuilderRequest
} from '../../walmart/types/campaign-builder-request';
import type { CampaignBuilderRequest as SamsClubCampaignBuilderRequest } from '../../sams-club/types/campaign-builder-request';
import { KeywordHarvestingSettings } from '../../walmart/types/keyword-harvesting';
import { AdGroupMediaCreateDto, AdGroupMediaUpdateDto, ClosedCaptioningUpdateDto } from '../types/walmart-sams-club/media';
import { PlacementsRequestObject } from '../../walmart/types/placement';
import { CancelSbReviewRequest, SbReviewRequest } from '../types/walmart-sams-club/review';
import { SBProfileBuilderRequest, SBProfileUpdateRequest } from '../types/walmart-sams-club/sb-profile';
import { UpdateAdItemBidsDto, UpdateKeywordBidsDto } from '../types/update-bids';
import { UpdateAdRequest } from '../../walmart/types/ad';
import { Pageable } from '../types/pageable';
import { PlatformValue } from '../types/platforms';
import {
  ResponseObject,
  executeDeleteRequest,
  executeGetRequest,
  executePostRequest,
  executePostWithFormData,
  executePutRequest
} from '../utilities/fetch-utilities';
import { buildPageableParams, serializeParams } from '../utilities/url-utilities';
import { useAuth } from './use-auth';
import { useCurrentRetailerPlatform } from './use-current-retailer-platform';
import {
  CreateKeywordRequest,
  CreateNegativeKeywordRequest,
  UpdateKeywordRequest,
  UpdateNegativeKeywordRequest
} from '../types/walmart-sams-club/keyword';
import { UpdateProfileRequest } from '../types/profile';
import { CampaignDailyBudgetOverride } from '../types/walmart-sams-club/campaign-daily-budget-override';
import { WalmartAndSamsProfileCreationPayload } from '../components/profile-creation-modal/profile-creation-objects';

export const useAdsApi = (retailerPlatform?: PlatformValue) => {
  const { acquireToken } = useAuth();
  const currentRetailerPlatform = useCurrentRetailerPlatform(retailerPlatform);

  if (!currentRetailerPlatform) {
    return {} as {
      [functionName: string]: () => Promise<ResponseObject>;
    };
  }

  const getProfile = async (profileId: number | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/profiles/${profileId}`,
      authToken: authResponse.accessToken
    });
  };

  const getProfiles = async (
    pageable?: Pageable,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/profiles${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaigns = async (
    pageable?: Pageable,
    beginDate?: string | null,
    endDate?: string | null,
    shouldIncludeChartMetrics?: boolean
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate, shouldIncludeChartMetrics }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignTotals = async (
    pageable?: Pageable,
    beginDate?: string | null,
    endDate?: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns/gettotals${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaign = async (profileId: number | null, campaignId: number | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = serializeParams({
      profileId
    });

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns/${campaignId}${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getDaypartingViewStats = async (campaignId: number | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns/${campaignId}/getDaypartingViewStats`,
      authToken: authResponse.accessToken
    });
  };

  const getHourlyStatsChartViewData = async (campaignId: number | null, daysBack: number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns/${campaignId}/getHourlyStatsChartViewData?daysBack=${daysBack}`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignMonthlyPacingByProfile = async (profileId: number | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns/GetCampaignMonthlyPacingByProfile/${profileId}`,
      authToken: authResponse.accessToken
    });
  };

  const getAdGroups = async (
    profileId: number | null,
    campaignId: number | null,
    beginDate: string | null,
    endDate: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = serializeParams({
      profileId,
      campaignId,
      beginDate,
      endDate
    });

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/adGroups${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getAdGroup = async (
    profileId: number | null,
    campaignId: number | null,
    adGroupId: number | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = serializeParams({
      profileId,
      campaignId
    });

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/adGroups/${adGroupId}${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getAds = async (
    pageable: Pageable | null,
    beginDate?: string | null,
    endDate?: string | null,
    shouldIncludeChartMetrics?: boolean
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate, shouldIncludeChartMetrics }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/ads${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getKeywords = async (
    pageable: Pageable | null,
    beginDate?: string | null,
    endDate?: string | null,
    shouldIncludeChartMetrics?: boolean
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate, shouldIncludeChartMetrics }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywords${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getSuggestedKeywords = async (adGroupId: number | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = serializeParams({
      adGroupId
    });

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/passThrough/getKeywordSuggestion${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getKeywordTotals = async (
    pageable: Pageable | null,
    beginDate: string | null,
    endDate: string | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({ beginDate, endDate }, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywords/gettotals${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const getNegativeKeywords = async (pageable: Pageable | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams({}, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/negativeKeywords${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const updateCampaign = async (campaign: UpdateCampaignRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns/${campaign.campaignId}`,
      authToken: authResponse.accessToken,
      body: campaign
    });
  };

  const updateCampaigns = async (profileId: number | null, campaigns: UpdateCampaignRequest[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: campaigns
    });
  };

  const setCampaignIsActiveInVector = async (campaignId: number, isEnabled: boolean): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns/${campaignId}/userIsEnabled?isEnabled=${isEnabled}`,
      authToken: authResponse.accessToken
    });
  };

  const bulkSetCampaignIsActiveInVector = async (campaignIds: number[], isEnabled: boolean): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns/userIsEnabled?isEnabled=${isEnabled}`,
      authToken: authResponse.accessToken,
      body: campaignIds
    });
  };

  const updateProfile = async (profile: UpdateProfileRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/profiles/${profile.profileId}`,
      authToken: authResponse.accessToken,
      body: profile
    });
  };

  const updateKeyword = async (profileId: number | null, keyword: UpdateKeywordRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywords?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: [keyword]
    });
  };

  const updateNegativeKeywords = async (negativeKeywords: UpdateNegativeKeywordRequest[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/negativeKeywords`,
      authToken: authResponse.accessToken,
      body: negativeKeywords
    });
  };

  const updateKeywords = async (profileId: number | null, keywords: UpdateKeywordRequest[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywords?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: keywords
    });
  };

  const updateKeywordBids = async (updateKeywordBidsDto: UpdateKeywordBidsDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywords/updateBids`,
      authToken: authResponse.accessToken,
      body: updateKeywordBidsDto
    });
  };

  const updateAd = async (profileId: number | null, ad: UpdateAdRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/ads?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: [ad]
    });
  };

  const updateAds = async (profileId: number | null, ads: UpdateAdRequest[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/ads?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: ads
    });
  };

  const updateAdItemBids = async (updateAdItemBidsDto: UpdateAdItemBidsDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/ads/updateBids`,
      authToken: authResponse.accessToken,
      body: updateAdItemBidsDto
    });
  };

  const createCampaign = async (profileId: number | null, campaign: Campaign): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: campaign
    });
  };

  const createKeywords = async (profileId: number | null, keywords: CreateKeywordRequest[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywords?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: keywords
    });
  };

  const createNegativeKeywords = async (negativeKeywords: CreateNegativeKeywordRequest[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/negativekeywords`,
      authToken: authResponse.accessToken,
      body: negativeKeywords
    });
  };

  const samsClubItemSearch = async (
    profileId: number | null,
    itemIds: string[],
    campaignId?: number | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = serializeParams({
      profileId,
      ...(campaignId && { campaignId })
    });

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/ads/itemSearch${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken,
      body: itemIds
    });
  };

  const walmartItemSearch = async (
    profileId: number | null,
    itemIds: string[],
    campaignId?: number | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = serializeParams({
      profileId,
      ...(campaignId && { campaignId })
    });

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/ads/itemSearch${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken,
      body: itemIds.map((itemId: string) => Number(itemId))
    });
  };

  const walmartBuildCampaign = async (
    profileId: number | null,
    campaignBuilderRequest: WalmartCampaignBuilderRequest
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns/buildCampaign?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: campaignBuilderRequest
    });
  };

  const samsClubBuildCampaign = async (
    profileId: number | null,
    campaignBuilderRequest: SamsClubCampaignBuilderRequest
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaigns/buildCampaign?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: campaignBuilderRequest
    });
  };

  const walmartSamsClubBuildAds = async (
    profileId: number | null,
    campaignId: number | null,
    adBuilderRequest: AdBuilderRequest
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/ads/buildAds?profileId=${profileId}&campaignId=${campaignId}`,
      authToken: authResponse.accessToken,
      body: adBuilderRequest
    });
  };

  const walmartCreateSbaReview = async (profileId: number | null, body: SbReviewRequest): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/sbaReviews?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: [body]
    });
  };

  const getSbaProfiles = async (
    profileId: number | null,
    campaignId: number | null,
    adGroupId: number | null = null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    let params = serializeParams({
      ...(profileId && { profileId }),
      ...(campaignId && { campaignId }),
      ...(adGroupId && { adGroupId })
    });

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/sbaProfiles${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const buildSbaProfile = async (
    profileId: number | null,
    campaignId: number | null,
    sbaProfileRequest: SBProfileBuilderRequest
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/sbaProfiles?profileId=${profileId}&campaignId=${campaignId}`,
      authToken: authResponse.accessToken,
      body: sbaProfileRequest
    });
  };

  const updateSbaProfile = async (
    profileId: number | null,
    sbProfileRequest: SBProfileUpdateRequest
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/sbaProfiles?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: sbProfileRequest
    });
  };

  const getHourlySchedule = async (profileId: number | null, campaignId: number | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/hourlySchedule?profileId=${profileId}&campaignId=${campaignId}`,
      authToken: authResponse.accessToken
    });
  };

  const createHourlySchedule = async (
    profileId: number | null,
    campaignId: number | null,
    dayPartingSchedule: any
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/hourlySchedule?profileId=${profileId}&campaignId=${campaignId}`,
      authToken: authResponse.accessToken,
      body: dayPartingSchedule
    });
  };

  const updateHourlySchedule = async (
    profileId: number | null,
    campaignId: number | null,
    dayPartingSchedule: any
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/hourlySchedule?profileId=${profileId}&campaignId=${campaignId}`,
      authToken: authResponse.accessToken,
      body: dayPartingSchedule
    });
  };

  const deleteHourlySchedule = async (profileId: number | null, campaignId: number | null): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeDeleteRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/hourlySchedule?profileId=${profileId}&campaignId=${campaignId}`,
      authToken: authResponse.accessToken
    });
  };

  const getSbaReviews = async (
    profileId: number | null,
    campaignId: number | null,
    adGroupId: number | null
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/sbaReviews?profileId=${profileId}&campaignId=${campaignId}${
        adGroupId ? `&adGroupId=${adGroupId}` : ''
      }`,
      authToken: authResponse.accessToken
    });
  };

  const cancelSbaReviews = async (
    profileId: number | null,
    cancelSbaReviewRequest: CancelSbReviewRequest
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/sbaReviews/cancel?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: [cancelSbaReviewRequest]
    });
  };

  const updateProfileMonthlyBudgets = async (
    updateProfileMonthlyBudgetRequest: UpdateProfileMonthlyBudgetRequest
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/budgets/updateProfileMonthlyBudgets`,
      authToken: authResponse.accessToken,
      body: updateProfileMonthlyBudgetRequest
    });
  };

  const getCampaignMonthlyBudgets = async (profileId: number, year: number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/budgets/getCampaignMonthlyBudgets?profileId=${profileId}&year=${year}`,
      authToken: authResponse.accessToken
    });
  };

  const updateCampaignAndProfileMonthlyBudgets = async (
    profileBudgets: UpdateProfileMonthlyBudgetRequest,
    campaignBudgets: CampaignBudget[]
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/budgets/updateCampaignAndProfileMonthlyBudgets`,
      authToken: authResponse.accessToken,
      body: { campaignBudgets, profileBudgets }
    });
  };

  const updateCampaignMonthlyBudgets = async (profileId: number, campaignBudgets: CampaignBudget[]): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/budgets/updateCampaignMonthlyBudgets?profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: campaignBudgets
    });
  };

  const getBudgetsViewData = async (profileId: number, year: number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/budgets/getBudgetsViewData?profileId=${profileId}&year=${year}`,
      authToken: authResponse.accessToken
    });
  };

  const isValidMonthlyBudget = async (campaignId: number, month: number, year: number, monthlyBudget: number) => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/budgets/isValidMonthlyBudget?campaignId=${campaignId}&month=${month}&year=${year}&monthlyBudget=${monthlyBudget}`,
      authToken: authResponse.accessToken
    });
  };

  const getPlacements = async (campaignId: number, profileId: number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/placement?campaignId=${campaignId}&profileId=${profileId}`,
      authToken: authResponse.accessToken
    });
  };

  const createPlacements = async (
    campaignId: number,
    profileId: number,
    placementsRequestObject: PlacementsRequestObject
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/placement?campaignId=${campaignId}&profileId=${profileId}`,
      authToken: authResponse.accessToken,
      body: placementsRequestObject
    });
  };

  const getPlacementMultipliers = async (campaignId: number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/multipliers/placement?campaignId=${campaignId}`,
      authToken: authResponse.accessToken
    });
  };

  const createPlacementMultipliers = async (
    multiplierRequestObject: PlacementMultiplierRequestObject
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/multipliers/placement`,
      authToken: authResponse.accessToken,
      body: multiplierRequestObject
    });
  };

  const updatePlacementMultipliers = async (
    multiplierRequestObject: PlacementMultiplierRequestObject[]
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/multipliers/placement`,
      authToken: authResponse.accessToken,
      body: multiplierRequestObject
    });
  };

  const getPlatformMultipliers = async (campaignId: number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/multipliers/platform?campaignId=${campaignId}`,
      authToken: authResponse.accessToken
    });
  };

  const createPlatformMultipliers = async (multiplierRequestObject: PlatformMultiplierRequestObject): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/multipliers/platform`,
      authToken: authResponse.accessToken,
      body: multiplierRequestObject
    });
  };

  const updatePlatformMultipliers = async (
    multiplierRequestObject: PlatformMultiplierRequestObject[]
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/multipliers/platform`,
      authToken: authResponse.accessToken,
      body: multiplierRequestObject
    });
  };

  const getKeywordHarvestingSettingsForCampaign = async (campaignId: number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywordHarvesting/settings/${campaignId}`,
      authToken: authResponse.accessToken
    });
  };

  const deleteKeywordHarvestingSettingsForCampaign = async (campaignId: number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeDeleteRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywordHarvesting/settings?campaignId=${campaignId}`,
      authToken: authResponse.accessToken
    });
  };

  const getPageableKeywordHarvestingSettings = async (pageable: Pageable): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams(null, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywordHarvesting/settings${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const updateKeywordHarvestingSettings = async (settings: KeywordHarvestingSettings): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywordHarvesting/settings`,
      authToken: authResponse.accessToken,
      body: settings
    });
  };

  const createKeywordHarvestingSettings = async (settings: KeywordHarvestingSettings): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywordHarvesting/settings`,
      authToken: authResponse.accessToken,
      body: settings
    });
  };

  const getPreviewOfHarvestedKeywords = async (campaignId: number, pageable?: Pageable): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = buildPageableParams(campaignId, pageable);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywordHarvesting/preview/${campaignId}${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const applyHarvestedKeywords = async (campaignId: number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/keywordHarvesting/apply/${campaignId}`,
      authToken: authResponse.accessToken
    });
  };

  const getAdGroupMedia = async (campaignId: number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/media/adGroup?campaignId=${campaignId}`,
      authToken: authResponse.accessToken
    });
  };

  const createAdGroupMedia = async (createAdGroupMediaDto: AdGroupMediaCreateDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/media/adGroup`,
      authToken: authResponse.accessToken,
      body: createAdGroupMediaDto
    });
  };

  const updateAdGroupMedia = async (updateAdGroupMediaDto: AdGroupMediaUpdateDto): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/media/adGroup`,
      authToken: authResponse.accessToken,
      body: updateAdGroupMediaDto
    });
  };

  const getMedia = async (profileId: number, mediaId?: number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    const params = serializeParams({
      profileId,
      ...(mediaId && { mediaId })
    });

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/media${params ? `?${params}` : ''}`,
      authToken: authResponse.accessToken
    });
  };

  const createMedia = async (media: FormData): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostWithFormData({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/media`,
      authToken: authResponse.accessToken,
      body: media
    });
  };

  const updateClosedCaptioning = async (updateClosedCaptioningDto: FormData): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostWithFormData({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/media/cc`,
      authToken: authResponse.accessToken,
      body: updateClosedCaptioningDto
    });
  };

  const getSearchTrends = async (): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/passThrough/getInsights`,
      authToken: authResponse.accessToken
    });
  };

  const getSearchTrendsUrl = async (): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/passThrough/getInsightsUrl`,
      authToken: authResponse.accessToken
    });
  };

  const getCampaignDailyBudgetOverrides = async (campaignId: number): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeGetRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaignDailyBudgetOverride/${campaignId}`,
      authToken: authResponse.accessToken
    });
  };

  const updateCampaignDailyBudgetOverride = async (
    campaignDailyBudgetOverride: CampaignDailyBudgetOverride
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePutRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaignDailyBudgetOverride`,
      authToken: authResponse.accessToken,
      body: campaignDailyBudgetOverride
    });
  };

  const createCampaignDailyBudgetOverride = async (
    campaignDailyBudgetOverride: CampaignDailyBudgetOverride
  ): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaignDailyBudgetOverride`,
      authToken: authResponse.accessToken,
      body: campaignDailyBudgetOverride
    });
  };

  const deleteCampaignDailyBudgetOverride = async (dailyBudgetOverrideId: string): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executeDeleteRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/campaignDailyBudgetOverride/${dailyBudgetOverrideId}`,
      authToken: authResponse.accessToken
    });
  };

  const createWalmartAndSamsProfile = async (profile: WalmartAndSamsProfileCreationPayload): Promise<ResponseObject> => {
    const authResponse = await acquireToken([API_AUTH_SCOPE]);

    return await executePostRequest({
      endpoint: `${API_ROOT}/${currentRetailerPlatform}/Profiles`,
      authToken: authResponse.accessToken,
      body: profile
    });
  };

  return {
    getProfile,
    updateProfile,
    getProfiles,
    getCampaign,
    getCampaigns,
    getCampaignTotals,
    getAdGroup,
    getAdGroups,
    getAds,
    getKeywords,
    getSuggestedKeywords,
    getKeywordTotals,
    getNegativeKeywords,
    getDaypartingViewStats,
    getHourlyStatsChartViewData,
    getCampaignMonthlyPacingByProfile,
    createCampaign,
    createKeywords,
    createNegativeKeywords,
    updateCampaign,
    updateCampaigns,
    setCampaignIsActiveInVector,
    bulkSetCampaignIsActiveInVector,
    updateKeyword,
    updateKeywords,
    updateNegativeKeywords,
    updateKeywordBids,
    updateAd,
    updateAds,
    updateAdItemBids,
    samsClubItemSearch,
    samsClubBuildCampaign,
    walmartItemSearch,
    walmartBuildCampaign,
    walmartSamsClubBuildAds,
    walmartCreateSbaReview,
    getSbaProfiles,
    buildSbaProfile,
    getHourlySchedule,
    createHourlySchedule,
    updateHourlySchedule,
    deleteHourlySchedule,
    updateSbaProfile,
    getSbaReviews,
    cancelSbaReviews,
    updateProfileMonthlyBudgets,
    getCampaignMonthlyBudgets,
    updateCampaignMonthlyBudgets,
    getBudgetsViewData,
    isValidMonthlyBudget,
    getPlacements,
    createPlacements,
    getPlacementMultipliers,
    createPlacementMultipliers,
    updatePlacementMultipliers,
    getPlatformMultipliers,
    createPlatformMultipliers,
    updatePlatformMultipliers,
    getKeywordHarvestingSettingsForCampaign,
    deleteKeywordHarvestingSettingsForCampaign,
    getPageableKeywordHarvestingSettings,
    updateKeywordHarvestingSettings,
    createKeywordHarvestingSettings,
    getPreviewOfHarvestedKeywords,
    applyHarvestedKeywords,
    getAdGroupMedia,
    getMedia: getMedia,
    createMedia,
    createAdGroupMedia,
    updateAdGroupMedia,
    updateClosedCaptioning,
    getSearchTrends,
    getSearchTrendsUrl,
    updateCampaignAndProfileMonthlyBudgets,
    getCampaignDailyBudgetOverrides,
    updateCampaignDailyBudgetOverride,
    createCampaignDailyBudgetOverride,
    deleteCampaignDailyBudgetOverride,
    createWalmartAndSamsProfile
  };
};

export default useAdsApi;
