import { MenuItem, Box, Typography, IconButton, Tooltip, Divider, useTheme } from '@mui/material';
import {
  ArchiveOutlined,
  UnarchiveOutlined,
  VisibilityOutlined,
  VisibilityOffOutlined,
  AccessTime,
  Archive,
  CalendarMonth,
  Warning
} from '@mui/icons-material';
import { formatDateForNotifications, NotificationItemProps, NotificationReason } from './notifications-types-handlers';
import { th } from 'date-fns/locale';

export function NotificationItem(props: NotificationItemProps) {
  const { notification, selectedTab, handleNotificationItemClick, handleArchive, handleToggleRead } = props;
  const theme = useTheme();

  const formatMessageWithLineBreaks = (message: string) => {
    const parts = message.split('\n');
    const mainMessage = parts[0];
    const campaignNames = parts.slice(1).filter((name) => name.trim() !== '');

    return (
      <>
        <Typography variant="body1" component="div" gutterBottom sx={{ textWrap: 'wrap' }}>
          {mainMessage}
        </Typography>
        {campaignNames.length > 0 && (
          <Box sx={{ pl: 1, mt: 0.5, display: 'flex', flexDirection: 'column' }}>
            {campaignNames.map((name, index) => (
              <Typography
                key={index}
                variant="caption"
                component="div"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 0.5
                }}
              >
                <span style={{ marginRight: '4px' }}>•</span>
                {name.trim()}
              </Typography>
            ))}
          </Box>
        )}
      </>
    );
  };

  const getNotificationIcon = () => {
    switch (notification.reason) {
      case NotificationReason.WalmartCampaignWithoutBudget:
        return <Warning fontSize="small" sx={{ color: theme.palette.warning.main, mr: 1 }} />;
      case NotificationReason.WalmartCampaignMissingNextMonthBudget:
        return <CalendarMonth fontSize="small" sx={{ color: theme.palette.info.main, mr: 1 }} />;
      default:
        return null;
    }
  };

  const getBorderColor = () => {
    switch (notification.reason) {
      case NotificationReason.WalmartCampaignWithoutBudget:
        return theme.palette.warning.main;
      case NotificationReason.WalmartCampaignMissingNextMonthBudget:
        return theme.palette.info.main;
      default:
        return theme.palette.error.main;
    }
  };

  return (
    <MenuItem
      key={notification.id}
      onClick={() => handleNotificationItemClick(notification)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        py: 1.5,
        px: 2,
        backgroundColor: notification.isRead ? 'inherit' : 'rgba(25, 118, 210, 0.08)',
        transition: 'background-color 0.2s',
        '&:hover': { backgroundColor: notification.isRead ? 'action.hover' : 'rgba(25, 118, 210, 0.12)' },
        borderLeft: `2px solid ${getBorderColor()}`,
        borderRadius: '4px',
        my: 0.5
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              fontWeight: notification.isRead ? 'normal' : 500
            }}
          >
            {getNotificationIcon()}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                fontWeight: notification.isRead ? 'normal' : 500
              }}
            >
              {formatMessageWithLineBreaks(notification.notification.message)}
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: 0.5, alignSelf: 'flex-start' }}>
          {selectedTab === 0 ? (
            <Tooltip title="Archive notification">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleArchive(notification.id, true);
                }}
                sx={{
                  color: 'text.secondary',
                  '&:hover': { color: 'primary.main' },
                  height: 30,
                  width: 30
                }}
              >
                <ArchiveOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Unarchive notification">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleArchive(notification.id, false);
                }}
                sx={{
                  color: 'text.secondary',
                  '&:hover': { color: 'primary.main' },
                  height: 30,
                  width: 30
                }}
              >
                <UnarchiveOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={notification.isRead ? 'Mark as unread' : 'Mark as read'}>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleToggleRead(notification.id, notification.isRead);
              }}
              sx={{
                color: notification.isRead ? 'text.secondary' : 'primary.main',
                '&:hover': { color: 'primary.main' },
                height: 30,
                width: 30
              }}
            >
              {notification.isRead ? <VisibilityOutlined fontSize="small" /> : <VisibilityOffOutlined fontSize="small" />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
        <AccessTime fontSize="small" sx={{ color: 'text.secondary', fontSize: '1rem' }} />
        <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {formatDateForNotifications(notification.notification.createdAt)}
          {notification.isArchived && notification.archivedDate && (
            <>
              <Divider orientation="vertical" flexItem />
              <Archive sx={{ fontSize: '0.875rem' }} />
              {`Archived ${formatDateForNotifications(notification.archivedDate)}`}
            </>
          )}
        </Typography>
      </Box>
    </MenuItem>
  );
}
