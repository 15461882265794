import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { getGridSingleSelectOperators } from '@mui/x-data-grid-premium';
import { Link as RouterLink } from 'react-router-dom';
import { renderEntityId } from '../../../../shared/components/grid-components/renderer/renderEntityId';
import { adGroupStatusOptions as WALMART_AD_GROUP_STATUS_OPTIONS } from '../../../../shared/types/walmart-sams-club/ad-group';
import { renderAdGroupStatus } from '../renderer/ad-group/renderStatus';
import { METRIC_COLUMNS } from './metric-columns';
import { Platform } from '../../../../shared/types/platforms';
import { renderAdGroupNameWithActions } from '../renderer/ad-group/renderAdGroupNameWithActions';
import { renderAdGroupActions } from '../renderer/ad-group/renderActiveToggle';
import { Link, Tooltip } from '@mui/material';

export const AD_GROUP_COLUMNS: GridColDef[] = [
  {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    type: 'actions',
    getActions: renderAdGroupActions
  },
  {
    field: 'adGroupId',
    headerName: 'Ad Group Id',
    width: 90,
    type: 'number',
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    editable: false,
    renderCell: renderAdGroupNameWithActions(Platform.WALMART.value)
  },
  {
    field: 'status',
    headerName: 'Active Status',
    type: 'singleSelect',
    valueOptions: WALMART_AD_GROUP_STATUS_OPTIONS.map((option) => option.label),
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    renderCell: renderAdGroupStatus(WALMART_AD_GROUP_STATUS_OPTIONS),
    width: 140,
    minWidth: 120
  },
  {
    field: 'campaignId',
    headerName: 'Campaign Id',
    width: 110,
    type: 'number',
    valueFormatter: ({ value }) => renderEntityId(value)
  },
  {
    field: 'keywordsCount',
    headerName: 'Keywords',
    width: 120,
    renderCell: (params: GridRenderCellParams) => {
      const count = params.value || 0;
      const profileId = params.row.profileId;
      const campaignId = params.row.campaignId;

      if (count === 0) {
        return (
          <Tooltip title="No keywords">
            <span>{count} keywords</span>
          </Tooltip>
        );
      }

      return (
        <Tooltip title="Click to view keywords">
          <Link
            component={RouterLink}
            to={`/walmart/keywords?campaignId=${campaignId}&profileId=${profileId}&adGroupId=${params.row.adGroupId}`}
            color="primary"
            sx={{
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' }
            }}
          >
            {count} {count === 1 ? 'keyword' : 'keywords'}
          </Link>
        </Tooltip>
      );
    }
  },
  {
    field: 'adItemsCount',
    headerName: 'Ad Items',
    width: 120,
    renderCell: (params: GridRenderCellParams) => {
      const count = params.value || 0;
      const profileId = params.row.profileId;
      const campaignId = params.row.campaignId;
      const adGroupId = params.row.adGroupId;

      if (count === 0) {
        return (
          <Tooltip title="No ad items">
            <span>{count} items</span>
          </Tooltip>
        );
      }

      return (
        <Tooltip title="Click to view ad items">
          <Link
            component={RouterLink}
            to={`/walmart/ads?campaignId=${campaignId}&profileId=${profileId}${!!adGroupId ? `&adGroupId=${adGroupId}` : ''}`}
            color="primary"
            sx={{
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' }
            }}
          >
            {count} {count === 1 ? 'item' : 'items'}
          </Link>
        </Tooltip>
      );
    }
  },
  ...METRIC_COLUMNS
];
