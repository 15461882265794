import { Divider, Box, Typography, Button, Container, Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MediaListContainer } from './media-list-container';
import { MediaListTable } from './media-list-table';
import AddIcon from '@mui/icons-material/Add';
import useAdsApi from '../../../../hooks/use-walmart-sams-club-api';
import Page from '../../../page';
import { MediaListSearch } from './media-list-search';
import { MediaFileUploader } from './media-file-uploader';
import { useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

interface Filters {
  status?: string;
}

interface Search {
  filters: Filters;
}

const useSearch = () => {
  const [search, setSearch] = useState<Search>({
    filters: {
      status: 'LIVED'
    }
  });

  return {
    search,
    updateSearch: setSearch
  };
};

export const VideoMedia = () => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const { search, updateSearch } = useSearch();
  const [medias, setMedias] = useState<any[]>([]);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [shouldShowErrorState, setShouldShowErrorState] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [openFileUploader, setOpenFileUploader] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();
  const profileId = searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null;

  const [profiles, setProfiles] = useState<any[]>([]);

  const { getMedia, getProfiles } = useAdsApi();

  const getMediaRef = useRef(getMedia);
  const getProfilesRef = useRef(getProfiles);

  const handleFiltersChange = useCallback(
    (filters: Filters): void => {
      updateSearch((prevState) => ({
        ...prevState,
        filters
      }));
    },
    [updateSearch]
  );

  const handleUploaderOpen = useCallback((): void => {
    setOpenFileUploader(true);
  }, []);

  const handleProfileChange = useCallback(
    (event: any, newValue: any) => {
      if (newValue && newValue.profileId) {
        setSearchParams({ profileId: newValue.profileId.toString() });
      } else {
        setSearchParams({});
        setMedias([]);
        setIsInitialized(false);
      }
    },
    [setSearchParams]
  );

  const fetchMedia = useCallback(async () => {
    if (!profileId) {
      setIsInitialized(true);
      return;
    }
    setIsInitialized(false);
    const responseObject = await getMediaRef.current(profileId);
    if (!responseObject.success) {
      enqueueSnackbar('An error occurred while fetching media', { variant: 'error' });
      setErrorMessage(`${responseObject.response?.status} - ${responseObject.errorMessage || responseObject.body?.message}`);
      setShouldShowErrorState(true);
      setIsInitialized(true);
      return;
    }
    setMedias(responseObject.body);
    setIsInitialized(true);
  }, [profileId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchProfiles = useCallback(async () => {
    const response = await getProfilesRef.current();

    if (response.success) {
      const profilesData = Array.isArray(response.body)
        ? response.body
        : Array.isArray(response.body.records)
          ? response.body.records
          : [];
      setProfiles(profilesData);
    } else {
      enqueueSnackbar('An error occurred while fetching profiles', { variant: 'error' });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUploaderClose = useCallback((): void => {
    fetchMedia();
    setOpenFileUploader(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchProfiles();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchMedia();
  }, [profileId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFilteredMedia = useCallback(() => {
    const { filters } = search;
    const { status } = filters;

    if (status) {
      return medias.filter((media) => {
        if (search.filters.status === 'PENDING') {
          return media.status === search.filters.status || media.status === 'PROCESSING';
        }

        return media.status === search.filters.status;
      });
    }

    return medias;
  }, [medias, search]);

  return (
    <>
      <Page title="Video Manager">
        <>
          <Container>
            <FormControl fullWidth sx={{ mt: 2, width: 300 }}>
              <Autocomplete
                options={profiles}
                getOptionLabel={(profile) => profile.name}
                onChange={handleProfileChange}
                value={
                  profiles && profiles.length > 0 && profileId
                    ? profiles.find((profile) => profile.profileId === profileId) || null
                    : null
                }
                renderInput={(params) => <TextField {...params} size="small" label="Profile" />}
              />
            </FormControl>
          </Container>
          <Box
            component="main"
            ref={rootRef}
            sx={{
              display: 'flex',
              flex: '1 1 auto',
              overflow: 'hidden',
              position: 'relative'
            }}
          >
            <Box
              ref={rootRef}
              sx={{
                bottom: 0,
                display: 'flex',
                left: 0,
                position: 'absolute',
                right: 0,
                top: 0
              }}
            >
              <MediaListContainer open={false}>
                <Container>
                  <Box sx={{ py: 3 }}>
                    <Grid container alignItems="center" justifyContent="space-between" spacing={4}>
                      <Grid item xs={12} sm={8}>
                        <Typography variant="h4">Client Video Assets</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Button
                          startIcon={<AddIcon />}
                          variant="contained"
                          onClick={handleUploaderOpen}
                          disabled={!profileId} // Disable if no profile is selected
                        >
                          Create New
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  {isInitialized ? (
                    <>
                      {profileId ? (
                        <>
                          <MediaListSearch onFiltersChange={handleFiltersChange} />
                          <MediaListTable medias={getFilteredMedia()} search={search.filters.status} />
                        </>
                      ) : (
                        <Typography variant="h6">Please select a profile to view media assets.</Typography>
                      )}
                    </>
                  ) : (
                    <Typography variant="h6">
                      {shouldShowErrorState ? 'An error occurred while fetching media' : 'Loading...'}
                    </Typography>
                  )}
                  {shouldShowErrorState && errorMessage && (
                    <>
                      <Divider sx={{ my: 2 }} />
                      <Typography variant="body1">Error: {errorMessage}</Typography>
                    </>
                  )}
                </Container>
              </MediaListContainer>
            </Box>
          </Box>
        </>
      </Page>
      {!!profileId && openFileUploader ? (
        <MediaFileUploader onClose={handleUploaderClose} profileId={profileId} open={openFileUploader} />
      ) : null}
    </>
  );
};
