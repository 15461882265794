import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useAdsApi } from '../../../../shared/hooks/use-walmart-sams-club-api';
import AddItemsAlert from '../../../../shared/pages/campaigns/create-campaign-form/add-items-alert';
import { CampaignItemsTable } from './campaign-items-table';
import { CampaignBuilderStepLabelButton } from './step-label-button';

interface ItemSelectionStepProps {
  formik: any;
  stepNumber: number;
  profileId: number | null;
  campaignId?: number | null;
  existingItems?: string[]; // Array of item IDs that are already selected in other ad groups (if MultiItemAdGroup)
  onItemsSelected?: (items: any[]) => void;
}

export const ItemSelectionStep: FC<ItemSelectionStepProps> = (props) => {
  const { formik, stepNumber, profileId, campaignId, existingItems = [], onItemsSelected, ...other } = props;

  const [eligibleItems, setEligibleItems] = useState<any[]>([]);
  const [invalidItems, setInvalidItems] = useState<string[]>([]);
  const [alreadyTargetedItems, setAlreadyTargetedItems] = useState<any[]>([]);
  const [userInputItemIds, setUserInputItemIds] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [itemSearchIsLoading, setItemSearchIsLoading] = useState(false);

  const { walmartItemSearch } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [showStep, setShowStep] = useState<boolean>(true);

  const handleStepButtonClick = () => {
    setShowStep(!showStep);
  };

  // Check for duplicates against existing items in other ad groups (if MultiItemAdGroup)
  const filterDuplicates = (newItems: any[]) => {
    return newItems.filter((item) => !existingItems.includes(item.itemId));
  };

  const addItems = async () => {
    setItemSearchIsLoading(true);
    setIsAlertOpen(false);

    const itemIds = userInputItemIds
      .replace(/,\s+/g, ',')
      .split(/[\n,\s+]/)
      .filter(Boolean);

    // Filter out items that are already in the current ad group
    const newItemIds = itemIds.filter((itemId) => !formik.values.items.some((item: any) => item.itemId === itemId));

    if (newItemIds.length < 1) {
      setItemSearchIsLoading(false);
      enqueueSnackbar('No new items to add', { variant: 'info' });
      return;
    }

    try {
      const response = await walmartItemSearch(profileId, newItemIds, campaignId);

      if (response.success) {
        const eligibleItems = response.body?.eligibleItems || [];
        const duplicateItems = eligibleItems.filter((item: { itemId: string }) => existingItems.includes(item.itemId));
        const alreadyTargetedItems = response.body?.alreadyTargetedItems || [];

        // Filter out items that exist in other ad groups
        const uniqueEligibleItems = filterDuplicates(eligibleItems);

        if (duplicateItems.length > 0) {
          enqueueSnackbar(`${duplicateItems.length} item(s) already exist in other ad groups`, { variant: 'warning' });
          alreadyTargetedItems.push(...duplicateItems);
        }

        setEligibleItems(uniqueEligibleItems);
        setAlreadyTargetedItems(alreadyTargetedItems || []);

        const trulyInvalidItems = newItemIds.filter(
          (newItemId: string) =>
            !uniqueEligibleItems.some((item: any) => item.itemId === newItemId) &&
            !alreadyTargetedItems.some((item: any) => Number(item.itemId) === Number(newItemId))
        );

        setInvalidItems(trulyInvalidItems);

        // Update formik with unique items only
        const updatedItems = [...formik.values.items, ...uniqueEligibleItems];
        await formik.setFieldTouched('items', true);
        await formik.setFieldValue('items', updatedItems);

        // Notify parent component of selected items
        onItemsSelected?.(updatedItems);

        setIsAlertOpen(true);
      } else {
        enqueueSnackbar(response.errorMessage, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Error searching for items', { variant: 'error' });
    } finally {
      setItemSearchIsLoading(false);
    }
  };

  const removeItem = (item: any) => {
    const filteredItems = formik.values.items.filter((campaignItem: any) => campaignItem.itemId !== item.itemId);
    formik.setFieldValue('items', filteredItems);
  };

  const containsAlphabeticalCharacters = (value: string) => {
    return /[a-zA-Z]/.test(value);
  };

  const handleClearAllItems = async () => {
    await formik.setFieldValue('items', []);
    setEligibleItems([]);
    setInvalidItems([]);
    setAlreadyTargetedItems([]);
  };

  const getErrorMessage = () => {
    // Display formik error message if touched && SIAG && no items (see validation schema)
    if (formik.errors.items && formik.touched.items) {
      return formik.errors.items;
    }

    if (containsAlphabeticalCharacters(userInputItemIds)) {
      return 'Item ids must be numbers only';
    }

    return '';
  };

  const [itemError, setItemError] = useState<{
    error: boolean;
    message: string;
  }>({
    error: false,
    message: ''
  });

  useEffect(() => {
    if (formik.touched.items && formik.errors.items) {
      setItemError({
        error: true,
        message: formik.errors.items
      });
      return;
    }

    if (containsAlphabeticalCharacters(userInputItemIds)) {
      setItemError({
        error: true,
        message: 'Item ids must be numbers only'
      });
      return;
    }

    setItemError({
      error: false,
      message: ''
    });
  }, [formik.errors.items, formik.touched.items, userInputItemIds]);

  return (
    <Box {...other} sx={{ mb: showStep ? 10 : 1 }}>
      <CampaignBuilderStepLabelButton
        showStep={showStep}
        handleStepButtonClick={handleStepButtonClick}
        stepNumber={stepNumber}
        stepLabel="Select Items"
      />
      <Collapse in={showStep}>
        {formik.errors.items && formik.touched.items && (
          <Typography variant="body2" color="error">
            errors: {formik.errors.items}
          </Typography>
        )}
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Box display={'flex'}>
              <Box sx={{ width: '30%', pr: 2 }}>
                <TextField
                  id="campaignItemIds"
                  label="Items"
                  onChange={(event) => {
                    setUserInputItemIds(event.target.value);
                  }}
                  placeholder="Enter item IDs separated by new lines"
                  multiline
                  value={userInputItemIds}
                  rows={15}
                  fullWidth
                  inputProps={{
                    style: {
                      fontSize: '12px'
                    }
                  }}
                  error={itemError.error}
                  helperText={itemError.message}
                />
                {!profileId ? (
                  <Tooltip title="Please select a Profile before adding Items">
                    <span>
                      <Button disabled>Add Items</Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={addItems}
                    disabled={containsAlphabeticalCharacters(userInputItemIds) || !userInputItemIds}
                  >
                    Add Items
                  </Button>
                )}
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box sx={{ width: '70%', pl: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="outlined" sx={{ mb: 2 }} onClick={handleClearAllItems}>
                    Remove All Items
                  </Button>
                </Box>
                <AddItemsAlert
                  eligibleItems={eligibleItems}
                  invalidItems={invalidItems}
                  alreadyTargetedItems={alreadyTargetedItems}
                  isOpen={isAlertOpen}
                  onClose={() => {
                    setIsAlertOpen(false);
                    setEligibleItems([]);
                    setInvalidItems([]);
                    setAlreadyTargetedItems([]);
                  }}
                />
                {!itemSearchIsLoading ? (
                  <CampaignItemsTable formik={formik} removeItem={removeItem} />
                ) : (
                  <Box display={'flex'} alignItems="center" justifyContent={'center'} sx={{ height: '100%' }}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Box sx={{ mt: 3 }}></Box>
      </Collapse>
    </Box>
  );
};
