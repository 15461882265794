import { Box, Container, Stack, Typography } from '@mui/material';
import { DataGridPremium, GridFilterModel, GridRowModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { DateRange } from '@mui/x-date-pickers-pro';
import { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import KeywordBulkActions from '../../../shared/components/grid-components/bulk-actions/keywords/keyword-bulk-actions';
import MetricsChart from '../../../shared/components/metrics-chart';
import Page from '../../../shared/components/page';
import VectorPerformanceGrid from '../../../shared/components/performance-grid/vector-performance-grid';
import useSharedApi from '../../../shared/hooks/use-shared-api';
import useVectorPerformanceGrid from '../../../shared/hooks/use-vector-performance-grid';
import useAdsApi, { BreadCrumbParams } from '../../../shared/hooks/use-wmt-map-updated-api';
import { DEFAULT_ROW_COUNT, Pageable } from '../../../shared/types/pageable';
import { buildUpdateKeywordRequest } from '../../../shared/types/walmart-sams-club/keyword';
import { GridConfigSettings } from '../../../shared/utilities/grid-config-settings';
import { BidConfirmationDialog } from '../../components/grid-components/bid-confirmation-dialog';
import { KEYWORD_COLUMNS } from '../../components/grid-components/column-configurations/keyword-columns';

const Keywords: FC = () => {
  const { getKeywords, getKeywordTotals, updateKeyword } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();

  const [keywords, setKeywords] = useState<any[]>([]);
  const [keywordTotals, setKeywordTotals] = useState({
    returnOnAdSpend: ''
  });
  const [isLoading, setIsLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);
  const [bidChangeData, setBidChangeData] = useState<any>(null);
  const [timeSeriesData, setTimeSeriesData] = useState<any[]>([]);

  let [searchParams] = useSearchParams();
  let profileId = useMemo(() => (searchParams.get('profileId') ? Number(searchParams.get('profileId')) : null), [searchParams]);
  let campaignId = useMemo(
    () => (searchParams.get('campaignId') ? Number(searchParams.get('campaignId')) : null),
    [searchParams]
  );
  let adGroupId = useMemo(() => (searchParams.get('adGroupId') ? Number(searchParams.get('adGroupId')) : null), [searchParams]);

  const breadcrumbParams: BreadCrumbParams[] = useMemo(
    () => [{ profileId }, { campaignId }, { adGroupId }],
    [profileId, campaignId, adGroupId]
  );

  const {
    initializePerformanceGrid,
    saveGridConfig,
    handleDateChange,
    handleFilterModelChange,
    handleSortModelChange,
    handlePageSizeChange,
    handlePageChange
  } = useVectorPerformanceGrid(GridConfigSettings.WALMART_KEYWORD);

  const apiRef = useGridApiRef();

  const { getUserSetting, updateUserSetting } = useSharedApi();

  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const [initialSettings, setInitialSettings] = useState<{
    pageable?: Pageable;
    config: GridInitialStatePremium;
    shouldShowChart?: boolean;
  }>();

  const [dateSettings, setDateSettings] = useState<{ beginDate: string; endDate: string }>();
  const [dateSettingsLoading, setDateSettingsLoading] = useState(true);

  useEffect(() => {
    initializePerformanceGrid({
      setInitialSettings,
      setSettingsLoaded,
      setDateSettings,
      setDateSettingsLoading,
      fetchEntity: fetchKeywordsData
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchKeywordsData = async (pageable: Pageable, beginDate: string, endDate: string) => {
    setIsLoading(true);

    const response = await getKeywords([...breadcrumbParams], pageable, beginDate, endDate, true);

    if (response.success) {
      setKeywords(response.body?.dataGrid?.records);
      setRowCount(response.body?.dataGrid?.totalFilteredRecords || DEFAULT_ROW_COUNT);

      if (response.body?.timeSeriesData) {
        setTimeSeriesData(response.body?.timeSeriesData);
      }
    } else {
      enqueueSnackbar('Failed to get keywords', { variant: 'error' });
    }

    setIsLoading(false);

    const totalsResponse = await getKeywordTotals([...breadcrumbParams], pageable, beginDate, endDate);

    if (totalsResponse.success) {
      setKeywordTotals(totalsResponse.body);
    } else {
      enqueueSnackbar(totalsResponse.errorMessage, { variant: 'error' });
    }
  };

  const processRowUpdate = useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
    if (newRow.bid === oldRow.bid && newRow.state === oldRow.state) {
      return oldRow;
    }

    // Setting the bidChangeData will open the confirmation dialog if the bid is greater than suggested max bid
    if (Number(newRow.bid) > 10) {
      return new Promise<GridRowModel>((resolve, reject) => {
        setBidChangeData({ resolve, reject, newRow, oldRow });
      });
    }

    setIsLoading(true);

    try {
      const response = await updateKeyword(newRow.profileId, buildUpdateKeywordRequest(newRow));

      setIsLoading(false);

      if (response.success && response.body?.isSuccess) {
        enqueueSnackbar('Keyword updated successfully', { variant: 'success' });
        return newRow;
      } else {
        const errorMessage =
          response.body?.errors?.length > 0 ? response.body.errors[0] : 'Failed to update keyword. Please try again.';

        enqueueSnackbar(errorMessage, { variant: 'error' });
        return oldRow;
      }
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
      return oldRow;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const discardChanges = () => {
    const { oldRow, resolve } = bidChangeData;
    resolve(oldRow); // Resolve with the old row to not update the internal state
    setBidChangeData(null);
  };

  const confirmBidChange = async () => {
    const { newRow, oldRow, reject, resolve } = bidChangeData;

    setIsLoading(true);

    try {
      const response = await updateKeyword(newRow.profileId, buildUpdateKeywordRequest(newRow));

      setIsLoading(false);

      if (response.success && response.body?.isSuccess) {
        resolve(newRow);
        enqueueSnackbar('Keyword updated successfully', { variant: 'success' });
      } else {
        const errorMessage =
          response.body?.errors?.length > 0 ? response.body.errors[0] : 'Failed to update keyword. Please try again.';

        enqueueSnackbar(errorMessage, { variant: 'error' });
        reject(oldRow);
      }
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar('An unexpected error occurred', { variant: 'error' });
      reject(oldRow);
    }

    setBidChangeData(null);
  };

  return (
    <>
      <Page title="Keywords">
        <Container maxWidth="xl" style={{ height: '93vh', marginTop: '10px' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Typography variant="h4">Keywords</Typography>
            {!settingsLoaded || dateSettingsLoading || !dateSettings ? (
              <DataGridPremium
                rows={[]}
                disableRowGrouping
                columns={KEYWORD_COLUMNS}
                loading={true}
                processRowUpdate={processRowUpdate}
                initialState={{}}
                key={uuidv4()}
              />
            ) : (
              <>
                {!!timeSeriesData && (
                  <Box display="flex" alignItems={'center'} my={'1%'}>
                    <MetricsChart
                      timeSeriesData={timeSeriesData}
                      isMetricsChartLoading={isLoading}
                      chartSettingsKey="WalmartKeywordsChartSetting"
                    />
                  </Box>
                )}
                <VectorPerformanceGrid
                  apiRef={apiRef}
                  processRowUpdate={processRowUpdate}
                  loading={isLoading}
                  rows={keywords}
                  columns={KEYWORD_COLUMNS}
                  initialState={initialSettings?.config}
                  saveGridConfig={saveGridConfig}
                  dateConfig={{
                    dateSettings: dateSettings,
                    dateSettingsLoading: dateSettingsLoading,
                    handleDateRangeChange: (dateRange: DateRange<Dayjs>) =>
                      handleDateChange(dateRange, dateSettings, dateSettingsLoading, apiRef, fetchKeywordsData, setDateSettings)
                  }}
                  getRowId={(row: any) => row.keywordId}
                  getRowClassName={(params) => `row-status--${params.row.state}`}
                  bulkActions={<KeywordBulkActions setIsLoading={setIsLoading} />}
                  handleFilterModelChange={(newModel: GridFilterModel) => {
                    handleFilterModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchKeywordsData);
                  }}
                  handleSortModelChange={(newModel: GridSortModel) => {
                    handleSortModelChange(newModel, settingsLoaded, dateSettings, apiRef, fetchKeywordsData);
                  }}
                  handlePageChange={(page: number) => {
                    handlePageChange(page, settingsLoaded, dateSettings, apiRef, fetchKeywordsData);
                  }}
                  handlePageSizeChange={(pageSize: number) => {
                    handlePageSizeChange(pageSize, settingsLoaded, dateSettings, apiRef, fetchKeywordsData);
                  }}
                  rowCount={rowCount}
                  aggregatedTotals={keywordTotals}
                />
              </>
            )}
          </Stack>
        </Container>
      </Page>

      {bidChangeData && (
        <BidConfirmationDialog
          bidChangeData={bidChangeData}
          confirmBidChange={confirmBidChange}
          cancelBidChange={discardChanges}
        />
      )}
    </>
  );
};

export default Keywords;
