import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import useAdsApi from '../../shared/hooks/use-walmart-sams-club-api';
import { WalmartCampaignOption, WalmartCampaignOptions } from '../../shared/types/walmart-sams-club/campaign';
import { CampaignOptionsSelections } from '../../shared/types/walmart-sams-club/campaign-options-selections';
import CampaignOptions from './campaign-options';
import { Box } from '@mui/system';

interface BrandTermTargetingDialogProps {
  isShowing: boolean;
  toggle: () => void;
  profileId: number;
  campaignId: number;
  campaignOptions: WalmartCampaignOptions;
}

const CampaignOptionsDialog = (props: BrandTermTargetingDialogProps) => {
  const { isShowing, toggle, profileId, campaignId, campaignOptions } = props;
  const { getCampaign, updateCampaign } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [brandTermTargetingSetting, setBrandTermTargetingSetting] = useState<CampaignOptionsSelections>(
    CampaignOptionsSelections.OptIn
  );
  const [complementaryTermTargetingSetting, setComplementaryTargetingSetting] = useState<CampaignOptionsSelections>(
    CampaignOptionsSelections.OptIn
  );

  const handleSaveButtonClick = async () => {
    setIsSaveButtonDisabled(true);
    setIsLoading(true);

    // A Set is used here to avoids duplicates
    const newCampaignOptions = new Set(campaignOptions);

    if (brandTermTargetingSetting === CampaignOptionsSelections.OptOut) {
      newCampaignOptions.add(WalmartCampaignOption.BrandTermOptOut);
    } else {
      newCampaignOptions.delete(WalmartCampaignOption.BrandTermOptOut);
    }

    if (complementaryTermTargetingSetting === CampaignOptionsSelections.OptOut) {
      newCampaignOptions.add(WalmartCampaignOption.ComplementaryTermOptOut);
    } else {
      newCampaignOptions.delete(WalmartCampaignOption.ComplementaryTermOptOut);
    }

    const response = await updateCampaign({
      campaignId: campaignId,
      campaignOptions: Array.from(newCampaignOptions)
    });

    if (response.success) {
      enqueueSnackbar('Campaign updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
    setIsSaveButtonDisabled(false);

    toggle();
  };

  useEffect(() => {
    const fetchBrandTermTargetingOption = async () => {
      const response = await getCampaign(profileId, campaignId);

      if (response.success) {
        setBrandTermTargetingSetting(
          Array.isArray(response.body.campaignOptions) &&
            response.body.campaignOptions.includes(WalmartCampaignOption.BrandTermOptOut)
            ? CampaignOptionsSelections.OptOut
            : CampaignOptionsSelections.OptIn
        );

        setComplementaryTargetingSetting(
          Array.isArray(response.body.campaignOptions) &&
            response.body.campaignOptions.includes(WalmartCampaignOption.ComplementaryTermOptOut)
            ? CampaignOptionsSelections.OptOut
            : CampaignOptionsSelections.OptIn
        );
      } else {
        enqueueSnackbar('Error fetching campaign options.', { variant: 'error' });
      }
    };

    fetchBrandTermTargetingOption();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={isShowing} onClose={toggle} maxWidth="sm" fullWidth>
      <DialogTitle>Campaign Options</DialogTitle>
      <DialogContent>
        <Typography gutterBottom variant="body2">
          Choose whether to opt in or out. All new campaigns are opted in by default.
        </Typography>
        <Box sx={{ mt: 2 }}>
          <CampaignOptions
            brandTermTargeting={brandTermTargetingSetting}
            complementaryTargeting={complementaryTermTargetingSetting}
            onValueChange={(option, optionValue) => {
              if (option === 'brandTermTargeting') {
                setBrandTermTargetingSetting(optionValue);
              }
              if (option === 'complementaryTargeting') {
                setComplementaryTargetingSetting(optionValue);
              }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle}>Cancel</Button>
        <Button variant="contained" onClick={handleSaveButtonClick} disabled={isSaveButtonDisabled}>
          Save
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default CampaignOptionsDialog;
